import React, {Component, useRef} from 'react';
import SignatureCanvas from 'react-signature-canvas'
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/FirmaCanvas.css'
import '../tools/css/Membresia.css';
import '../tools/css/Paginacion.css';
import Departamentos from '../API/Departamentos';

import '../tools/css/Crear_cuenta.css';

import axios from 'axios';
import img from '../tools/images/logoblanco.png';
import iconofotoavatar from '../tools/images/upload.svg';
import fondo_cover from '../tools/images/imagen portada.jpg'
import fondo_avatar from '../tools/images/imagen logo.jpg'
import pasos from '../tools/images/pasos.svg'
import paso1 from '../tools/images/paso 1.svg'
import paso2 from '../tools/images/paso 2.svg'
import paso3 from '../tools/images/paso 3.svg'
import paso1compra from '../tools/images/paso1compra.svg'
import paso2compra from '../tools/images/paso2compra.svg'
import paso3compra from '../tools/images/paso3compra.svg'
import free from '../tools/images/MEMBRESIA FREE.png'
import platinium from '../tools/images/MEMBRESIA platinum.png'
import golden from '../tools/images/MEMBRESIA golden.png'
import diamond from '../tools/images/MEMBRESIA diamond.png'
import icono_aprobado from '../tools/images/aprobado icono.svg'
import card_credit from '../tools/images/credit-card.svg';
import pse from '../tools/images/pse.png';
import bancolombia from '../tools/images/bancolombia.png';
import Moment from 'react-moment';
import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';
import { FaTextHeight } from 'react-icons/fa';

const ApiURL="https://medicallapi.azurewebsites.net/";
var institution_id = 0;
var queryString = window.location.search;
var urlParams = new URLSearchParams(queryString);
var nom_membresia = urlParams.get('membresia');
var tipo_pres = urlParams.get('user');
var id_membresia=1;
var metodo_pago='';
var tipo_persona='';
var img_membresia='';
var url='';
var url_pse='';
var rterminos='false';
var rpoliticas='false';
var valida_reps=0



if(nom_membresia=='platinum'){

  id_membresia=2;
  img_membresia=platinium

  //alert(nom_membresia)

}else{
  if(nom_membresia=='golden'){

    id_membresia=3;
    img_membresia=golden
    //alert(nom_membresia)

  }else{
    if(nom_membresia=='diamond'){

      id_membresia=4;
      img_membresia=diamond
      //alert(nom_membresia)

    }else{

      id_membresia=1;
      img_membresia=free
      //alert("free")

    }
  }
}

var tipo_id='null';
var sexo='';
var mensaje='';
var msj_usuario='';
var msj_prestador='';
var avatar=[];
var avatar_length='Ningún archivo seleccionado';
var cover=[];
var files_view='';
var tipoid='null';
var cover_length='Ningún archivo seleccionado';
var files_viewp='';
var tipoidrl='null';
var clasepres='null';
var tiponatu='null';
var idnivel='0';
var Ese='null';
var coddepa='0';
var codmuni='0';
var codactiva='0';
var tipo_usuario="";

const body = {
  background: '#b6b6b6',
  height: 'auto'
};


class Crear_Cuenta extends Component  { 

  constructor(props)
    {
     super()
      this.state={
        show:false,
        Municipios:[],
        Detalle_membresia:[],
        Detalle_compra:[],
        Bancos:[],
        loading: false,
        loading_button: true,
        active_button: true,
        loading_button_activacuenta: true,
        loading_buttonrecovery_pass: true,
        active_buttonrecovery_pass: true,
        loading_buttonchange_pass: true,
        active_buttonchange_pass: true,
        active_buttonpaso1:true,
        nom_inst_active:true,
        button_activacuenta: true,
        active: false,
        checked:false,
        checkedT:false,
        active_button_terminos: true,
          form:{   
          tipo_id:'',
          dv:'',  
          id_inst:'',
          nom_inst:"",
          nombre1:"",
          nombre2:"",
          apellido1:"",
          apellido2:"",
          email_inst:'',
          clase_pres:'',
          cod_pres:'',
          tipo_naturaleza:'',
          nivel:'',
          ese:'no',
          cod_depa:'',
          cod_muni:'',
          dir_inst:'',
          tele_inst:'',
          tele_inst2:'',
          pag_web:'',
          tipo_idrl:'',
          id_rl:'',
          nom1_rl:"",
          nom2_rl:"",
          apell1_rl:"",
          apell2_rl:"",
          pass:'',
          pass_confirm:'',
          selectedFile:null,
          selectedFilep:null,
          cod_activa1:'',
          cod_activa2:'',
          cod_activa3:'',
          cod_activa4:'',
          cod_activa5:'',
          cod_activa6:'',
          municipio:'',
          email_cuenta:'',
          pass_cuenta:'',
          correo_recupera:'',
          cod_recuperacion:'',
          passr:'',
          pass_confirmr:'',
          llave_publi:"",
          llave_priv:"",
          tipoid_comprador_general:'null',
          id_comprador_general:'',
          nombres_comprador_general:'',
          apellidos_comprador_general:'',
          //correo_comprador_general:'',
          dir_comprador_general:'',
          tel_comprador_general:'',
          num_tc:'',
          tarjetahabiente:'',
          mes_tc:'',
          year_tc:'',
          cuotas_tc:'1',
          cod_tc:'',
          //tipodcto_pse:'CC',
          //numerodcto_pse:'',
          id_banco_pse:'',
          cel_nequi:'',
          btnpaso1: <button id="btn_save" className="btn btn-save" onClick={()=> this.Validar_Paso1()} > Continuar
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" class="bi bi-arrow-right-square" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                      <path fill-rule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                      </svg>
                    </button>
          }
          
        }

    }

    

    state = { trimmedDataURL: null }

  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas()
      .toDataURL('image/png') })
  }

    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         
         console.log(this.state.form);
    }

    Campo_numerico(){
      $(".camponum").keypress(function(tecla)
      {
        if(tecla.charCode<48 || tecla.charCode>57){
         
          return false;

        }
      })
    }


   Validarnumero(){
    $('.validanumericos').keypress(function(e) {
      if(isNaN(this.value + String.fromCharCode(e.charCode))) 
         return false;
      })
      .on("cut copy paste",function(e){
      e.preventDefault();
      });
   }


    GetTipoID = (id) =>{
      this.setState({tipo_id:id.target.value})
      console.log(id.target.value)
      tipoid=(id.target.value)
      this.label_razon_prestador();
     
    }

    GetClasepres = (cp) =>{
      this.setState({clase_pres:cp.target.value})
      console.log(cp.target.value)
      clasepres=(cp.target.value)
      //this.Cuota_mod();
     
    }

    GetTipoNatu = (tn) =>{

      Ese='null';

      this.setState({tipo_naturaleza:tn.target.value})
      console.log(tn.target.value)
      tiponatu=(tn.target.value)
      

      $("#ese").val($('#ese > option:first').val());

      if(tiponatu=='privada'){
        
        $('#sel_eseno').show(); 
        $('#sel_esesi').hide();     
      }else{

        $('#sel_eseno').show(); 
        $('#sel_esesi').show();

      }

      //this.Cuota_mod();
     
    }

    GetNivel = (n) =>{
      this.setState({nivel:n.target.value})
      console.log(n.target.value)
      idnivel=(n.target.value)  
     
    }

    GetESE = (e) =>{
      this.setState({ese:e.target.value})
      console.log(e.target.value)
      Ese=(e.target.value)    
     
    }

    GetMunicipio = (cm) =>{
      this.setState({cod_muni:cm.target.value})
      console.log(cm.target.value)
      codmuni=(cm.target.value)  
     
    }

    GetTipoIDLD = (tild) =>{
      this.setState({tipo_idrl:tild.target.value})
      console.log(tild.target.value)
      tipoidrl=(tild.target.value)  
     
    }


    GetCodActiva = (c) =>{      
      
      if(this.state.cod_activa1.length>0 && this.state.cod_activa2.length>0 && this.state.cod_activa3.length>0 && this.state.cod_activa4.length>0 && this.state.cod_activa5.length>0 && this.state.cod_activa6.length>0){
        this.setState({ button_activacuenta: this.state.button_activacuenta = true })
      }else{
        this.setState({ button_activacuenta: this.state.button_activacuenta = false })
      }
     
    }

    GetMetodopago = (mp) =>{
     
      //console.log(mp.target.value)
      metodo_pago=(mp.target.value)

      this.Formulario_metodopago();
     
    }

    GetTipopersona = (tp) =>{
     
      //console.log(tp.target.value)
      tipo_persona=(tp.target.value)    
     
    }

    GetTerminos = (t) =>{
      //this.setState({Racepta_autori:a.target.value})
      console.log(t.target.value)
      rterminos=(t.target.value)
      this.setState({ checked: this.state.checked = true })
      this.setState({ active_button_terminos: this.state.active_button_terminos = true })
     
    }

    GetTerminosstado = (t) =>{
      //this.setState({Racepta_autori:a.target.value})
      console.log(t.target.value)
      rterminos=(t.target.value)
     
      this.setState({ checked: this.state.checked = true })
      this.setState({ checkedT: this.state.checkedT = true })
      this.setState({ active_button_terminos: this.state.active_button_terminos = false })
     
    }

    GetTerminos_desmarcados = (td) =>{
      //this.setState({Racepta_autori:a.target.value})
      rterminos='false'
      this.setState({ checked: this.state.checked = false })
      this.setState({ checkedT: this.state.checkedT = false })
      this.setState({ active_button_terminos: this.state.active_button_terminos = false })
     
    }

    GetPoliticas = (p) =>{
      //this.setState({Racepta_autori:a.target.value})
      console.log(p.target.value)
      rpoliticas=(p.target.value)
     
    }

    Formulario_metodopago(){     
      if(metodo_pago==='CARD'){
  
        $('#tarjeta_credito').show();
        $('#pse').hide();
        $('#bancolombia').hide();
        $('#nequi').hide();


      }else{

        if(metodo_pago==='PSE'){

          $('#tarjeta_credito').hide();
          $('#pse').show();
          $('#bancolombia').hide();
          $('#nequi').hide();

          tipo_persona='';

          $("#tipo_persona_pse").val($('#tipo_persona_pse > option:first').val());


        }else{

          if(metodo_pago==='BANCOLOMBIA_TRANSFER'){

            $('#tarjeta_credito').hide();
            $('#pse').hide();           
            $('#nequi').hide();

            tipo_persona='PERSON';

            $("#tipo_persona_pse").val($('#tipo_persona_pse > option:first').val());
         

          }else{

            if(metodo_pago==='NEQUI'){

              $('#tarjeta_credito').hide();
              $('#pse').hide();
              $('#bancolombia').hide();
              $('#nequi').show();

            }
          }
        }
      }
      
    }

   

    _handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key=='Tab') {
       
        this.Buscar_Institucion()
        this.Buscar_Prestador()
        
      }
    }

    modal_activacuenta(){
      $('#modal-activacuenta').modal('show');
      $("#modal-activacuenta input").val("");
      $('#link_panelweb').hide();     
    }

    modal_img_perfil(){
      $('#modal_img_perfil').modal('show'); 
    }

    modal_img_portada(){
      $('#modal_img_portada').modal('show'); 
    }


    modal_recoverypass(){
    
      $('#modal-recoverypass').modal('show');
      $('#modal-confirmaridentidad').modal('hide');
    
      this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = true })
    }
    

    modal_llaveswompi()
    {     
      if(this.state.form.id_inst.length==0 || this.state.form.cod_pres.length==0 || this.state.form.nom_inst.length==0 || clasepres=='null' || this.state.form.email_inst.length==0 || tiponatu=='null' || idnivel=='0' || Ese=='null' || coddepa=='0' || codmuni=='0' || this.state.form.dir_inst.length==0 || this.state.form.tele_inst.length==0 || this.state.form.nom1_rlh=='' || this.state.form.apell1_rl=='' || tipoidrl=='null' || this.state.form.id_rl=='' || this.state.form.pass.length==0 || this.state.form.pass_confirm.length==0 ||  avatar.length==0 || cover.length==0 ){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        if(rterminos=='false'){

          store.addNotification({
            title: 'Atención',
            message: "Debe aceptar los términos y condiciones de uso",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
        }else{

          this.registrar();
          //$('#modal-llaveswompi').modal('show'); // <- to show modal crear sede 

        }       

      }
      
      
      
    }

    modal_terminos_condiciones(){
    
      $('#modal-terminos_condiciones').modal('show');

      this.setState({ active_button_terminos: this.state.active_button_terminos = true })
      
  
    }

    validar_codigo(){
      $('#mensaje_recupera').hide();
      $('#cambio_contraseña').show();
      $('#correo_recuperacion').hide();
      $('#btns_option').hide();     
    }


    componentDidMount(){

      //$('#modal-terminos_condiciones').modal('show'); 

      $('[data-toggle="tooltip"]').tooltip()
      this.detalle_membresia();
      $('#registro').hide();
      $('#label_nomprestador').hide(); 
      $('#link_panelweb').hide();      
      $('#btn_cerraractivacion').hide();
      $('#vista_imgs').hide();
      $('#vista_imgs1').hide();
      $('#vista_imgs2').hide();
      $('#rutas_logos').hide();
      $('#tabla_imgs1').hide();
      $('#tabla_imgs2').hide();
      $('#cambio_contraseña').hide();

      $('#tarjeta_credito').hide();
      $('#pse').hide();
      $('#bancolombia').hide();
      $('#nequi').hide();
      $('#compra_paso2').hide();
      $('#compra_paso3').hide();
      $('#btn_compra_paso2').hide();  
      $('#btn_compra_paso3').hide();  
      $('#img_paso2').hide();  

      $('#form_registro').hide();
      $('#paso1').show();
      $('#paso2').hide();
      $('#paso3').hide();

      if(tipo_pres=='instituciones'){
        $('#sel_cc').hide();
        $('#sel_ce').hide();
        $('#label_razonsocial').show();
        $('#label_nomprestador').hide();
        $('#sel_ips').show();
        $('#sel_independiente').hide();
        $('#sel_transporte').show();
      }else{

        if(tipo_pres=='independiente'){

          $('#sel_nit').hide();
          $('#label_razonsocial').hide();
          $('#label_nomprestador').show();
          $('#sel_ips').hide();
          $('#sel_independiente').show();
          $('#sel_transporte').hide();

        }
        
      }

    }


    validar_paso1_compra(){

      if(this.state.form.tipoid_comprador_general=='null' || this.state.form.id_comprador_general.length==0 || this.state.form.nombres_comprador_general.length==0 || this.state.form.apellidos_comprador_general.length==0 || this.state.form.dir_comprador_general.length==0 || this.state.form.tel_comprador_general.length==0 || this.state.form.tel_comprador_general.length==0){
  
        store.addNotification({
          title: 'Atención',
          message: "Faltan datos del comprador por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        $('#img_paso1').hide();
        $('#img_paso2').show();

        $('#compra_paso1').hide();
        $('#compra_paso2').show();
  
        $('#btn_compra_paso1').hide();
        $('#btn_compra_paso2').show();

      }

      
    
    }


    dropmodal_comprar_membresia() {

      $("#modal-compramembresia input").val("");
      $("#modal-compramembresia input[type='radio']").prop('checked', false).change();      
      $("#tipoid_comprador_general").val($('#tipoid_comprador_general > option:first').val());
      $("#mes_tc").val($('#mes_tc > option:first').val());
      $("#year_tc").val($('#year_tc > option:first').val());
      $("#cuotas_tc").val($('#cuotas_tc > option:first').val());
      $("#cuotas_tc").val($('#cuotas_tc > option:first').val());
      $("#tipo_persona_pse").val($('#tipo_persona_pse > option:first').val());
      $("#id_banco_pse").val($('#id_banco_pse > option:first').val());


      this.setState({
        form:{
          id_inst:this.state.form.id_inst,
          dv:this.state.form.dv,
          nom_inst:this.state.form.nom_inst,
          dir_inst:this.state.form.dir_inst,
          tele_inst:this.state.form.tele_inst,
          tipoid_comprador_general:'null',
          id_comprador_general:this.state.form.id,
          nombres_comprador_general:this.state.form.nom_inst,
          apellidos_comprador_general:'',
          email_inst:this.state.form.email_inst,
          dir_comprador_general:'',
          tel_comprador_general:'',    
          num_tc:'',
          tarjetahabiente:'',
          mes_tc:'',
          year_tc:'',
          cuotas_tc:'1',
          cod_tc:'',
          tipodcto_pse:'CC',
          numerodcto_pse:'',
          id_banco_pse:'',
          cel_nequi:''     
        }
      }) 
      
      this.Drop_formularios_pagos();
    }

    Drop_formularios_pagos(){

      $('#img_paso1').show();
      $('#img_paso2').hide();

      $('#compra_paso1').show();
      $('#compra_paso2').hide();

      $('#btn_compra_paso1').show();
      $('#btn_compra_paso2').hide();


      $('#tarjeta_credito').hide();
      $('#pse').hide();
      $('#bancolombia').hide();
      $('#nequi').hide();


    }

    atras(){

      $('#img_paso1').show();
      $('#img_paso2').hide();

      $('#compra_paso1').show();
      $('#compra_paso2').hide();
      $('#compra_paso2').hide();

      $('#btn_compra_paso1').show();
      $('#btn_compra_paso2').hide();
      $('#btn_compra_paso3').hide();
    
    }

    atras3(){

      $('#img_paso1').show();
      $('#img_paso2').hide();

      $('#compra_paso1').hide();
      $('#compra_paso2').show();
      $('#compra_paso3').hide();

      $('#btn_compra_paso1').hide();
      $('#btn_compra_paso2').show();
      $('#btn_compra_paso3').hide();
    
    }

    Validar_formularios_compra(){

      if(this.state.form.tipoid_comprador_general=='null' || this.state.form.id_comprador_general.length==0 || this.state.form.nombres_comprador_general.length==0 || this.state.form.apellidos_comprador_general.length==0 || this.state.form.dir_comprador_general.length==0 || this.state.form.tel_comprador_general.length==0 ){
  
        store.addNotification({
          title: 'Atención',
          message: "Faltan datos del comprador por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })
      
      }else{
        if(metodo_pago===''){
          store.addNotification({
            title: 'Atención',
            message: "Seleccione un método de pago",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
        }
      }
      if(metodo_pago==='CARD'){
  
        if(this.state.form.num_tc=='' || this.state.form.tarjetahabiente=='' || this.state.form.mes_tc==='MM' || this.state.form.year_tc==='YY' || this.state.form.cuotas_tc==='0' || this.state.form.cod_tc==''){

          store.addNotification({
            title: 'Atención',
            message: "Faltan datos de la tarjeta por ingresar",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
        }else{
          this.detalle_compraXmetodo();
        }
  
      }else{

        if(metodo_pago==='PSE'){

          if( tipo_persona==='' || this.state.form.id_banco_pse.length==0 ){

            store.addNotification({
              title: 'Atención',
              message: "Faltan datos de PSE por ingresar",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
    
          }else{
            this.detalle_compraXmetodo();
          }              

        }else{

          if(metodo_pago==='NEQUI'){

            if( this.state.form.cel_nequi.length==0 ){

              store.addNotification({
                title: 'Atención',
                message: "Faltan datos de Nequi por ingresar",
                type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                container: 'top-right',                // where to position the notifications
                animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                dismiss: {
                  duration: 10000,
                  showIcon:true
                }
              })
      
            }else{
              this.detalle_compraXmetodo();
            }

          }else{
            if(metodo_pago==='BANCOLOMBIA_TRANSFER'){

              if( tipo_persona==='' ){

                store.addNotification({
                  title: 'Atención',
                  message: "Faltan datos de bancolombia por ingresar",
                  type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                  container: 'top-right',                // where to position the notifications
                  animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                  animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                  dismiss: {
                    duration: 10000,
                    showIcon:true
                  }
                })
        
              }else{
                this.detalle_compraXmetodo();
              }

            }
          }
        }
        
      }

    }

    ComprarMembresia=async()=>{


      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })
  
      await axios.post(ApiURL+"/api/Payments/Membership",  
      {
        typeId: this.state.form.tipoid_comprador_general,
        identification: this.state.form.id_comprador_general,
        names: this.state.form.nombres_comprador_general,
        lastNames: this.state.form.apellidos_comprador_general,
        email: this.state.form.email_inst,
        address: this.state.form.dir_comprador_general,
        phone: this.state.form.tel_comprador_general,
        institutionId: institution_id,
        membershipId: id_membresia,
        paymentMethod: {
          type: metodo_pago,
          installments: this.state.form.cuotas_tc,
          card: {
            number: this.state.form.num_tc,
            cvc: this.state.form.cod_tc,
            expMonth: this.state.form.mes_tc,
            expYear: this.state.form.year_tc,
            cardHolder: this.state.form.tarjetahabiente
          },
          userType: tipo_persona,
          paymentDescription: "Compra Membresia",
          phoneNumber: this.state.form.cel_nequi,
          userLegalIdType: this.state.form.tipoid_comprador_general,
          userLegalId: this.state.form.id_comprador_general,
          financialInstitutionCode: this.state.form.id_banco_pse
        }
      } )          
  
      .then(response=>{
        console.log(response.data)
        mensaje  = response.data.message;
        url  = response.data.data.transactionId;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        console.log(url)     
        
        if(mensaje=='No fué posible generar la transacción.'){
  
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
  
        }else{
  
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
          //window.location.href=url;
        //window.open(url)
        $('#modal-compramembresia').modal('hide');
        this.setState({ loading_recurso: this.state.loading_recurso = false })
        $('#modal-loader').modal('show') // <- to show modal descarga video
  
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
  
        this.long_polling()
  
        }             
        
        
        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          this.setState({ loading_recurso: this.state.loading_recurso = false })
  
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })
  
  
    }

    long_polling=async()=>{

      if(metodo_pago==='CARD'){
  
          axios.get(ApiURL+"/api/Payments/GetStatusOrder/"+url)
          .then((response) => {
  
            if(response.status=='200'){
      
              console.log(response.data.data.status);
      
              url_pse=response.data.data.status
      
      
              store.addNotification({
                title: 'Muy bien',
                message: 'Su compra con tarjeta de crédito ha sido: '+url_pse,
                type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
                container: 'top-right',                // where to position the notifications
                animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                dismiss: {
                  duration: 10000,
                  showIcon:true
                }
              })
  
              $('#modal-compramembresia').modal('hide');
              this.setState({ loading_recurso: this.state.loading_recurso = true })
              $('#modal-loader').modal('hide') // <- to hide modal proceso compre
  
              this.dropmodal_comprar_membresia();
  
              window.location.href="/detalle_compra?id="+url; 
  
      
      
            }else{
                this.long_polling();  
        
              } 
          
       
          })
          .catch((error) => {
            console.log(error.message);
            //console.log(error.response.data.data);
  
            if(error.response.status=='417'){
  
              url_pse=error.response.data.message
      
      
              store.addNotification({
                title: 'Atención',
                message: url_pse,
                type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                container: 'top-right',                // where to position the notifications
                animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                dismiss: {
                  duration: 10000,
                  showIcon:true
                }
              })
  
              $('#modal-compramembresia').modal('hide');
              this.setState({ loading_recurso: this.state.loading_recurso = true })
              $('#modal-loader').modal('hide') // <- to hide modal proceso compre
  
              this.dropmodal_comprar_membresia();
  
              window.location.href="/detalle_compra?id="+url;
  
            }else{
  
              this.long_polling()
  
            }
   
            
          })
  
      }else{
        
              if(metodo_pago==='PSE'){
        
                axios.get(ApiURL+"/api/Payments/GetStatusOrder/"+url)
                .then((response) => {
        
                  if(response.status=='200'){
            
                    console.log(response.data.data.payment_method.extra.async_payment_url);
            
                    url_pse=response.data.data.payment_method.extra.async_payment_url
  
                    this.setState({ loading_recurso: this.state.loading_recurso = true })
                    $('#modal-loader').modal('hide') // <- to hide modal proceso compre
            
            
                    window.location.href=url_pse;   
            
            
                  }else{
                    this.long_polling();  
            
                  }         
       
                })
                .catch((error) => {
                  //console.log(error.message);
                 
                  console.log(error.response.data.data.payment_method);
                  if(error.response.data.data.payment_method.extra==null){
  
                  this.long_polling();
        
                  }else{
        
                    let msj_pse=error.response.data.message
        
                    url_pse=error.response.data.data.payment_method.extra.async_payment_url
            
                    store.addNotification({
                      title: 'Atención',
                      message: msj_pse,
                      type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
                      container: 'top-right',                // where to position the notifications
                      animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                      animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                      dismiss: {
                        duration: 10000,
                        showIcon:true
                      }
                    })
        
                    $('#modal-compramembresia').modal('hide');
                    this.setState({ loading_recurso: this.state.loading_recurso = true })
                    $('#modal-loader').modal('hide') // <- to hide modal proceso compre
        
                    this.dropmodal_comprar_membresia();
      
                    window.location.href=url_pse;
                    //window.location.href="http://app.medicall24.com.co/detalle_compra?id="+url;
                    //this.long_polling()
        
                  }
        
                })
  
        }else{
  
            if(metodo_pago==='BANCOLOMBIA_TRANSFER'){
        
            axios.get(ApiURL+"/api/Payments/GetStatusOrder/"+url)
            .then((response) => {
    
              if(response.status=='200'){
        
                console.log(response.data.data.payment_method.extra.async_payment_url);
        
                url_pse=response.data.data.payment_method.extra.async_payment_url   
                
                this.setState({ loading_recurso: this.state.loading_recurso = true })
                $('#modal-loader').modal('hide') // <- to hide modal proceso compre
        
                window.location.href=url_pse;   
        
        
              }else{
                this.long_polling();  
        
              }         
   
            })
            .catch((error) => {
              //console.log(error.message);
              console.log(error.response.data.data.payment_method);
              if(error.response.data.data.payment_method.extra==null){
  
                this.long_polling();
    
              }else{
    
                let msj_pse=error.response.data.message
        
                url_pse=error.response.data.data.payment_method.extra.async_payment_url
        
                store.addNotification({
                  title: 'Atención',
                  message: msj_pse,
                  type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
                  container: 'top-right',                // where to position the notifications
                  animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                  animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                  dismiss: {
                    duration: 10000,
                    showIcon:true
                  }
                })
    
                $('#modal-compramembresia').modal('hide');
                this.setState({ loading_recurso: this.state.loading_recurso = true })
                $('#modal-loader').modal('hide') // <- to hide modal proceso compre
    
                this.dropmodal_comprar_membresia();
  
                window.location.href=url_pse;
                //window.location.href="http://app.medicall24.com.co/detalle_compra?id="+url;
                //this.long_polling()
    
              }
    
            })
  
          }else{
  
            if(metodo_pago==='NEQUI'){
        
              axios.get(ApiURL+"/api/Payments/GetStatusOrder/"+url)
              .then((response) => {
      
                if(response.status=='200'){
          
                  console.log(response.data.data.status);
      
                  url_pse=response.data.data.status
          
          
                  store.addNotification({
                    title: 'Muy bien',
                    message: 'Su compra con Nequi ha sido: '+url_pse,
                    type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
                    container: 'top-right',                // where to position the notifications
                    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                    dismiss: {
                      duration: 10000,
                      showIcon:true
                    }
                  })
      
                  $('#modal-compramembresia').modal('hide');
                  this.setState({ loading_recurso: this.state.loading_recurso = true })
                  $('#modal-loader').modal('hide') // <- to hide modal proceso compre
      
                  this.dropmodal_comprar_membresia(); 
  
                  window.location.href="/detalle_compra?id="+url;
          
          
                }else{
                  this.long_polling();  
          
                }         
     
              })
              .catch((error) => {
                console.log(error.message);
                
                if(error.response.status=='417'){
  
                  url_pse=error.response.data.message
          
          
                  store.addNotification({
                    title: 'Atención',
                    message: url_pse,
                    type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                    container: 'top-right',                // where to position the notifications
                    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                    dismiss: {
                      duration: 10000,
                      showIcon:true
                    }
                  })
      
                  $('#modal-compramembresia').modal('hide');
                  this.setState({ loading_recurso: this.state.loading_recurso = true })
                  $('#modal-loader').modal('hide') // <- to hide modal proceso compre
      
                  this.dropmodal_comprar_membresia();
  
                  window.location.href="/detalle_compra?id="+url;
      
                }else{
      
                  this.long_polling()
      
                }
      
              })
    
            }
  
          }
  
  
        }
  
      } 
      
  
    }


    detalle_membresia(){

      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/Products/GetById/"+id_membresia)
      .then((response) => {
        console.log(response.data);
        
        this.setState({Detalle_membresia: response.data}) 

        this.setState({ loading: this.state.loading = true })

        $('#form_registro').show();

      })
      .catch((error) => {
        console.log(error);
      })
      

    }

    detalle_compraXmetodo(){      
      
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      axios.post(ApiURL+"/api/Payments/GetDetailPayment",
      {
        productID: id_membresia,
        PaymentMethod: metodo_pago        
      })
      .then((response) => {
        console.log(response.data);
        
        this.setState({Detalle_compra: response.data}) 

      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })

        $('#compra_paso2').hide();
        $('#compra_paso3').show();

        $('#btn_compra_paso2').hide();
        $('#btn_compra_paso3').show();


      })
      .catch((error) => {
        console.log(error);

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
      })
      

    }


    paso1(){

      this.Buscar_Prestador();

      if(tipoid=='CC' || tipoid=='CE' || tipoid=='null'){

        if(tipoid=='null' || this.state.form.id_inst.length==0 || this.state.form.dv.length==0 || this.state.form.cod_pres.length==0 || this.state.form.nombre1.length==0 || this.state.form.apellido1.length==0 || clasepres=='null' || this.state.form.email_inst.length==0 ){

          store.addNotification({
            title: 'Atención',
            message: "Faltan datos por ingresar",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
        }else{

          if(valida_reps==1){

            $('#paso1').hide();
            $('#paso2').show();
            $('#paso3').hide();

          }
  
          
  
        }

      }else{

        if(tipoid=='NIT' || tipoid=='null'){

          if(tipoid=='null' || this.state.form.id_inst.length==0 || this.state.form.dv.length==0 || this.state.form.cod_pres.length==0 || this.state.form.nom_inst.length==0 || clasepres=='null' || this.state.form.email_inst.length==0 ){

            store.addNotification({
              title: 'Atención',
              message: "Faltan datos por ingresar",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
    
          }else{

            if(valida_reps==1){

              $('#paso1').hide();
              $('#paso2').show();
              $('#paso3').hide();

            }    
            
    
          }

        }

      }      

    }

    validar_paso1(){

      if(tipoid=='CC' || tipoid=='CE'){

        this.setState({
          form:{
            id_inst:this.state.form.id_inst,
            dv:this.state.form.dv,
            nom_inst:this.state.form.nombre1 +' '+ this.state.form.nombre2   + ' '+ this.state.form.apellido1 + ' '+ this.state.form.apellido2,   
            nombre1:this.state.form.nombre1,  
            nombre2: this.state.form.nombre2,
            apellido1:this.state.form.apellido1,
            apellido2:this.state.form.apellido2, 
            pass:this.state.form.pass, 
            pass_confirm:this.state.form.pass,
            cod_pres:this.state.form.cod_pres,     
            email_inst:this.state.form.email_inst,
            pag_web:this.state.form.pag_web,
            dir_inst:this.state.form.dir_inst,
            tele_inst:this.state.form.tele_inst,
            tele_inst2:this.state.form.tele_inst2,
            id_rl:this.state.form.id_rl,
            nom1_rl:this.state.form.nom1_rl, 
            nom2_rl:this.state.form.nom2_rl,
            apell1_rl:this.state.form.apell1_rl,
            apell2_rl:this.state.form.apell2_rl,             
            pass_cuenta:'',
            cod_activa1:this.state.form.cod_activa1,
            cod_activa2:this.state.form.cod_activa2,
            cod_activa3:this.state.form.cod_activa3,
            cod_activa4:this.state.form.cod_activa4,
            cod_activa5:this.state.form.cod_activa5,
            cod_activa6:this.state.form.cod_activa6,
            llave_publi:this.state.form.llave_publi,
            llave_priv:this.state.form.llave_priv,
            tipoid_comprador_general:'CC',
            id_comprador_general:'',
            nombres_comprador_general:'',
            apellidos_comprador_general:'',
            correo_comprador_general:'',
            dir_comprador_general:'',
            tel_comprador_general:'',    
            num_tc:'',
            tarjetahabiente:'',
            mes_tc:'',
            year_tc:'',
            cuotas_tc:'1',
            cod_tc:'',
            tipodcto_pse:'CC',
            numerodcto_pse:'',
            id_banco_pse:'',
            cel_nequi:''
            
          }
        })


      }else{

        if(tipoid=='NIT'){

          this.setState({
            form:{
              id_inst:this.state.form.id_inst,
              dv:this.state.form.dv,
              nom_inst:this.state.form.nom_inst,   
              nombre1:this.state.form.nom_inst,  
              nombre2: this.state.form.nombre2,
              apellido1:this.state.form.apellido1,
              apellido2:this.state.form.apellido2, 
              pass:this.state.form.pass, 
              pass_confirm:this.state.form.pass_confirm,
              cod_pres:this.state.form.cod_pres,     
              email_inst:this.state.form.email_inst,
              pag_web:this.state.form.pag_web,
              dir_inst:this.state.form.dir_inst,
              tele_inst:this.state.form.tele_inst,
              tele_inst2:this.state.form.tele_inst2,
              id_rl:this.state.form.id_rl,
              nom1_rl:this.state.form.nom1_rl, 
              nom2_rl:this.state.form.nom2_rl,
              apell1_rl:this.state.form.apell1_rl,
              apell2_rl:this.state.form.apell2_rl,              
              pass_cuenta:'',
              cod_activa1:this.state.form.cod_activa1,
              cod_activa2:this.state.form.cod_activa2,
              cod_activa3:this.state.form.cod_activa3,
              cod_activa4:this.state.form.cod_activa4,
              cod_activa5:this.state.form.cod_activa5,
              cod_activa6:this.state.form.cod_activa6,
              llave_publi:this.state.form.llave_publi,
              llave_priv:this.state.form.llave_priv,
              tipoid_comprador_general:'CC',
              id_comprador_general:'',
              nombres_comprador_general:'',
              apellidos_comprador_general:'',
              correo_comprador_general:'',
              dir_comprador_general:'',
              tel_comprador_general:'',    
              num_tc:'',
              tarjetahabiente:'',
              mes_tc:'',
              year_tc:'',
              cuotas_tc:'1',
              cod_tc:'',
              tipodcto_pse:'CC',
              numerodcto_pse:'',
              id_banco_pse:'',
              cel_nequi:''
              
            }
          })

        }
         
      }      

    }

    validar_paso2(){

      if(tiponatu=='null' || idnivel=='0' || Ese=='null' || coddepa=='0' || codmuni=='0' || this.state.form.dir_inst=='' || this.state.form.tele_inst==''){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        $('#paso1').hide();
        $('#paso2').hide();
        $('#paso3').show();

      }

      
     
    }

    validar_atras2(){

      $('#paso1').show();
      $('#paso2').hide();
      $('#paso3').hide();
     
    }

    validar_atras3(){

      $('#paso1').hide();
      $('#paso2').show();
      $('#paso3').hide();
     
    }

    modal_comprar_membresia(){

      axios.get(ApiURL+"/api/Payments/FinancialInstitutions")
      .then((response) => {
        //console.log(response);
        this.setState({Bancos: response.data.data})
      $('#modal-compramembresia').modal('show'); 

      })
      .catch((error) => {
        console.log(error);
      })
      

    }


    label_razon_prestador(){
      
      clasepres='null'
      if(tipoid=='NIT'){
        $('#label_razonsocial').show();
        $('#label_nomprestador').hide();
        $('#sel_ips').show();
        $('#sel_independiente').hide();
        $('#sel_transporte').show();
      }else{
        if(tipoid=='CC' || tipoid=='CE'){

          $('#label_razonsocial').hide();
          $('#label_nomprestador').show();
          $('#sel_ips').hide();
          $('#sel_independiente').show();
          $('#sel_transporte').hide();
        }
        
      }
    }


    mostrarPassword(){
      var cambio = document.getElementById("txtPassword");
		if(cambio.type == "password"){
			cambio.type = "text";
			$('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
		}else{
			cambio.type = "password";
			$('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
		}
    this.mostrarPassword_confirm();
    }


    mostrarPassword_confirm(){
      var cambio_confirm = document.getElementById("txtPassword_confirm");
		if(cambio_confirm.type == "password"){
			cambio_confirm.type = "text";
			$('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
		}else{
			cambio_confirm.type = "password";
			$('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
		}
    }


    Deshabilitar_paso1(val_reps){
      if(val_reps==0){
        this.setState({ active_buttonpaso1: this.state.active_buttonpaso1 = false })
      }else{
        
        this.setState({ active_buttonpaso1: this.state.active_buttonpaso1 = true })
        
      }
    }


    Buscar_Institucion=async()=>{

      if(tipoid=='null' || this.state.form.id_inst.length==0){

        store.addNotification({
          title: 'Atención',
          message: "Los campos tipo ID o Identificación/NIT no han sido diligenciados",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        if(this.state.form.email_inst.length > 0){

        }else{       

        axios.post(ApiURL+"/api/Institutions/GetInstitution",

        {
        identification: this.state.form.id_inst,
        typeId: tipoid        
        })  
        
      .then(response=>{
        console.log(response.data)
        if(response.data.providerClass=='ips'){
          tipo_usuario='Institución IPS'
        }else{
          if(response.data.providerClass=='profesional independiente'){
            tipo_usuario='Profesional Independiente'
          }
        }
        
        msj_prestador="El usuario ya se encuentra registrado como " + tipo_usuario+ ", inicia sesión y prueba las ventajas de usar Medicall24."
       
        this.setState({
          form:{
            pass_cuenta:this.state.form.pass_cuenta,
            email_cuenta:this.state.form.email_cuenta,
            id_inst:this.state.form.id_inst,
            dv:this.state.form.dv,
            cod_pres:this.state.form.cod_pres,
            nombre1:this.state.form.nombre1,
            apellido1:this.state.form.apellido1,
            nom_inst:this.state.form.nom_inst,
            email_inst:this.state.form.email_inst               
          }
        })

        $('#modal-verificaprestador').modal('show');
        this.setState({ active_buttonpaso1: this.state.active_buttonpaso1 = false })
  
      })
      .catch(error=>{

        this.setState({ active_buttonpaso1: this.state.active_buttonpaso1 = true })

        this.Buscar_Usuario();
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

       }

      }
  
    }


    Buscar_Prestador=async()=>{

      if(valida_reps==0){

        if(tipoid=='null' || this.state.form.id_inst.length==0){

          store.addNotification({
            title: 'Atención',
            message: "Los campos tipo ID o Identificación/NIT no han sido diligenciados",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
        }else{
             
  
          var settings = {
            "url": "https://www.datos.gov.co/resource/c36g-9fc2.json?numeroidentificacion="+this.state.form.id_inst+"&$$app_token=HuxfLvwqb4S89Mpmbpsp0s5l3",
            "method": "GET",
            "timeout": 0,
          };
          
          $.ajax(settings).done(function (response) {
            if(response.length==0){
              
              store.addNotification({
                title: 'Atención',
                message: "La identificación ingresada no se encuentra inscrita en el REPS. Para realizar el registro en Medicall24 debes estar habilitado como Prestador de Salud en Colombia. Si crees que hay un error, comunícate con nosotros por medio de nuestros canales virtuales.",
                type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                container: 'top-right',                // where to position the notifications
                animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                dismiss: {
                  duration: 10000,
                  showIcon:true
                }
              })
  
              valida_reps=0
              //alert(valida_reps)         
              
            }else{
  
              store.addNotification({
                title: 'Atención',
                message: "La identificación ingresada se encuentra inscrita en el REPS con la razón social: "+response[0].nombresede,
                type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
                container: 'top-right',                // where to position the notifications
                animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                dismiss: {
                  duration: 10000,
                  showIcon:true
                }
              })
  
              valida_reps=1
              //alert(valida_reps)
                
  
            }
            //console.log(response[0].nombresede);
          });
  
          //this.Deshabilitar_paso1(valida_reps)
        
  
        }

      }else{

        var settings = {
          "url": "https://www.datos.gov.co/resource/c36g-9fc2.json?numeroidentificacion="+this.state.form.id_inst+"&$$app_token=HuxfLvwqb4S89Mpmbpsp0s5l3",
          "method": "GET",
          "timeout": 0,
        };
        
        $.ajax(settings).done(function (response) {
          if(response.length==0){
            
            store.addNotification({
              title: 'Atención',
              message: "La identificación ingresada no se encuentra inscrita en el REPS. Para realizar el registro en Medicall24 debes estar habilitado como Prestador de Salud en Colombia. Si crees que hay un error, comunícate con nosotros por medio de nuestros canales virtuales.",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            valida_reps=0
            //alert(valida_reps)  
            $('#paso1').show();
            $('#paso2').hide();
            $('#paso3').hide();       
            
          }else{

            

            valida_reps=1
            //alert(valida_reps)
              

          }
          //console.log(response[0].nombresede);
        });

        //this.Deshabilitar_paso1(valida_reps)

      }      

      
  
    }



    Buscar_Usuario=async()=>{

      if(tipoid=='null' || this.state.form.id_inst.length==0){

        store.addNotification({
          title: 'Atención',
          message: "Los campos tipo de ID y/o identificación están vacios!!!",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        if(this.state.form.email_inst.length > 0){

        }else{
       

        axios.post(ApiURL+"/api/Users/GetUser",

        {
        identification: this.state.form.id_inst,
        typeId: tipoid        
        })  

      .then(response=>{
        console.log(response.data)
        if(response.data.rol=='Professional'){
          tipo_usuario='Profesional'
        }else{
          if(response.data.rol=='Patient'){
            tipo_usuario='Paciente'
          }
        }
        
        msj_usuario="Ya eres un usuario de Medicall24, si deseas registrarte como un Prestador de Salud (Profesional Independiente), ingresa la misma contraseña que utilizaste para iniciar sesión en la aplicación."

        this.setState({
          form:{
            pass_cuenta:this.state.form.pass_cuenta,
            email_cuenta:response.data.user.email,
            id_inst:this.state.form.id_inst,
            dv:this.state.form.dv,
            cod_pres:this.state.form.cod_pres,
            nombre1:this.state.form.nombre1,
            apellido1:this.state.form.apellido1,
            nom_inst:this.state.form.nom_inst,
            email_inst:this.state.form.email_inst               
          }
        })
       

        $('#modal-confirmaridentidad').modal('show');

        $('#modal-recoverypass').modal('hide');
        $('#mensaje_recupera').show();
        $('#cambio_contraseña').hide();
        $('#correo_recuperacion').show();
        $('#btns_option').show();  

    
        
        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

       }

      }
  
     } 


     Buscar_Usuario_pass=async()=>{

      if (this.state.form.pass_cuenta.length==0){

        store.addNotification({
          title: 'Atención',
          message: "Ingrese una contraseña",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = false })
        this.setState({ button_activacuenta: this.state.button_activacuenta = false })

        axios.post(ApiURL+"/api/Auth/SimpleLogin",

        {
        email: this.state.form.email_cuenta,
        password: this.state.form.pass_cuenta
        
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  =response.data.user.id +' - '+ response.data.user.name1;
        //alert(mensaje);

      

          this.setState({
            form:{
              id_inst:response.data.user.identification,
              dv:this.state.form.dv,
              nom_inst:response.data.user.name1 ? response.data.user.name1 : '' +' '+ response.data.user.name2 ? response.data.user.name2 : ''   + ' '+ response.data.user.lastname1 ? response.data.user.lastname1 : '' + ' '+ response.data.user.lastname2 ? response.data.user.lastname2 : '',   
              nombre1:response.data.user.name1 ? (response.data.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',  
              nombre2: response.data.user.name2 ? (response.data.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
              apellido1:response.data.user.lastname1 ? (response.data.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
              apellido2:response.data.user.lastname2 ? (response.data.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '', 
              pass:'00000', 
              pass_confirm:'00000',
              cod_pres:this.state.form.cod_pres,     
              email_inst:response.data.user.email,
              pag_web:this.state.form.pag_web,
              dir_inst:'',
              tele_inst:this.state.form.tele_inst1,
              tele_inst2:this.state.form.tele_inst2,
              id_rl:this.state.form.id_rl,
              nom1_rl:this.state.form.nom1_rl, 
              nom2_rl:this.state.form.nom2_rl,
              apell1_rl:this.state.form.apell1_rl,
              apell2_rl:this.state.form.apell2_rl,              
              pass_cuenta:'',
              llave_publi:this.state.form.llave_publi,
              llave_priv:this.state.form.llave_priv,
              
            }
          })

        
          $('#show_password').hide();
        
        
        
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
        //alert(this.state.form.patientid)
               
        //window.location.href="/home";
      store.addNotification({
          title: 'Muy bien',
          message: "Ahora ingresa los datos solicitados en el formulario de registro para finalizar.",
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration:10000,
            showIcon:true
          }
        })



      
        this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
        this.setState({ button_activacuenta: this.state.button_activacuenta = true })
        this.setState({ nom_inst_active: this.state.nom_inst_active = false })

        $('#modal-confirmaridentidad').modal('hide');
        
        
        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message

          store.addNotification({
            title: 'Atención',
            message: "La contraseña ingresa no es correcta, si la olvidaste, ingresa en la opción de recuperación de constraseña.",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

         

          this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
          this.setState({ button_activacuenta: this.state.button_activacuenta = true })
          
          
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
          this.setState({ button_activacuenta: this.state.button_activacuenta = true })
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
          this.setState({ button_activacuenta: this.state.button_activacuenta = true })
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

      }
  
     }
     
     
    registrar(){    

            //this.saveuser();
            this.saveinstitution();
    
    }


     saveuser=async()=>{

      if(tipo_id=='null' || this.state.id_user==''){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ loading_button: this.state.active_button = false })
        await axios.post(ApiURL+"/api/Users/Create",
  
          {
          identification: this.state.form.id_user,
          typeId: tipo_id,
          password: this.state.form.pass,
          name1: this.state.form.nom1,
          name2: this.state.form.nom2,
          lastName1: this.state.form.apell1,
          lastName2: this.state.form.apell2,
          gender:sexo,
          email:this.state.form.email,
          phone:this.state.form.telefono
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ loading_button: this.state.active_button = true })
          
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ loading_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

      }      
  
     }


     saveinstitution=async()=>{

      if(this.state.form.id_inst.length==0 || this.state.form.cod_pres.length==0 || this.state.form.nom_inst.length==0 || clasepres=='null' || this.state.form.email_inst.length==0 || tiponatu=='null' || idnivel=='0' || Ese=='null' || coddepa=='0' || codmuni=='0' || this.state.form.dir_inst.length==0 || this.state.form.tele_inst.length==0 || this.state.form.nom1_rlh=='' || this.state.form.apell1_rl=='' || tipoidrl=='null' || this.state.form.id_rl=='' || this.state.form.pass.length==0 || this.state.form.pass_confirm.length==0 ||  avatar.length==0 || cover.length==0 ){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        if(this.state.form.pass!==this.state.form.pass_confirm){

          store.addNotification({
            title: 'Atención',
            message: 'Las contraseñas no coinciden',
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })

        }else{
        
        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        let formData = new FormData();
        formData.append('typeId', tipoid)
        formData.append('nit', this.state.form.id_inst)
        formData.append('name', this.state.form.nom_inst)
        formData.append('name1', this.state.form.nombre1)
        formData.append('name2', this.state.form.nombre2)
        formData.append('lastname1', this.state.form.apellido1)
        formData.append('lastname2', this.state.form.apellido2)
        formData.append('Password', this.state.form.pass)
        formData.append('providerCode', this.state.form.cod_pres)
        formData.append('email', this.state.form.email_inst)  
        formData.append('web', this.state.form.pag_web)      
        formData.append('address', this.state.form.dir_inst)
        formData.append('phone1', this.state.form.tele_inst)
        formData.append('phone2', this.state.form.tele_inst2)
        formData.append('departmentId', coddepa)
        formData.append('municipalityId', codmuni)
        formData.append('ProviderClass', clasepres)
        formData.append('LegalNature', tiponatu)
        formData.append('Ese', Ese)
        formData.append('AtentionLevel', idnivel)
        formData.append('TipeIdRepresentative', tipoidrl)
        formData.append('IdRepresentative', this.state.form.id_rl)
        formData.append('NameRepresentative1', this.state.form.nom1_rl)
        formData.append('NameRepresentative2', this.state.form.nom2_rl)
        formData.append('LastNameRepresentative1', this.state.form.apell1_rl)
        formData.append('LastNameRepresentative2', this.state.form.apell2_rl)
        formData.append('PublicKey', "")
        formData.append('PrivateKey', "")
        formData.append('EventsKey',"")
        formData.append('Secretkey',"")
        formData.append('avatar', avatar)
        formData.append('cover', cover)

        await axios.post(ApiURL+"/api/Institutions/Save", formData,{

          headers: {
            'Content-Type': 'multipart/form-data'            
          }

        })  
        .then(response=>{
          //console.log(response)
          mensaje  = response.data.message;
          institution_id = response.data.institution.id
          
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })

          $('#modal-llaveswompi').modal('hide');

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          //$('#alert-activacuenta').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
          if(response.status=='200'){

            if(id_membresia==1){

              $('#modal-creaprestador').modal('show');

            }else{
              $('#form_registro').hide();
              $('#registro').show();
            }           
            

          }else{
            if(response.status=='201' && response.data.emailValidated==true){

              if(id_membresia==1){

                $('#modal-creaprestador').modal('show');
  
              }else{
                $('#form_registro').hide();
                $('#registro').show();
              }              

            }else{
              
              if(response.status=='201' && response.data.emailValidated==false){             

                  this.modal_activacuenta();               

              }
            }
          }
          
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ loading_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
      
      })

       }

      }      
  
     }


     Activar_cuenta=async()=>{

      if(this.state.form.cod_activa1=='' || this.state.form.cod_activa2=='' || this.state.form.cod_activa3=='' || this.state.form.cod_activa4=='' || this.state.form.cod_activa5=='' || this.state.form.cod_activa6==''){

        store.addNotification({
          title: 'Atención',
          message: "Ingrese el codigo de activación enviado al correo electrónico: "+this.state.form.email_inst,
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = false })
        this.setState({ button_activacuenta: this.state.button_activacuenta = false })
        await axios.post(ApiURL+"/api/Users/ActivateAccount",
  
          {
            type: 'Activation',
            validationCode: this.state.form.cod_activa1+this.state.form.cod_activa2+this.state.form.cod_activa3+this.state.form.cod_activa4+this.state.form.cod_activa5+this.state.form.cod_activa6        
          })  

        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })

          this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
          this.setState({ button_activacuenta: this.state.button_activacuenta = true })

 

          if(id_membresia==1){

            $('#btn_activacuenta').hide();
            $('#link_panelweb').show();

          }else{
            $('#modal-activacuenta').modal('hide');
            $('#btn_activacuenta').hide();
            $('#link_panelweb').hide();      
            $('#btn_cerraractivacion').show();

            $('#form_registro').hide();
            $('#registro').show();
          }

          //$('#alert-activacuenta').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
          
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

          this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
          this.setState({ button_activacuenta: this.state.button_activacuenta = true })

          //$('#alert-activacuenta').html('<div class="alert alert-danger" role="alert">'+mensaje+'</div>');

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
            this.setState({ button_activacuenta: this.state.button_activacuenta = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
            this.setState({ button_activacuenta: this.state.button_activacuenta = true })
        }
        //console.log(error.config);
      
      })

      }      
  
     }



     Recuperar_cuenta=async()=>{

      if(!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.state.form.correo_recupera)){
  
        store.addNotification({
          title: 'Atención',
          message: "La dirección de correo electrónico ingresada contiene caracteres no válidos.",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })
  
      }else{
        this.setState({ loading_buttonrecovery_pass: this.state.loading_buttonrecovery_pass = false })
        this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = false })
        await axios.post(ApiURL+"/api/Auth/RecoveryPassword",
  
          {         
            email: this.state.form.correo_recupera          
          })  
  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })
  
          this.setState({ loading_buttonrecovery_pass: this.state.loading_buttonrecovery_pass = true })
          this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = false })
  
          //$('#alert-recoverypass').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
          
          $('#mensaje_recupera').hide();
          $('#cambio_contraseña').show();
          $('#correo_recuperacion').hide();
          $('#btns_option').hide();
          
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
  
            this.setState({ loading_buttonrecovery_pass: this.state.loading_buttonrecovery_pass = true })
            this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = true })
  
          $('#alert-recoverypass').html('<div class="alert alert-danger" role="alert">'+mensaje+'</div>');
  
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })
  
      }      
  
     }


     Cambiar_Contraseña=async()=>{

      if(this.state.form.cod_recuperacion.length==0 || this.state.form.passr.length==0 || this.state.form.pass_confirmr.length==0 ){
  
        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })
  
      }else{
        if(this.state.form.passr!==this.state.form.pass_confirmr){
  
          store.addNotification({
            title: 'Atención',
            message: 'La contraseña ingresada no es correcta',
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })
  
        }else{
        
        this.setState({ loading_buttonchange_pass: this.state.loading_buttonchange_pass = false })
        this.setState({ active_buttonchange_pass: this.state.active_buttonchange_pass = false })    
  
        await axios.post(ApiURL+"/api/Users/ChangePassword", 
  
          {
            validationCode:this.state.form.cod_recuperacion,         
            password: this.state.form.passr          
          })
   
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })
  
          this.setState({ loading_buttonchange_pass: this.state.loading_buttonchange_pass = true })
          this.setState({ active_buttonchange_pass: this.state.active_buttonchange_pass = true })
  
          $('#modal-recoverypass').modal('hide');
          $('#mensaje_recupera').show();
          $('#cambio_contraseña').hide();
          $('#correo_recuperacion').show();
          $('#btns_option').show();   
    
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
  
          this.setState({ loading_buttonchange_pass: this.state.loading_buttonchange_pass = true })
          this.setState({ active_buttonchange_pass: this.state.active_buttonchange_pass = true })
  
          //$('#alert-recoverypass').html('<div class="alert alert-danger" role="alert">'+mensaje+'</div>');
  
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })
  
       }
  
      }      
  
     }


     Validar_Paso1(){

      if(tipoid.length<=0 || this.state.form.id_inst.length<=0 || this.state.form.dv.length<=0 || this.state.form.nom_inst<=0 || this.state.form.email_inst<=0 ){

        store.addNotification({
          title: 'Atención',
          message: 'Faltan datos por ingresar',
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })

      }else{
        
        if(!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.state.form.email_inst)){

          store.addNotification({
            title: 'Atención',
            message: "La dirección de correo electrónico ingresada contiene caracteres no válidos.",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
    
        }else{
          
          this.setState({ btnpaso1: this.state.form.btnpaso1 = <button id="btn_save"  data-toggle="pill" href="#custom-tabs-three-profile" aria-controls="custom-tabs-three-profile"  className="btn btn-save" onClick={()=> this.Validar_Paso1()} > Continuar
                           <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" class="bi bi-arrow-right-square" viewBox="0 0 16 16">
                           <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                           <path fill-rule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                          </svg>
                        </button> })
        }
      }

     }   
     
     Cargar_Municipios(coddepa){    
      
      axios.get(ApiURL+"/api/Departments/ListMunicipalties/"+coddepa)
      .then((response) => {
        console.log(response);
        this.setState({Municipios: response.data})

        if(this.state.Municipios.length>0){
          this.setState({ active: this.state.active = true })
        }else{
          this.setState({ active: this.state.active = false })
        }
        
      })
      .catch((error) => {
        console.log(error);
      })
    }


     handleridtipoid = (t) => {
      console.log(t)
      tipo_id=(t)
      //alert(tipo_id)
    }


    GetImg = (i) =>{      
      this.setState({selectedFile:i.target.files})
      avatar=i.target.files[0]
      files_view=URL.createObjectURL(i.target.files[0]);
      avatar_length="Ha seleccionado "+(i.target.files.length)+" archivo" 
      $('#vista_imgs').show();  
      $('#vista_imgs1').show(); 
      $('#quitar_avatar').hide();
      $('#tabla_imgs2').show();     
      //this.modal_img_perfil() 
      //alert(avatar)
    }

    GetImg_portada = (ip) =>{     
      this.setState({selectedFilep:ip.target.files})
      cover=ip.target.files[0]
      files_viewp=URL.createObjectURL(ip.target.files[0]);
      cover_length="Ha seleccionado "+(ip.target.files.length)+" archivo" 
      $('#vista_imgs').show();  
      $('#vista_imgs2').show(); 
      $('#quitar_portada').hide();
      $('#tabla_imgs1').show();  
      //this.modal_img_portada() 
      //alert(cover)
    }

   

    handleriddepartamento = (d) => {
      console.log(d)
      coddepa=(d)
      //alert(id_profe)
      this.Cargar_Municipios(coddepa)
    }

    


   render(){  
      const {form} = this.state;

      return(      
        
       
        <div style={body} className=""> 

              <nav className="navbar navbar-expand navbar-white navbar-light" id="nav2">       
                 
                      <a id="img_center"  href="https://medicall24.com.co/" className="navbar-brand"> <img  src={img} alt="logo"  width= "230"/> </a>
                      
                      <a id="btn-tengocuenta" href="/" target="_blank"> Iniciar sesión </a>                                            
    
                      {/* <button id="btn_crear" className="btn btn-edit" onClick={()=>{this.modal_llaveswompi()}}> Crear
                        
                      </button>  */}
              </nav>
         
              <div className="content">

                    <div className="container-fluid">

                        <div className="d-flex justify-content-center">
                    
                              {this.state.loading?false: <ReactBootStrap.Spinner animation="border" size="md" />}
                          
                        </div>

                        <div id="form_registro" className="row justify-content-md-center">
  
                            <div className="col-lg-10">

                                  <br/>

                                  <div className="card" id="card_body">
                                    
                                    <div className="card-body">

                                        
                                     
                                        <div id="paso1">
                                            
                                             
                                                <div className="row">
              
                                                      <div className="col-sm-6 offset-3">                                                        
                                                      <img src={paso1} />     
                                                      </div>
                                            
                                                      <div  className="col-sm-3">
                                                         
                                                           <button id="btn_crear" className="btn btn-edit"  disabled={!this.state.active_buttonpaso1} onClick={()=>{this.validar_paso1();this.paso1()} }> Siguiente
                                                               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="2 3 10 10">
                                                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                              </svg>
                                                           </button>        
                                                      </div>
                                          
                                                </div>

                                                <br/> <br/>

                                                <div className="row">
          
                                                        <div className="col-md-5">
                                                               <div className="form-group">                    
                                                                    <b id="asterisco">* </b><label > Tipo ID </label>
                                                                    <select onChange={this.GetTipoID}  className="form-control" name="tipo_id">
                                                                       <option  value="null">Seleccione...</option>
                                                                       <option id="sel_cc" value="CC">Cédula de ciudadania</option> 
                                                                       <option id="sel_ce" value="CE">Cédula de extrangeria</option>
                                                                       <option id="sel_nit" value="NIT">NIT</option>                    
                                                                    </select>
                                                                                        
                                                               </div>                 
                                                        </div>
                                                                       
                                                        <div className="col-md-3">   
                                                                           
                                                               <div className="form-group">                    
                                                                   <b id="asterisco">* </b><label >Identificación / NIT</label>
                                                                   <input type="text" maxLength="10" className="form-control camponum" data-toggle="tooltip" data-placement="top" name="id_inst" onChange={this.handleChange} onKeyDown={this.Campo_numerico()} value={form.id_inst} />
                                                                                        
                                                               </div>
                                                                   
                                                        </div>
          
                                                        <div className="col-md-1">
                                                               <div className="form-group"> 
                                                                  <label >DV</label>
                                                                  <div className="input-group"> 

                                                                      <input type="text" maxLength="2" className="form-control camponum" name="dv" value={form.dv} onChange={this.handleChange} onClick={()=> this.Buscar_Prestador()} />
                                                                      <div className="input-group-append">
                                                                           <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="El dígito de verifiacion es un número único que se les concede a las personas naturales o júridicas, que se encuentra al final del NIT de la persona o empresa, y se distingue porque es separado por un guión." > <span className="fas fa-info-circle"></span> </button>
                                                                      </div>                                                                          
                                                                      
                                                                  </div>                                                              
                                                                                        
                                                               </div>                 
                                                        </div> 
                                                        
                                                        <div className="col-md-3">
                                                             <div className="form-group">                    
                                                                <b id="asterisco">* </b><label >Código de prestador</label>
                                                                <div className="input-group"> 

                                                                    <input type="number" maxLength="12" className="form-control camponum" name="cod_pres" value={form.cod_pres} onChange={this.handleChange} onClick={()=> this.Buscar_Institucion()} onKeyDown={this._handleKeyDown}/>
                                                                    <div className="input-group-append">
                                                                           <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Es el código de habilitación que fue asignado por la dirección departamental de salud, en la inscripción como prestador de salud. Se compone de dos (2) dígitos de departamento, tres (3) de municipio, cinco (5) de un secuencial propio de DTS y dos (2) dígitos del número de la sede. En este campo se debe agregar el código de habilitación generado para la sede principal." > <span className="fas fa-info-circle"></span> </button>
                                                                    </div>

                                                                </div>                      
                                                             </div>                 
                                                        </div>                                                                       
                                                        
          
                                                </div>

                                                <div className="row" id="label_razonsocial">

                                                        <div className="col-md-12">                
                                                              <div className="form-group"> 

                                                                  <b id="asterisco">* </b><label>Razón social del prestador</label>                                                                 
                                                                  <div className="input-group">

                                                                      <input type="text" maxLength="50" className="form-control" name="nom_inst" value={form.nom_inst} onChange={this.handleChange} onClick={()=> this.Buscar_Institucion()} onKeyDown={this._handleKeyDown}/>
                                                                      <div className="input-group-append">
                                                                             <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Nombre de la sede, si es profesional independiente corresponde a los nombres y apellidos" > <span className="fas fa-info-circle"></span> </button>
                                                                      </div>

                                                                  </div>                    
                                                              </div>
                                                                   
                                                        </div>
                                                                                                     
          
                                                </div>

                                                <div className="row" id="label_nomprestador">

                                                        <div className="col-md-3">                
                                                              <div className="form-group">                    
                                                                  
                                                                  <b id="asterisco">* </b><label>Primer nombre</label>
                                                                  <div className="input-group">

                                                                      <input type="text" maxLength="50" className="form-control" name="nombre1" value={form.nombre1} onChange={this.handleChange} onClick={()=> this.Buscar_Institucion()} onKeyDown={this._handleKeyDown}/>
                                                                      <div className="input-group-append">
                                                                             <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al primero nombre si es profesional independiente" > <span className="fas fa-info-circle"></span> </button>
                                                                      </div>

                                                                   </div>                    
                                                              </div>                                                                   
                                                        </div>

                                                        <div className="col-md-3">                
                                                              <div className="form-group">  

                                                                  <label>Segundo nombre</label>
                                                                  <div className="input-group">

                                                                      <input type="text" maxLength="50" className="form-control" name="nombre2" value={form.nombre2} onChange={this.handleChange}/>
                                                                      <div className="input-group-append">
                                                                             <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al segundo nombre si es profesional independiente" > <span className="fas fa-info-circle"></span> </button>
                                                                      </div>

                                                                   </div>                    
                                                              </div>                                                                   
                                                        </div>

                                                        <div className="col-md-3">                
                                                              <div className="form-group"> 

                                                                  <b id="asterisco">* </b><label>Primer apellido</label>
                                                                  <div className="input-group">

                                                                      <input type="text" maxLength="50" className="form-control" name="apellido1" value={form.apellido1} onChange={this.handleChange}/>
                                                                      <div className="input-group-append">
                                                                             <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al primero apellido si es profesional independiente" > <span className="fas fa-info-circle"></span> </button>
                                                                      </div>

                                                                   </div>                    
                                                              </div>                                                                   
                                                        </div>

                                                        <div className="col-md-3">                
                                                              <div className="form-group"> 

                                                                  <label>Segundo apellido</label>
                                                                  <div className="input-group">

                                                                      <input type="text" maxLength="50" className="form-control" name="apellido2" value={form.apellido2} onChange={this.handleChange}/>
                                                                      <div className="input-group-append">
                                                                             <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al segundo apellido si es profesional independiente" > <span className="fas fa-info-circle"></span> </button>
                                                                      </div>

                                                                   </div>                    
                                                              </div>                                                                   
                                                        </div>
                                                                                                     
          
                                                </div>

                                                <div className="row">

                                                      <div className="col-md-5">                
                                                             <div className="form-group">                    
                                                                  <b id="asterisco">* </b><label >Clase prestador</label>
                                                                  <select onChange={this.GetClasepres}  className="form-control" name="clase_pres" >
                                                                     <option  value="null">Seleccione...</option>
                                                                     <option id="sel_ips"  value="ips">Instituciones - IPS</option> 
                                                                     <option id="sel_independiente"  value="profesional independiente">Profesional independiente</option>
                                                                     <option id="sel_transporte"  value="transporte Especial de Pacientes">Transporte Especial de Pacientes</option>                    
                                                                  </select>
                                                                                      
                                                             </div>
                                                                 
                                                      </div>

                                                      <div className="col-md-7">                
                                                               <div className="form-group">                    
                                                                   <b id="asterisco">* </b><label >Correo Electrónico</label>
                                                                   <input type="text" disabled={!this.state.nom_inst_active} maxLength="50" className="form-control" name="email_inst" value={form.email_inst} onChange={this.handleChange}/>
                                                                                        
                                                               </div>
                                                                   
                                                      </div>                                                                                                                  
                                                                                                            
        
                                                </div>                                          

          
                                               {/* {this.state.form.btnpaso1}*/}
                                           
                                
                                        </div>
                                        
                                        <div id="paso2">
                                          
                                                <div className="row">

                                                      <div  className="col-sm-2">
                                                                 
                                                            <button id="btn_anterior" className="btn btn-edit" onClick={()=> this.validar_atras2()}>
                                                               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="currentColor" class="bi bi-arrow-left-short" viewBox="3 3 10 10">
                                                                 <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                                               </svg>
                                                               Atrás
                                                            </button>        
                                                      </div>
              
                                                      <div className="col-sm-7 offset-1">                                                        
                                                      <img src={paso2} />     
                                                      </div>
                                            
                                                      <div  className="col-sm-2">
                                                         
                                                            <button id="btn_crear" className="btn btn-edit" onClick={()=> this.validar_paso2()}> Siguiente
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="2 3 10 10">
                                                                 <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                               </svg>
                                                            </button>        
                                                      </div>
                                          
                                                </div>

                                                <br/> <br/>
                                                
                                                <div className="row">                     
                                                     
                                                                     
                                                      <div className="col-md-4">                
                                                             <div className="form-group">                    
                                                                  <b id="asterisco">* </b><label >Naturaleza jurídica:</label>
                                                                  <select onChange={this.GetTipoNatu}  className="form-control" name="tipo_naturaleza">
                                                                     <option  value="null">Seleccione...</option>
                                                                     <option  value="privada">Privada</option> 
                                                                     <option  value="publica">Pública</option>
                                                                     <option  value="mixta">Mixta</option>                    
                                                                  </select>
                                                                                      
                                                             </div>
                                                                 
                                                      </div> 

                                                      <div className="col-md-4">                
                                                             <div className="form-group">                    
                                                                  <b id="asterisco">* </b><label >Nivel de atención:</label>
                                                                  <select onChange={this.GetNivel} className="form-control" name="nivel">
                                                                     <option  value="0">Seleccione...</option>
                                                                     <option  value="1">1</option>
                                                                     <option  value="2">2</option> 
                                                                     <option  value="3">3</option>                                                                                          
                                                                  </select>
                                                                  
                                                              </div>
                                                                 
                                                      </div>  

                                                      <div className="col-md-4">                
                                                             <div className="form-group">                    
                                                                  <b id="asterisco">* </b> <label >Emprese social del estado:</label>
                                                                  <select onChange={this.GetESE} className="form-control" id="ese" name="ese">
                                                                     <option  value="null">Seleccione...</option>
                                                                     <option id="sel_eseno"  value="false">No</option>
                                                                     <option id="sel_esesi"  value="true">Si</option>                                                                                                                                                                
                                                                  </select>
                                                                  
                                                              </div>
                                                                 
                                                      </div>                                                     
        
                                                </div>
                                                
                                                <div className="row">                                

                                                      <div className="col-md-4">                
                                                             <div className="form-group">                    
                                                                 <b id="asterisco">* </b> <label >Departamento:</label>
                                                                 <Departamentos handleriddepartamento={this.handleriddepartamento} />
                                                                                      
                                                             </div>
                                                                 
                                                      </div> 

                                                      <div className="col-md-4">                
                                                             <div className="form-group">                    
                                                                 <b id="asterisco">* </b><label >Municipio:</label>
                                                                 <select onChange={this.GetMunicipio}  className="form-control" name="cod_muni" disabled={!this.state.active}>
                                                                     <option  value="0"> Seleccione...</option> ,
                                                                     {this.state.Municipios.map(item=>                              
                                                                     <option key={item.id} value={item.id}> {(item.nombre).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                                     )}                                                                      
                                                                 </select>
                                                             </div>
                                                                 
                                                      </div>  

                                                      <div className="col-md-4">                
                                                             <div className="form-group">                    
                                                                 <b id="asterisco">* </b> <label >Dirección:</label>
                                                                 <input type="text" maxLength="100" className="form-control"  name="dir_inst" onChange={this.handleChange}/>
                                                                                      
                                                             </div>
                                                                 
                                                      </div>                                                                         
                                                                                              
          
                                                </div>

                                                <div className="row"> 

                                         
                                                      <div className="col-md-4">                
                                                             <div className="form-group">                    
                                                                 <b id="asterisco">* </b> <label >Telefono 1:</label>
                                                                 <input type="text" maxLength="20" className="form-control camponum"  name="tele_inst" onChange={this.handleChange}/>                                                                                      
                                                             </div>
                                                                 
                                                      </div> 

                                                      <div className="col-md-4">                
                                                             <div className="form-group">                    
                                                                 <label >Telefono 2:</label>
                                                                 <input type="text" maxLength="20" className="form-control camponum"  name="tele_inst2" onChange={this.handleChange}/>                                                                                      
                                                             </div>
                                                                 
                                                      </div>

                                                      <div className="col-md-4">                
                                                             <div className="form-group">                    
                                                                 <label >Página web:</label>
                                                                 <input type="text" maxLength="50" className="form-control"  name="pag_web" onChange={this.handleChange}/>                                                                                      
                                                             </div>
                                                                 
                                                      </div>

                                                </div>                                   


                                    
                                        </div>

                                        <div id="paso3">
                                          
                                                <div className="row">

                                                    <div className="col-lg-8">

                                                        <div className="row">

                                                        <div  className="col-sm-4 col-sm-offset-2">
                                                                 
                                                              <button id="btn_anterior" className="btn btn-edit" onClick={()=> this.validar_atras3()}>
                                                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="currentColor" class="bi bi-arrow-left-short" viewBox="3 3 10 10">
                                                                   <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                                                 </svg>
                                                                 Atrás
                                                              </button>        
                                                            </div>
              
                                                        <div className="col-sm-7 ">

                                                              <img src={paso3} />     

                                                        </div>
                                            
                                                     
                                          
                                                </div>

                                                        <br/> 

                                                        <div className="form-group">                    
                                                                <label >Datos del representante legal</label>
                                                                
                                                        </div>

                                                        <div className="row">
                                                              
                                                              <div className="col-md-6">
                                                                     <div className="form-group">                    
                                                                            <b id="asterisco">* </b> <label >Tipo ID</label>
                                                                            <select onChange={this.GetTipoIDLD}  className="form-control" name="tipo_idrl">
                                                                               <option  value="null">Seleccione...</option>
                                                                               <option  value="CC">Cédula de ciudadania</option> 
                                                                               <option  value="CE">Cédula de extrangeria</option>                                                                                         
                                                                            </select>
                                                                                              
                                                                     </div>                 
                                                              </div>
                                                                             
                                                              <div className="col-md-6">   
                                                                                   
                                                                       <div className="form-group">                    
                                                                           <b id="asterisco">* </b> <label >Identificación</label>
                                                                           <div className="input-group">
                                                                               <input type="number" maxLength="10" className="form-control camponum" name="id_rl" onChange={this.handleChange}/>
                                                                               <div className="input-group-append">
                                                                                     <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al número de identificación del representante legal del prestador" > <span className="fas fa-info-circle"></span> </button>
                                                                              </div>
                                                                           </div>                    
                                                                       </div>
                                                                           
                                                                </div>                                                    
                                                                  
                
                                                        </div>

                                                        <div className="row">                                                           
        
                                                              <div className="col-md-6">                
                                                                      <div className="form-group">                    
                                                                         
                                                                          <b id="asterisco">* </b><label >Primer nombre</label>
                                                                          <div className="input-group">
        
                                                                              <input type="text" maxLength="50" className="form-control" name="nom1_rl" onChange={this.handleChange}/>
                                                                              <div className="input-group-append">
                                                                                     <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al primer nombre del representante legal del prestador" > <span className="fas fa-info-circle"></span> </button>
                                                                              </div>
        
                                                                           </div>                    
                                                                      </div>
                                                                           
                                                                </div>

                                                                <div className="col-md-6">                
                                                                      <div className="form-group">                    
                                                                         
                                                                          <label >Segundo nombre</label>
                                                                          <div className="input-group">
        
                                                                              <input type="text" maxLength="50" className="form-control" name="nom2_rl" onChange={this.handleChange}/>
                                                                              <div className="input-group-append">
                                                                                     <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al segundo nombre del representante legal del prestador" > <span className="fas fa-info-circle"></span> </button>
                                                                              </div>
        
                                                                           </div>                    
                                                                      </div>
                                                                           
                                                                </div>

                                                                
        
                                                        </div>


                                                        <div className="row">       
                                                            

                                                                <div className="col-md-6">                
                                                                      <div className="form-group">                    
                                                                         
                                                                          <b id="asterisco">* </b> <label >Primer apellido</label>
                                                                          <div className="input-group">
        
                                                                              <input type="text" maxLength="50" className="form-control" name="apell1_rl" onChange={this.handleChange}/>
                                                                              <div className="input-group-append">
                                                                                     <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al primer apellido del representante legal del prestador" > <span className="fas fa-info-circle"></span> </button>
                                                                              </div>
        
                                                                           </div>                    
                                                                      </div>
                                                                           
                                                                </div>

                                                                <div className="col-md-6">                
                                                                      <div className="form-group">                    
                                                                         
                                                                          <label >Segundo apellido</label>
                                                                          <div className="input-group">
        
                                                                              <input type="text" maxLength="50" className="form-control" name="apell2_rl" onChange={this.handleChange}/>
                                                                              <div className="input-group-append">
                                                                                     <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al segundo apellido del representante legal del prestador" > <span className="fas fa-info-circle"></span> </button>
                                                                              </div>
        
                                                                           </div>                    
                                                                      </div>
                                                                           
                                                                </div>
        
                                                        </div> 

                                                        <br/>                                              
                                                        

                                                        <div className="form-group">                    
                                                                <label >Datos de acceso</label>
                                                                
                                                        </div>


                                                        <div className="row">
                                                              
                                                              <div className="col-md-6">
                                                                      <div className="form-group">  
                                                                            <b id="asterisco">* </b> <label >Contraseña</label>
                                                                            <div className="input-group">                                                                  
                                                                              <input id="txtPassword" disabled={!this.state.nom_inst_active} type="password" maxLength="20" className="form-control" name="pass" onChange={this.handleChange} value={form.pass} />
                                                                                                                                                                     
                                                                            </div>
                                                                      </div>                 
                                                              </div>
                                                                               
                                                              <div className="col-md-6">  
                                                                      <div className="form-group"> 
                                                                            <b id="asterisco">* </b> <label >Confirmar contraseña</label>             
                                                                            <div className="input-group">                                                                  
                                                                              <input id="txtPassword_confirm" disabled={!this.state.nom_inst_active} type="password" maxLength="20" className="form-control" name="pass_confirm" onChange={this.handleChange} value={form.pass_confirm} /> 
                                                                              <div className="input-group-append">
                                                                                    <button id="show_password" className="btn btn-primary" type="button" data-toggle="tooltip" data-placement="top" title="Mostrar/Ocultar contraseña" onClick={()=> this.mostrarPassword()}> <span class="fa fa-eye-slash icon"></span> </button>
                                                                              </div>                                                                                       
                                                                            </div>
                                                                      </div>
                                                                           
                                                              </div>                                        
                                                                  
                
                                                        </div>

                                                        <br/>

                                                        <div className="row">  
        
                                                              <div className="col-md-12">             
                                                    
                                                                  <div className="form-group form-check">
                                                                    <input type="radio" className="form-check-input" id="exampleCheck1"  value="true" checked={this.state.checked}  />
                                                                    <label id="check_terminos" className="btn form-check-label" htmlFor="exampleCheck1" onClick={()=> this.modal_terminos_condiciones()}>  Leer los Términos y condiciones de uso y la Política de Protección de Datos </label>
                                                                  </div>
                                                            
                                                              </div>
        
                                                             
                                                              {/* <div className="col-md-6">                
                                                                      
                                                              <div className="form-group form-check">
                                                                <input type="radio" className="form-check-input" id="exampleCheck1" onClick={this.GetPoliticas} value="true" />
                                                                <label id="check_terminos" className="form-check-label" htmlFor="exampleCheck1" onClick={()=> this.modal_terminos_condiciones()}>  Política de Protección de Datos</label>
                                                              </div>
                                                                           
                                                              </div>                                                                                         */}
                  
                                                        </div>
                                                
                                                        <div className="row" id="rutas_logos">  
        
                                                              <div className="col-md-6">                
                                                                       <div className="form-group">                    
                                                                           <label >Logo institución</label>
                                                                           <div className="custom-file">
                                                                             <input type="file" name="selectedFile" id="exampleInputFile" accept="image/jpg,image/jpeg,image/bmp,image/png" onChange={this.GetImg}/>
                                                                             <label className="custom-file-label" for="exampleInputFile">{avatar_length}</label>
                                                                           </div> 
                                                                           <button className="btn btn-edit btn-xs" id="vista_imgs1" onClick={()=> this.modal_img_perfil()} >Ver imagen</button>                                                                                       
                                                                       </div>                                                                   
                                                              </div>
        
                                                             
                                                              <div className="col-md-6">                
                                                                       <div className="form-group">                    
                                                                           <label >Logo portada</label>
                                                                           <div className="custom-file">
                                                                             <input type="file" name="selectedFilep" id="exampleInputFilep" accept="image/jpg,image/jpeg,image/bmp,image/png" onChange={this.GetImg_portada}/>
                                                                             <label className="custom-file-label" for="exampleInputFilep">{cover_length}</label>                                                                     
                                                                           </div>
                                                                           <button className="btn btn-edit btn-xs" id="vista_imgs2" onClick={()=> this.modal_img_portada()} >Ver imagen</button>
                                                                                                
                                                                       </div>
                                                                           
                                                              </div>                                                                                        
                  
                                                        </div>

                                                    </div>

                                                   


                                                    <div className="col-lg-4" id="hr_vertical1">

                                                          <div className="row">
                                                              
                                                              <div className="col-sm-12">
                                                      
                                                                  <label>Imagen de portada </label> <b id="asterisco">* </b>

                                                              </div>

                                                              <div className="col-sm-12" id="quitar_portada">
                                                      
                                                                  <img id="avatar_blanco" src={fondo_cover} className="card-img-top" />

                                                              </div> 

                                                            

                                                                  <div className="col-sm-12" id="tabla_imgs1">                                    
                                                               
                                                                        {this.state.selectedFilep && [...this.state.selectedFilep].map((file)=>{
                                        
                                                                          let src,preview,type=file.type;
                                                                           
                                                                          // Coincide con la cadena que comienza con image /
                                                                          if (/^image\/\S+$/.test(type)) {
                                                                              src = URL.createObjectURL(file)
                                                                              preview = <img id="avatar_blanco" src={src} className="card-img-top"/>
                                                                          }else{
                                                                                if (/^video\/\S+$/.test(type)) {
                                                                                  src = URL.createObjectURL(file)
                                                                                  preview = <video id="vdos" src={src} width= "430" loop controls />
                                                                              }
                                                                          }
                                                                          return(  
                                                                                                      
                                                                           preview
                                                                           
                                                                        )})}
                                        
                                                                  </div>                      
                                        
                             
         
                                                                <div id="medida" className="col-sm-6">
    
                                                                    Medidas sugeridas: 1200x628 pixeles
     
                                                                </div>

                                                                <div className="col-sm-2 offset-md-4">
    
                                                                    <div  className="custom-file">
                                                                        <input type="file" name="selectedFilep" id="exampleInputFilep" className="inputfile inputfile-6" accept="image/jpg,image/jpeg,image/bmp,image/png" onChange={this.GetImg}/>
                                                                
                                                                        <label htmlFor="file-6" for="exampleInputFilep" className="btn">
                                                                          <figure>
                                                                        
                                                                          <img src={iconofotoavatar}/>
                                                                          </figure>
                                                                          {/*<span className="iborrainputfile">{num_file}</span>*/}
                                                                        </label>
                                                                    </div>
     
                                                                </div>

                                                                
                                                                <div className="col-sm-12" id="hr_horizontal1">
                                                      
                                                                  <label>Logo de perfil </label> <b id="asterisco">* </b>

                                                                </div>

                                                                <div className="col-sm-9 offset-md-3" id="quitar_avatar">

                                                                    <img id="portada_blanco" src={fondo_avatar}  width="150" />

                                                                </div>

                                                             

                                                                  <div className="col-sm-9 offset-md-3" id="tabla_imgs2">                                    
                                                               
                                                                        {this.state.selectedFile && [...this.state.selectedFile].map((file)=>{
                                        
                                                                          let src,preview,type=file.type;
                                                                           
                                                                          // Coincide con la cadena que comienza con image /
                                                                          if (/^image\/\S+$/.test(type)) {
                                                                              src = URL.createObjectURL(file)
                                                                              preview = <img id="portada_blanco" src={src} width="150"/>
                                                                          }else{
                                                                                if (/^video\/\S+$/.test(type)) {
                                                                                  src = URL.createObjectURL(file)
                                                                                  preview = <video id="vdos" src={src} width= "430" loop controls />
                                                                              }
                                                                          }
                                                                          return(  
                                                                                                       
                                                                           preview
                                                                          
                                                                        )})}
                                        
                                                                  </div>                      
                                        
    
                                                                <div id="medida" className="col-sm-6">
    
                                                                    Medidas sugeridas: 600x600 pixeles
     
                                                                </div>
    
                                                               
                       
                                                                <div className="col-sm-2 offset-md-4">
    
                                                                    <div className="custom-file">
                                                                        <input type="file" name="selectedFile" id="exampleInputFile" className="inputfile inputfile-6" accept="image/jpg,image/jpeg,image/bmp,image/png" onChange={this.GetImg_portada}/>
                                                                       
                                                                        <label htmlFor="file-6" for="exampleInputFile" className="btn">
                                                                         
                                                                          <figure>
                                                                          <img src={iconofotoavatar}/>
                                                                          </figure>
                                                                          {/*<span className="iborrainputfile">{num_file}</span>*/}
                                                                        </label>
                                                                    </div>
     
                                                                </div>

                                                              

                                                                      <div className="col-md-12 text-center">
                                                                              <button id="btn-registrar" className="btn btn-save" disabled={!this.state.active_button} onClick={()=> this.modal_llaveswompi()}>{this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Registrar
                                                                                  <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
                                                                                    <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z"/>
                                                                                    <path d="M8.354 10.354l7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                                                                                  </svg>
                                                                              </button>
                                                                      </div>

                                                          </div>


                                                    </div>


                                                </div>                                
                           

                                               
                                               {/*<Firma/>*/}   
          
                                    
                                        </div>          
                                       
                                        <p type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Los campos marcados con el asterisco (*) de color rojo son obligatorios." id="campos_obligatorios"><b id="asterisco">*</b> Campo obligatorio. </p>
          
                                    </div>
                                    
                                    
                                  </div>
                              
                            </div>

                           {/* <div className="row">  
        
                                                    <div className="col-md-12">             
                                                    
                                                        <div className="form-group form-check">
                                                          <input type="radio" className="form-check-input" id="exampleCheck1"  value="true" checked={this.state.checked}  />
                                                          <label id="check_terminos" className="form-check-label" htmlFor="exampleCheck1" onClick={()=> this.modal_terminos_condiciones()}>  Acepto los Términos y condiciones de uso y la Política de Protección de Datos </label>
                                                        </div>
                                                  
                                                    </div>
                                                    
                                                    <div className="col-md-6">                
                                                            
                                                    <div className="form-group form-check">
                                                      <input type="radio" className="form-check-input" id="exampleCheck1" onClick={this.GetPoliticas} value="true"   />
                                                      <label id="check_terminos" className="form-check-label" htmlFor="exampleCheck1" onClick={()=> this.modal_terminos_condiciones()}>  Política de Protección de Datos</label>
                                                    </div>
                                                                 
                                                    </div> 
        
                            </div>  */}
                            

                            <div className="col-lg-10">


                    
                                  <div className="row">
                                      <div className="col-lg-8">
                                            <div className="row justify-content-md-center">
                                               
                                                    <br/>
                                                    <img src={img_membresia} width="380" /> 
                                                
                                            </div>
                                      </div>
                                      
                                      <div className="col-lg-4">
                                            <div className="card" id="card_body">
                                                  <div className="card-header text-center">
                                                     <b>Resumen de compra</b> <br/>
                                                  </div>
                                                  <div className="card-body">
                                                      <div className="row justify-content-md-center">
                                                   
                                                          <div className="col-sm-8">                                                                      
                                                             
                                                              <b>Subtotal: </b> {this.state.Detalle_membresia.priceCOP ? (new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP", minimumFractionDigits: 0}).format(this.state.Detalle_membresia.priceCOP)) : '0.00'} <br/>
                                                              <b>IVA:  </b> {this.state.Detalle_membresia.percentIVA ? (new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP", minimumFractionDigits: 0}).format(this.state.Detalle_membresia.percentIVA)) : '0.00'} <br/>
                                                              <b>Total: </b> {this.state.Detalle_membresia.priceCOP ? (new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP", minimumFractionDigits: 0}).format(this.state.Detalle_membresia.priceCOP)) : '0.00'} <br/>
                                                          </div>            

                                                      </div>
                                                  </div>
                                            </div>

                                      </div>

                                  </div>                               
                              
                            </div>                         

                            
  
                        </div>

                        <div id="registro" className="row justify-content-md-center">
  
                            <div className="col-lg-10">

                                  <br/>

                                  <div className="card" id="card_body">
                                    
                                    <div className="card-body">                                     
                                  
                                        <div className="tab-content" id="custom-tabs-three-tabContent">

                                          <div className="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                                            
                                                  <div className="row d-flex justify-content-center">
                                                                     
                                                        <img src={icono_aprobado} alt="logo"  width= "55"/>                                                  
                                                                             
                                                  </div>
    
                                                  <br/>
    
    
                                                  <div className="row d-flex justify-content-center">
                                                                     
                                                        <h4 id="h4_compra">Registro exitoso</h4>                                                   
                                                                             
                                                  </div>
    
    
                                                  <div className="row d-flex justify-content-center">
    
                                                        <div className="col col-lg-5 ">

                                                              <h4 id="h4_compra">Datos del titular</h4>
                                                              <br/>
                                                            
                                                              <div className="form-group">                    
                                                                 <p id="espacios" >Nombre o Razón social del prestador:</p>
                                                                 <p id="info_compra" >{form.nom_inst}</p>                                         
                                                                                       
                                                              </div>     
  
                                                              <div className="form-group">                    
                                                                 <p id="espacios" >Identificación / NIT:</p>
                                                                 <p id="info_compra" >{form.id_inst}-{form.dv}</p>                                         
                                                                                     
                                                              </div>   
  
                                                              <div className="form-group">                    
                                                                 <p id="espacios" >Dirección:</p>
                                                                 <p id="info_compra" >{form.dir_inst}</p>                                           
                                                                                     
                                                              </div> 
  
                                                              <div className="form-group">                    
                                                                <p id="espacios" >Teléfono:</p>
                                                                 <p id="info_compra" >{form.tele_inst}</p>                                           
                                                                                     
                                                              </div>  
  
                                                               <div className="form-group">                    
                                                                  <p id="espacios" >Correo electrónico:</p>
                                                                 <p id="info_compra" >{form.email_inst}</p>                                           
                                                                                      
                                                              </div>                                                 
      
      
                                                        </div>
      
                                                        <div className="col col-lg-5" id="hr_vertical">

                                                              <h4 className="offset-3" id="h4_compra">Datos de la compra</h4>
                                                              <br/>

                                                              <div className="form-group offset-3">                    
                                                                  <p id="espacios" >Membresía:</p>
                                                                  <p id="info_compra" >{this.state.Detalle_membresia.name}</p>                                                                                
                                                              </div>
  
                                                              <div className="form-group offset-3">                    
                                                                  <p id="espacios" >Valor:</p>
                                                                  <p id="info_compra" >Subtotal: {this.state.Detalle_membresia.priceCOP ? (new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP", minimumFractionDigits: 0}).format(this.state.Detalle_membresia.priceCOP)) : '0.00'}</p>
                                                                  <p id="info_compra" >IVA: {this.state.Detalle_membresia.percentIVA ? (new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP", minimumFractionDigits: 0}).format(this.state.Detalle_membresia.percentIVA)) : '0.00'}</p>  
                                                                  <p id="info_compra" >Total: {this.state.Detalle_membresia.priceCOP ? (new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP", minimumFractionDigits: 0}).format(this.state.Detalle_membresia.priceCOP)) : '0.00'}</p>                                                     
                                                                                     
                                                              </div>
  
                                                              <div className="form-group offset-3">                    
                                                                <div className="form-group form-check">
                                                                  <input type="radio" className="form-check-input" id="exampleCheck1" checked />
                                                                  <label id="check_terminos" className="form-check-label" htmlFor="exampleCheck1">  Aceptar el contrato de venta de servicios </label>
                                                                </div>                                        
                                                                                     
                                                              </div>      
                                                            
                                                        </div>
    
                                                  </div>  
    
                                          </div>                 
                                         
                                        </div>    
                                      
          
                                    </div>

                                    <div className="card-body col-md-12 col-auto text-center"> 

                                     
                                      <button type="button" id="btn" className="btn btn-primary" onClick={()=> this.modal_comprar_membresia()}> Iniciar pago </button>
                                    
                                    </div>
                                    
                                    
                                  </div>
                              
                            </div>                    
  
                        </div>

                        <div className="row justify-content-md-center text-white">
  
                           
                          Copyright &copy; 2020 Medicall24  &nbsp; <a href="https://medicall24.com.co/"> Terminos y condiciones</a> &nbsp; - Todos los derechos reservados. Version  2.0.4
                   
             
                        </div>

                    </div>
                    
              </div>  

              <div className="modal fade" id="modal-llaveswompi" data-backdrop="static">

                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Llaves asociadas a Wompi</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
                                  <div className="form-group"> 

                                    <label >Llave pública</label>

                                    <div className="input-group">
                                        <input type="text" maxLength="50" className="form-control" name="llave_publi" onChange={this.handleChange} />
                                        <div className="input-group-append">
                                             <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde a la llave pública asignada por Wompi." > <span className="fas fa-info-circle"></span> </button>
                                        </div>                                                                          
                                        
                                    </div>                                                              
                                                          
                                 </div>                 
                          </div>

                          <div className="col-md-12">
                                  <div className="form-group"> 

                                    <label >Llave privada</label>

                                    <div className="input-group">
                                        <input type="text" maxLength="50" className="form-control" name="llave_priv"  onChange={this.handleChange} />
                                        <div className="input-group-append">
                                             <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde a la llave privada asignada por Wompi." > <span className="fas fa-info-circle"></span> </button>
                                        </div>                                                                          
                                        
                                    </div>                                                              
                                                          
                                 </div>                 
                          </div>

                          <div className="col-md-12">
                                  <div className="form-group"> 

                                    <label >Eventos</label>

                                    <div className="input-group">
                                        <input type="text" maxLength="50" className="form-control" name="llave_eventos"  onChange={this.handleChange} />
                                        <div className="input-group-append">
                                             <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al secreto eventos asignado por Wompi." > <span className="fas fa-info-circle"></span> </button>
                                        </div>                                                                          
                                        
                                    </div>                                                              
                                                          
                                 </div>                 
                          </div>

                          <div className="col-md-12">
                                  <div className="form-group"> 

                                    <label >Integridad</label>

                                    <div className="input-group">
                                        <input type="text" maxLength="50" className="form-control" name="llave_integridad"  onChange={this.handleChange} />
                                        <div className="input-group-append">
                                             <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al secreto integridad asignado por Wompi." > <span className="fas fa-info-circle"></span> </button>
                                        </div>                                                                          
                                        
                                    </div>                                                              
                                                          
                                 </div>                 
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.registrar()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}Registrar</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                          </div>
                  </div>                
                </div>
            
              </div>       
              

              <div className="modal fade" id="modal-activacuenta" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Código enviado</b></h4>
                      <a type="button" className="close" href="/" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                      </a>
                    </div>
    
                    <div className="modal-body">

                          <span id="alert-activacuenta"> </span>
                      
                          <div className="row justify-content-md-center"> <br/>

                              <div className="col-md-10">

                                 Hemos enviado el código de activación de la cuenta al correo <strong id="correo_activacion"> {this.state.form.email_inst} </strong> 
                                 ingrésalo para finalizar el registro.

                              </div>
                                                  
                          
                          </div>

                          <br/>

                          <div className="row justify-content-md-center"> <br/>                                      
                              
                                 
                                          <input type="text" className="form-control" maxLength="1" name="cod_activa1" id="cod_activa" onChange={this.handleChange}/>
                                          <input type="text" className="form-control" maxLength="1" name="cod_activa2" id="cod_activa" onChange={this.handleChange}/>    
                                          <input type="text" className="form-control" maxLength="1" name="cod_activa3" id="cod_activa" onChange={this.handleChange}/>
                                          <input type="text" className="form-control" maxLength="1" name="cod_activa4" id="cod_activa" onChange={this.handleChange}/>
                                          <input type="text" className="form-control" maxLength="1" name="cod_activa5" id="cod_activa" onChange={this.handleChange}/>
                                          <input type="text" className="form-control" maxLength="1" name="cod_activa6" id="cod_activa" onChange={this.handleChange}/>                                              
                                         
                          
                          </div> 

                          <br/>

                          <div className="row justify-content-md-center" id="btn_activacuenta"> <br/>

                              <div className="col-md-10">                          
                                              
                                    <button id="btn-registrar" type="button" className="btn btn-save" disabled={!this.state.button_activacuenta} onClick={()=> this.Activar_cuenta()}> {this.state.loading_button_activacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Activar cuenta</button>
                               
                              </div>  

                          </div>

                          

                          <div className="col-md-12 text-center" id="link_panelweb">
                            <a type="button" id="btn-ttc" className="btn" href="/">
                                                   <svg width="22" height="22" viewBox="0 0 16 16" className="bi bi-box-arrow-in-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                   <path fillRule="evenodd" d="M8.146 11.354a.5.5 0 0 1 0-.708L10.793 8 8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/>
                                                   <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 1 8z"/>
                                                   <path fillRule="evenodd" d="M13.5 14.5A1.5 1.5 0 0 0 15 13V3a1.5 1.5 0 0 0-1.5-1.5h-8A1.5 1.5 0 0 0 4 3v1.5a.5.5 0 0 0 1 0V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5h-8A.5.5 0 0 1 5 13v-1.5a.5.5 0 0 0-1 0V13a1.5 1.5 0 0 0 1.5 1.5h8z"/>
                                                   </svg> Iniciar Sesión
                                                 </a>
                          </div>

                          <div className="col-md-12 text-center" id="btn_cerraractivacion">
                            <a type="button" id="btn-ttc" className="btn" data-dismiss="modal">
                                                   <svg width="22" height="22" viewBox="0 0 16 16" className="bi bi-box-arrow-in-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                   <path fillRule="evenodd" d="M8.146 11.354a.5.5 0 0 1 0-.708L10.793 8 8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/>
                                                   <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 1 8z"/>
                                                   <path fillRule="evenodd" d="M13.5 14.5A1.5 1.5 0 0 0 15 13V3a1.5 1.5 0 0 0-1.5-1.5h-8A1.5 1.5 0 0 0 4 3v1.5a.5.5 0 0 0 1 0V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5h-8A.5.5 0 0 1 5 13v-1.5a.5.5 0 0 0-1 0V13a1.5 1.5 0 0 0 1.5 1.5h8z"/>
                                                   </svg> Cerrar
                                                 </a>
                          </div>        
                              
                    </div> 
    
                {/*<div className="modal-footer justify-content-between">      
                                        
                          <button type="button" className="btn btn-primary" disabled={!this.state.button_activacuenta} onClick={()=> this.Activar_cuenta()}> {this.state.loading_button_activacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Activar cuenta</button>           
                    
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>            
              
                             
                    </div> */}

                  </div>
                  
                </div>
                
              </div> 


              <div className="modal fade" id="modal-creaprestador" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Prestador creado</b></h4>
                      <a type="button" className="close"  href="/" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </a>
                    </div>
    
                    <div className="modal-body">

                          <span id="alert-activacuenta"> </span>
                      
                          <div className="row justify-content-md-center"> <br/>

                              <div className="col-md-10">

                                 Su registro como prestador ha sido exítoso

                              </div>
                                                  
                          
                          </div>

                          <br/>

                         


                          <div className="col-md-12 text-center" id="link_panelwebp">
                            <a type="button" id="btn-ttc" className="btn" href="/">
                                                   <svg width="22" height="22" viewBox="0 0 16 16" className="bi bi-box-arrow-in-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                   <path fillRule="evenodd" d="M8.146 11.354a.5.5 0 0 1 0-.708L10.793 8 8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/>
                                                   <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 1 8z"/>
                                                   <path fillRule="evenodd" d="M13.5 14.5A1.5 1.5 0 0 0 15 13V3a1.5 1.5 0 0 0-1.5-1.5h-8A1.5 1.5 0 0 0 4 3v1.5a.5.5 0 0 0 1 0V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5h-8A.5.5 0 0 1 5 13v-1.5a.5.5 0 0 0-1 0V13a1.5 1.5 0 0 0 1.5 1.5h8z"/>
                                                   </svg> Iniciar Sesión
                                                 </a>
                          </div>        
                              
                    </div> 
    
                {/*<div className="modal-footer justify-content-between">      
                                        
                          <button type="button" className="btn btn-primary" disabled={!this.state.button_activacuenta} onClick={()=> this.Activar_cuenta()}> {this.state.loading_button_activacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Activar cuenta</button>           
                    
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>            
              
                             
                    </div> */}

                  </div>
                  
                </div>
                
              </div>

              <div className="modal fade" id="modal-verificaprestador" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Atención</b></h4>
                      <a type="button" className="close" data-dismiss="modal"  aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </a>
                    </div>
    
                    <div className="modal-body">

                          <span id="alert-activacuenta"> </span>
                      
                          <div className="row justify-content-md-center"> <br/>

                              <div className="col-md-11">

                              {msj_prestador}

                              </div>
                                                  
                          
                          </div>

                          <br/>                         


                          <div className="col-md-12 text-center" id="link_panelwebp">
                              <a type="button" id="btn-ttc" className="btn" href="/">
                                 <svg width="22" height="22" viewBox="0 0 16 16" className="bi bi-box-arrow-in-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                 <path fillRule="evenodd" d="M8.146 11.354a.5.5 0 0 1 0-.708L10.793 8 8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/>
                                 <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 1 8z"/>
                                 <path fillRule="evenodd" d="M13.5 14.5A1.5 1.5 0 0 0 15 13V3a1.5 1.5 0 0 0-1.5-1.5h-8A1.5 1.5 0 0 0 4 3v1.5a.5.5 0 0 0 1 0V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5h-8A.5.5 0 0 1 5 13v-1.5a.5.5 0 0 0-1 0V13a1.5 1.5 0 0 0 1.5 1.5h8z"/>
                                 </svg> Iniciar Sesión
                              </a>
                          </div>        
                              
                    </div> 
    
                {/*<div className="modal-footer justify-content-between">      
                                        
                          <button type="button" className="btn btn-primary" disabled={!this.state.button_activacuenta} onClick={()=> this.Activar_cuenta()}> {this.state.loading_button_activacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Activar cuenta</button>           
                    
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>            
              
                             
                    </div> */}

                  </div>
                  
                </div>
                
              </div>


              <div className="modal fade" id="modal-confirmaridentidad">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Confirmar identidad</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
    
                    <div className="modal-body">

                          <span id="alert-activacuenta"> </span>
                      
                          <div className="row justify-content-md-center"> 

                              <div className="col-md-11">

                                 {msj_usuario}
                                

                              </div>
                                                  
                          
                          </div>



                          <br/>

                          <div className="row justify-content-md-center">                                    
                              
                                 
                              <div className="col-md-6">
                                    <div className="form-group">  
                                        
                                          <div className="input-group">                                                                  
                                            <input id="txtPassword" disabled={!this.state.nom_inst_active} type="password" maxLength="20" className="form-control" name="pass_cuenta" onChange={this.handleChange} value={form.pass_cuenta} />                                                                                                                                   
                                          </div>
                                    </div>                 
                              </div>

                              
                          
                          </div> 

                          <br/>

                          <div className="row justify-content-md-center"> 

                              <div className="col-md-10">                          
                                              
                                    <button id="btn-registrar" type="button" className="btn btn-save" disabled={!this.state.button_activacuenta} onClick={()=> this.Buscar_Usuario_pass()}> {this.state.loading_button_activacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Verificar </button>
                               
                              </div>  

                          </div>

                          <div className="col-md-12 text-center">
                              <button id="btn-recoveripass" onClick={()=> this.modal_recoverypass()}>¿Olvidó su contraseña?</button> 
                          </div>

                            
                              
                    </div> 
    
                {/*<div className="modal-footer justify-content-between">      
                                        
                          <button type="button" className="btn btn-primary" disabled={!this.state.button_activacuenta} onClick={()=> this.Activar_cuenta()}> {this.state.loading_button_activacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Activar cuenta</button>           
                    
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>            
              
                             
                    </div> */}

                  </div>
                  
                </div>
                
              </div>


              <div className="modal fade" id="modal-recoverypass">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Recuperar contraseña</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                      </button>
                    </div>
    
                    <div className="modal-body">

                          
                          {/* /<span id="alert-recoverypass"> </span> */}
                      
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 

                              <div className="col-md-12">

                                 Se enviará un código de recuperación al correo electrónico registrado. <strong> {this.state.form.email_inst} </strong> 

                              </div>
                                                  
                          
                          </div>

                          <br/>

                          <div className="row" id="correo_recuperacion">                           
                                          
                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Ingresa el correo:</label>
                                          <input type="text" className="form-control" maxLength="50" name="correo_recupera" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div>                          
                          
                          </div>

                          <div id="cambio_contraseña">

                          <div className="row">                           
                                          
                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Código de validación:</label>
                                          <input type="text" className="form-control" maxLength="6" name="cod_recuperacion" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div>

                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Nueva contraseña:</label>
                                          <input type="password" maxLength="20" className="form-control" name="passr" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div>  

                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Confirmar contraseña:</label>
                                          <input type="password" maxLength="20" className="form-control" name="pass_confirmr" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div> 

                              <div className="col-md-12 text-center">
                                  <button type="submit" id="btn-cambiocontra" className="btn"  disabled={!this.state.active_buttonchange_pass} onClick={()=> this.Cambiar_Contraseña()}> {this.state.loading_buttonchange_pass?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Cambiar</button>                     
                              </div>

                          </div> 

                          </div> 

                             
                              
                    </div> 
    
                    <div className="modal-footer justify-content-between" id="btns_option">      
                                        
                          <button type="button" id="btn_recupera" className="btn btn-primary" disabled={!this.state.active_buttonrecovery_pass} onClick={()=> this.Recuperar_cuenta()}> {this.state.loading_buttonrecovery_pass?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Recuperar contraseña </button>           
                    
                          <button type="button" id="btn_recupera"  className="btn btn-primary" onClick={()=> this.validar_codigo()}>  Tengo un código </button>            
              
                             
                    </div>

                  </div>
                  
                </div>
                
              </div>


              <div className="modal fade" id="modal-compramembresia">

                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header" id="fondo_modal_compra">
                        <h4 className="modal-title"><b>{this.state.Detalle_membresia.name}</b></h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>                       
                      </div>    
    
                      <form>                               

                          <div className="modal-body">
                              
                              <div id="compra_paso1">


                                      <div className="d-flex justify-content-center">              
                                                                                                 
                                            <img src={paso1compra} />                                                             
                                
                                      </div>

                                       
                                      <br/> 

                                      <div className="row justify-content-md-center">

                                            <div className="col-lg-12">

                                                <div className="card" id="card_body">
                                                      <div className="card-header text-center">
                                                         <b>Detalle del producto</b> <br/>
                                                      </div>
                                                      <div className="card-body">
                                                          <div className="row d-flex justify-content-center">
                                                       
                                                              <div className="col col-lg-5 text-right">                                                                      
                                                                 
                                                                  <b>Subtotal: </b> <br/>
                                                                  <b>IVA: </b> <br/>
                                                                  <b>Total: </b> <br/>


                                                              </div> 
                                                              <div className="col-sm-6">                                                                      
                                                                 
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP", minimumFractionDigits: 0}).format(this.state.Detalle_membresia.priceCOP))}</b> <br/>
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP", minimumFractionDigits: 0}).format(this.state.Detalle_membresia.percentIVA))}</b> <br/>
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP", minimumFractionDigits: 0}).format(this.state.Detalle_membresia.priceCOP))}</b> <br/>


                                                              </div>          
    
                                                          </div>
                                                      </div>
                                                </div>
            
                                            </div>
                                  
                                      </div>
                                      
                                      <div className="row justify-content-md-center">

                                          <div className="col-md-5">                
                                                <div className="form-group">                    
                                                    <label > <h5>Datos del comprador</h5></label>
                                                                                                                 
                                                </div>                                             
                                          </div> 
                                  
                                      </div>  

                                      <div className="row">

                                            <div className="col-md-6">
                                                  <div className="form-group"> 

                                                        <label >Tipo ID</label>
                                                        <select  className="form-control" name="tipoid_comprador_general" id="tipoid_comprador_general" onChange={this.handleChange}  required>                                                          
                                                           <option  value="null">Seleccione</option> 
                                                           <option  value="CC">Cédula de ciudadania</option> 
                                                           <option  value="CE">Cédula de extrangeria</option>
                                                           <option  value="NIT">NIT</option>                    
                                                        </select>
                                                                            
                                                  </div>                 
                                            </div> 
                          
                                            <div className="col-md-6">
                                                  <div className="form-group">                    
                                                    <label >Identificación</label>
                                                    <input type="text" className="form-control" name="id_comprador_general" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>
              
                                      </div> 
        
                                      <div className="row">
              
                                            <div className="col-md-6">
                                                  <div className="form-group">                    
                                                    <label >Nombres</label>
                                                    <input type="text" className="form-control" name="nombres_comprador_general" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div> 
              
                                            <div className="col-md-6">
                                                  <div className="form-group">                    
                                                    <label >Apellidos</label>
                                                    <input type="text" className="form-control" name="apellidos_comprador_general" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>                         
                          
                                      </div>

                                      <div className="row">
                          
                                            <div className="col-md-12">
                                                  <div className="form-group">                    
                                                    <label >Enviaremos el código de la membresía a este correo</label>
                                                    <input type="email" className="form-control" name="correo_comprador_general" value={form.email_inst} readOnly/>                      
                                                  </div>                 
                                            </div>                   
                          
                                      </div>                           
                                    
                    
                                      <div className="row">
              
                                            <div className="col-md-6">
                                                  <div className="form-group">                    
                                                    <label >Dirección</label>
                                                    <input type="text" className="form-control" name="dir_comprador_general" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div> 
              
                                            <div className="col-md-6">
                                                  <div className="form-group">                    
                                                    <label >Telefono</label>
                                                    <input type="text" className="form-control" name="tel_comprador_general" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>                         
                          
                                      </div>
                              </div>

                              <div id="compra_paso2">

                                <div className="row">                                      
                                                                                           
                                      <img src={paso2compra} />                                                                
                          
                                </div>

                                <br/> 

                                <div className="row justify-content-md-center">

                                      <div className="col-md-7">                
                                            <div className="form-group">                    
                                                <label > <h5>Seleccione un método de pago</h5></label>
                                                                                                             
                                            </div>                                             
                                      </div> 
                                  
                                </div>  
                                            
                                <div className="form-check">
                                      <div className="input-group">
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="CARD" onClick={this.GetMetodopago}/>
                                            
                                            <label className="form-check-label" for="exampleRadios1">  Tarjeta de crédito  </label>
                                            <div className="input-group-append">
                                                  <img src={card_credit} />
                                            </div>
                                      </div>                                                        
                                           
                                </div> 

                                <br/>

                                <div className="row justify-content-md-center" id="tarjeta_credito">

                                    <div className="col-sm-12">
                                      

                                      <div className="row">
              
                                            <div className="col-md-12">
                                                  <div className="form-group">                    
                                                    <label >Número completo de la tarjeta (sin espacios)</label>
                                                    <input type="text" className="form-control" name="num_tc" placeholder="4400330022001100" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>                        
                          
                                      </div>

                                      <div className="row">
              
                                            <div className="col-md-12">
                                                  <div className="form-group">                    
                                                    <label >Nombre en la tarjeta</label>
                                                    <input type="text" className="form-control" maxLength="30" name="tarjetahabiente" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>                        
                          
                                      </div>

                                      <div className="row">
              
                                            <div className="col-md-4">
                                                  <div className="form-group"> 

                                                        <label >Fecha de expiración</label>
                                                        <div className="input-group">

                                                              <div className="input-group-append">
      
                                                                    <select  onChange={this.handleChange}  className="form-control" name="mes_tc" id="mes_tc" >                                                          
                                                                       <option  value="MM">MM</option>
                                                                       <option  value="01">01</option> 
                                                                       <option  value="02">02</option>
                                                                       <option  value="03">03</option>
                                                                       <option  value="04">04</option> 
                                                                       <option  value="05">05</option>
                                                                       <option  value="06">06</option>  
                                                                       <option  value="07">07</option> 
                                                                       <option  value="08">08</option>
                                                                       <option  value="09">09</option>  
                                                                       <option  value="10">10</option> 
                                                                       <option  value="11">11</option>
                                                                       <option  value="12">12</option>                      
                                                                    </select>
      
                                                              </div>

                                                              <div className="input-group-append">
      
                                                                    <select  onChange={this.handleChange}  className="form-control" name="year_tc" id="year_tc" >                                                          
                                                                       <option  value="YY">YY</option>
                                                                       <option  value="21">21</option> 
                                                                       <option  value="22">22</option>
                                                                       <option  value="23">23</option> 
                                                                       <option  value="24">24</option> 
                                                                       <option  value="25">25</option>
                                                                       <option  value="26">26</option>
                                                                       <option  value="27">27</option> 
                                                                       <option  value="28">28</option>
                                                                       <option  value="29">29</option>
                                                                       <option  value="30">30</option>                                                                            
                                                                    </select>
      
                                                              </div>
                                                        </div>                                                    
                                                                            
                                                  </div>                 
                                            </div> 

                                            <div className="col-md-4">
                                                  <div className="form-group">                    
                                                        <label >Cuotas</label>
                                                        <select  onChange={this.handleChange} className="form-control" name="cuotas_tc"  id="cuotas_tc">                                                         
                                                           <option  value="1">1</option> 
                                                           <option  value="2">2</option> 
                                                           <option  value="3">3</option> 
                                                           <option  value="4">4</option>
                                                           <option  value="5">5</option> 
                                                           <option  value="6">6</option>
                                                           <option  value="7">7</option> 
                                                           <option  value="8">8</option>  
                                                           <option  value="9">9</option> 
                                                           <option  value="10">10</option>
                                                           <option  value="11">11</option> 
                                                           <option  value="12">12</option>                                                                     
                                                        </select>
                                                                              
                                                     </div>                 
                                            </div>

                                            <div className="col-md-4">
                                                  <div className="form-group">                    
                                                    <label >Código de seguridad</label>
                                                    <input type="text" maxLength="3" className="form-control" name="cod_tc" placeholder="000" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div> 

                                      </div>

                                      <hr></hr>

                                    </div>

                                </div>

                                                      
                                <div className="form-check">
                                      <div className="input-group">
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="PSE" onClick={this.GetMetodopago}/>
                                            
                                            <label className="form-check-label" for="exampleRadios2">  PSE  </label>
                                            <div className="input-group-append">
                                                  <img src={pse} />
                                            </div>
                                      </div>                                                        
                                           
                                </div> 

                                <br/>  

                                <div className="row justify-content-md-center" id="pse">

                                    <div className="col-sm-12">

                                      {/* <div className="row">              
                                           
              
                                            <div className="col-md-4">
                                                  <div className="form-group">                    
                                                        <label >Tipo de documento</label>
                                                        <select onChange={this.handleChange} className="form-control" name="tipodcto_pse"  className="form-control">                                                         
                                                           <option  value="CC">CC</option> 
                                                           <option  value="NIT">NIT</option>                                                                        
                                                        </select>
                                                                              
                                                     </div>                 
                                            </div>  

                                            <div className="col-md-8">
                                                  <div className="form-group">                    
                                                    <label >Número de documento</label>
                                                    <input type="text" maxLength="10" className="form-control" name="numerodcto_pse"  onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>                      
                          
                                      </div> */}


                                      <div className="row">

                                            <div className="col-md-4">
                                                  <div className="form-group">                    
                                                        <label >Tipo de persona</label>
                                                        <select onChange={this.GetTipopersona} className="form-control" name="tipo_persona_pse"  id="tipo_persona_pse">                                                         
                                                           <option  value="">Seleccione...</option>
                                                           <option  value="0">Natural</option> 
                                                           <option  value="1">Júridica</option>                                                                        
                                                        </select>
                                                                              
                                                     </div>                 
                                            </div> 
              
                                            <div className="col-md-8">                                                                          
                                                    <div className="form-group">                    
                                                        <label >Banco</label> 
                                                        <select onChange={this.handleChange} className="form-control" name="id_banco_pse" id="id_banco_pse">
                                                            <option  value=""> Seleccione...</option> ,
                                                            {this.state.Bancos.map(banco=>                              
                                                            <option key={banco.financial_institution_code} value={banco.financial_institution_code}> {banco.financial_institution_name.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                            )}
                                                                    
                                                        </select>                                                                         
                                                        
                                                    </div>                                        
                                            </div> 
              
                                                                   
                          
                                      </div>

                                      <hr></hr>

                                    </div>

                                </div>                                  
                                                      
                                <div className="form-check">
                                      <div className="input-group">
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="BANCOLOMBIA_TRANSFER" onClick={this.GetMetodopago}/>
                                            
                                            <label className="form-check-label" for="exampleRadios3">  Bancolombia  </label>
                                            <div className="input-group-append">
                                                  <img src={bancolombia} />
                                            </div>
                                      </div>                                                        
                                           
                                </div> 

                                <br/>
                                                                 
                                                      
                              {/*  <div className="form-check">
                                      <div className="input-group">
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="NEQUI" onClick={this.GetMetodopago}/>
                                            
                                            <label className="form-check-label" for="exampleRadios4">  Nequi  </label>
                                            <div className="input-group-append">
                                                  <img src={nequi} />
                                            </div>
                                      </div>                                                                                                        
                                           
                                </div> 

                                <br/> 

                                <div className="row justify-content-md-center" id="nequi">

                                    <div className="col-sm-12">

                                      <div className="row"> 
                          
                                            <div className="col-md-12">
                                                  <div className="form-group">                    
                                                    <label >Numero de celular (cuenta Nequi)</label>
                                                    <input type="text" maxLength="10" className="form-control" name="cel_nequi" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>
              
                                      </div>  

                                    </div>        

                                                            </div>  */}

                              </div>

                              <div id="compra_paso3">


                                      <div className="d-flex justify-content-center">              
                                                                                                 
                                            <img src={paso3compra} />                                                                
                                
                                      </div>

                                       
                                      <br/> 

                                      <div className="row justify-content-md-center">

                                            <div className="col-lg-12">

                                                <div className="card" id="card_body">
                                                      <div className="card-header text-center">
                                                         <b>Detalle de la compra</b> <br/>
                                                      </div>
                                                      <div className="card-body">
                                                          <div className="row d-flex justify-content-center">
                                                       
                                                              <div className="col col-lg-5 text-right">                                                                      
                                                                 
                                                                  <b>Subtotal: </b> <br/>
                                                                  <b>IVA: </b> <br/>
                                                                  <b>Valor de la transacción: </b> <br/>
                                                                  <b>Total: </b> <br/>


                                                              </div> 
                                                              <div className="col-sm-6">                                                                      
                                                                 
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP"}).format(this.state.Detalle_compra.subtotal))}</b> <br/>
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP"}).format(this.state.Detalle_compra.iva))}</b> <br/>
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP"}).format(this.state.Detalle_compra.commission))}</b> <br/>
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP"}).format(this.state.Detalle_compra.total))}</b> <br/>


                                                              </div>          
    
                                                          </div>
                                                      </div>
                                                </div>
            
                                            </div>
                                  
                                      </div>                                      
                                     
                              </div>
                    
                          </div>
                                
                    
                          <div className="modal-footer justify-content-between" id="btn_compra_paso1">

                                <button type="button" id="btn_compra" className="btn btn-danger" data-dismiss="modal" onClick={()=> this.dropmodal_comprar_membresia()}>Cancelar</button>
                                <button type="submit" id="btn_compra" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.validar_paso1_compra()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Método de pago
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="2 3 10 10">
                                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                        </svg> 
                                </button>

                          </div>

                          <div className="modal-footer justify-content-between" id="btn_compra_paso2">                         

                                <button type="button" id="btn_compra" className="btn btn-danger" onClick={()=> this.atras()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="currentColor" class="bi bi-arrow-left-short" viewBox="3 3 10 10">
                                          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                        </svg>
                                        Atras
                                </button>
                                <button type="button" id="btn_compra" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.Validar_formularios_compra()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Continuar
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="2 3 10 10">
                                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                        </svg> 
                                </button>

                            
                                    
                          </div>

                          <div className="modal-footer justify-content-between" id="btn_compra_paso3">                         

                                <button type="button" id="btn_compra" className="btn btn-danger" onClick={()=> this.atras3()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="currentColor" class="bi bi-arrow-left-short" viewBox="3 3 10 10">
                                          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                        </svg>
                                        Atras
                                </button>
                                <button type="submit" id="btn_compra" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.ComprarMembresia()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Pagar
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="2 3 10 10">
                                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                        </svg> 
                                </button>

                            
                                    
                          </div>

                      </form>

                    </div>
                    
                  </div>
              
              </div>


              <div className="modal fade" id="modal_img_perfil">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Imagen de perfil</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
    
                    <div className="modal-body">
                        
                          <div className="row"> <br/>

                              <img src={files_view} id="imgs" alt="logo" width= "440" className="rounded"/>
                                                                
                          </div>

                    </div> 
    
                    <div className="modal-footer justify-content-between">      
                                                        
                          <button type="button" className="btn btn-primary" data-dismiss="modal">Aceptar</button>            
                                   
                    </div>

                  </div>                  
                </div>                
              </div> 


              <div className="modal fade" id="modal_img_portada">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Imagen de portada</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
    
                    <div className="modal-body">
                        
                          <div className="row"> <br/>
                               
                              <img src={files_viewp} id="imgs" alt="logo" width= "440" className="rounded"/>                                   
                          
                          </div>

                    </div> 
    
                    <div className="modal-footer justify-content-between">      
                                                        
                          <button type="button" className="btn btn-primary" data-dismiss="modal">Aceptar</button>            
              
                             
                    </div>

                  </div>  

                </div>

              </div>  

              <div className="modal fade" id="modal-terminos_condiciones" data-backdrop="static">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h5 className="col-12 modal-title text-center" id="correo_activacion"><b>TÉRMINOS Y CONDICIONES DE USO Y POLÍTICAS DE PRIVACIDAD</b></h5>
                      
                    </div>
    
                    <div className="modal-body">

                          
                          <div className="row justify-content-md-center"> <br/>

                              <div id="titulo_terminos" className="col-md-12">

                                  <b>TÉRMINOS Y CONDICIONES DE USO Y POLÍTICA DE PRIVACIDAD DE LOS CANALES VIRTUALES DE MEDICALL24 SAS</b>

                              </div>
                                                  
                          
                          </div>

                          <br/>
                          <div className="row justify-content-md-center"> <br/>

                              <div id="texto_terminos" className="col-md-12">                    

                                Estos son los Términos y Condiciones de Uso de los CANALES VIRTUALES producidos, suministrados y controlados por MEDICALL24 SAS (en adelante LA COMPAÑIA), los cuales incluyen los siguientes: i) Plataforma web para prestadores de salud denominada “Panel Web”; ii) Aplicación para dispositivos móviles denominada "App MEDICALL24", y iii) Página web con dirección www.medicall24.com.co; por favor lea cuidadosamente.  <br/> <br/>

                                Al hacer clic en la casilla “acepto término y condiciones”, usted habrá manifestado su aceptación sin restricciones de este aviso legal y, por lo tanto, de los Término y Condiciones de uso acá establecidas. Si usted no acepta los Términos y Condiciones de Uso establecidos aquí, usted no podrá acceder ni utilizar los servicios de los CANALES VIRTUALES de LA COMPAÑIA. <br></br>
                                
                                LA COMPAÑÍA puede modificar estos Términos y Condiciones de Uso en cualquier momento sin previo aviso. Usted deberá leer lo contenido en este instrumento legal periódicamente para revisar las normativas aquí establecidas, debido a que las mismas son obligatorias para usted. Los términos "usted", o, "usuario" tal como se usan aquí, se refieren a todas las personas o entidades (naturales o jurídicas) que accedan a los CANALES VIRTUALES de LA COMPAÑÍA. <br></br>

                                Los Términos y Condiciones de Uso generales que a continuación se establecen, regulan el uso de los CANALES VIRTUALES de LA COMPAÑÍA. Si usted no está de acuerdo con estos Términos y Condiciones de Uso, le solicitamos abstenerse de utilizar estos CANALES VIRTUALES de LA COMPAÑÍA, ya que su uso de cualquier forma, indicará que usted acepta tácitamente estos Términos y Condiciones. <br></br><br></br>
                                
                                <b>INTRODUCCIÓN.</b> <br/>
                                Los presentes Términos y Condiciones de Uso de los CANALES VIRTUALES de LA COMPAÑÍA se publican con el fin de informar a todos los usuarios, que LA COMPAÑÍA, ha puesto a su disposición la los CANALES VIRTUALES con la finalidad de realizar transacciones y gestionar sus servicios de manera ágil y segura.<br></br>

                                Los presentes Términos y Condiciones de Uso constituyen un acuerdo legal vinculante entre el usuario de los CANALES VIRTUALES y LA COMPAÑÍA, y establecen las condiciones de su uso. Por lo anterior, es su obligación como usuario de los CANALES VIRTUALES de LA COMPAÑÍA leer cuidadosamente los presentes Términos y Condiciones de Uso. Debe tener en cuenta que, si decide no aceptarlos, no podrá acceder ni utilizar los servicios de los CANALES VIRTUALES de LA COMPAÑÍA; por lo anterior, al hacer Click en la casilla “acepto los Término y Condiciones de Uso”, habrá manifestado su aceptación expresa, sin restricciones, reservas ni modificaciones a este Aviso Legal y por lo tanto a los Términos y Condiciones de Uso acá establecidos. <br></br> <br></br>
                                
                                <b>FUNCIONES DE LOS CANALES VIRTUALES.</b> <br/>
                                Los CANALES VIRTUALES de LA COMPAÑÍA brindan acceso a los usuarios  que cumplen con determinados roles, como lo son: rol paciente, rol médico y rol prestador de salud. Los CANALES VIRTUALES de LA COMPAÑÍA, permiten a los usuarios la interacción con diversas funcionalidades, como por ejemplo: i) agregar contenido de texto, imágenes y videos a su cuenta; ii) opinar sobre el contenido que le gusta, que no le gusta, o que le interesa de otros usuarios; iii) editar y personalizar su perfil; iv) realizar solicitudes de citas médicas por consulta externa, ya sea que estas se gestionen a través de la EPS del paciente o de forma particular; v) dar respuesta a las solicitudes de citas por consulta externa por parte del prestador de salud habilitado; vi) recaudar cuotas moderadoras; vii) recaudar el valor de las consultas particulares; viii) brindar atención médica para las citas de consulta externa bajo la modalidad de telemedicina, que son realizadas por los médicos de los prestadores de salud habilitados; ix) brindar atención médica urgente bajo la modalidad de telemedicina a los pacientes que consulten con médicos disponible en la urgencia del prestador de salud habilitado; x) registrar la información en salud de los pacientes en la historia clínica, luego de finalizar la atención médica; xi) descargar historias clínicas, fórmulas de medicamentos, órdenes médicas y certificados de incapacidad. <br></br>

                                La atención médica es responsabilidad del prestador de salud que brinde el servicio y este debe estar habilitado por el órgano de control competente, acorde a lo estipulado por las normas legales que regulan la materia en Colombia. El servicio de telemedicina que se brinda a través de los CANALES VIRTUALES de LA COMPAÑÍA, se realiza mediante videollamadas en tiempo real (comunicación sincrónica), y permite que los médicos de un prestador de salud atiendan eficientemente a sus pacientes, pudiendo emitir diagnósticos e instaurar tratamientos, y dejar registro de la atención en la clínica del paciente. <br></br>

                                El contenido publicado por los usuarios en los CANALES VIRTUALES de LA COMPAÑÍA, es informativo; por lo tanto, ni LA COMPAÑÍA ni su(s) autor(es), se responsabilizan del entendimiento, la interpretación y el uso de este contenido por parte del usuario, pues la veracidad de la información contenida y publicada, es de exclusiva responsabilidad del usuario que la genera. <br></br>

                                LA COMPAÑÍA se reserva la facultad de modificar o eliminar el contenido publicado en los CANALES VIRTUALES, por sí misma o mediante un tercero autorizado, sin notificar previamente al usuario. <br></br>

                                Con la aceptación de los Términos y Condiciones de Uso de los CANALES VIRTUALES de LA COMPAÑÍA, el usuario se obliga a usar los CANALES VIRTUALES conforme a la normatividad que rige la materia y a los Términos y Condiciones de Uso que aquí se contemplan, siempre en observancia de la ley, la moral, la confidencialidad y las buenas costumbres; así mismo, se compromete a no destinar su uso a fines ilícitos o inmorales, o en detrimento de los interés de terceros o de LA COMPAÑÍA. <br></br>
                                <br></br>
                                <b>LIMITACIÓN DE RESPONSABILIDADES MÉDICAS. </b> <br/>
                                El contenido disponible a través de los CANALES VIRTUALES de LA COMPAÑÍA no sustituye el criterio médico del profesional de la salud llamado a realizar consultas mediante el uso de la telemedicina. LA COMPAÑÍA, por sí misma, no provee servicios médicos, ni de diagnóstico, ni de tratamientos. El contenido publicado en los CANALES VIRTUALES de LA COMPAÑÍA es de exclusiva responsabilidad del usuario que lo genera, por lo tanto, usted sabrá determinar el nivel de confianza en el mismo. Los usuarios registrados en los CANALES VIRTUALES de LA COMPAÑÍA con el rol de médicos, son los únicos que pueden proveer servicios médicos de diagnóstico y tratamiento para pacientes, siempre y cuando estén vinculados en nuestro sistema a un prestador de salud. <br></br>
                                <br></br>
                                <b>REQUISITOS PARA EL ACCESO</b> <br/>
                                LA COMPAÑÍA no garantiza el acceso permanente e ininterrumpido a los CANALES VIRTUALES, ni que este acceso sea libre de errores, o que el servicio o el servidor que lo pone a disposición, estén libres de virus u otros agentes nocivos. Cuando el usuario accede a los CANALES VIRTUALES de LA COMPAÑÍA, será su responsabilidad tomar las medidas pertinentes para evitar y/o corregir los virus u otros agentes nocivos existentes en su dispositivo. <br></br>

                                El usuario será el único responsable del uso de la información contenida en los CANALES VIRTUALES de LA COMPAÑÍA. <br></br>

                                Los CANALES VIRTUALES de LA COMPAÑÍA solo funcionaran si el dispositivo del USUARIO está conectado a internet. <br></br>

                                El correcto funcionamiento de los CANALES VIRTUALES de LA COMPAÑÍA depende de la estabilidad y velocidad de internet y/o de la red de datos a la cual está conectado el dispositivo del USUARIO. <br></br>

                                LA COMPAÑÍA puede permitir el acceso a otros sitios de internet a través de vínculos contenidos en los CANALES VIRTUALES, pero esto no implica ninguna relación contractual o comercial entre LA COMPAÑÍA y el operador del sitio vinculado. LA COMPAÑÍA no es responsable del contenido de ninguno de estos sitios y no garantiza los productos o servicios ofrecidos por el sitio vinculado; por lo tanto, cualquier transacción que el usuario lleve a cabo con estos sitios de internet, se realiza única y exclusivamente bajo su propia responsabilidad y autonomía y el proveedor del servicio del sitio vinculado. LA COMPAÑÍA no es responsable por ningún tipo de transmisión recibida desde cualquier sitio vinculado. <br></br>
                                <br></br>
                                <b>MARCO LEGAL</b> <br/>
                                El marco legal que regirá para los productos y servicios de los CANALES VIRTUALES de LA COMPAÑÍA, será el estipulado para el adecuado tratamiento de los datos personales que sean incorporados o circulen, incluyendo los datos de carácter sensible, de conformidad con lo establecido en la legislación vigente de Habeas Data y por lo previsto en la Ley Estatutaria 1581 de 2012, Decreto Único Reglamentario del Sector Comercio, Industria y Turismo- Decreto 1074 de 2015, la resolución 2654 de 2019, y las Políticas de Privacidad y Tratamiento de Datos Personales adoptadas por LA COMPAÑÍA. <br></br>

                                El contenido de los Términos y Condiciones de Uso aquí previstos, puede ser objeto de modificaciones o actualizaciones, razón por la que será obligación del usuario revisar periódicamente el contenido de los mismos con el fin de mantenerse informado frente a los cambios que se puedan presentar. Por lo anterior, mediante la puesta en conocimiento de los Términos y Condiciones de Uso de los CANALES VIRTUALES de LA COMPAÑÍA, se entenderá cumplido el deber de informar al usuario. <br></br>
                                <br></br>
                                <b>AUTORIZACIÓN DE USO DE LOS CANALES VIRTUALES</b> <br/>
                                LA COMPAÑÍA, autoriza únicamente el uso de los CANALES VIRTUALES, sujeto a las reglas contenidas en el presente acuerdo de Términos y Condiciones de Uso, permaneciendo restringido su uso comercial o con cualquier otro fin diferente al autorizado. <br></br>

                                El usuario se abstendrá de modificar, manipular, alterar, copiar, distribuir, transmitir, reproducir, licenciar, crear sitios web y aplicaciones para dispositivos móviles derivadas, vender o entregar la información recibida de los CANALES VIRTUALES de LA COMPAÑÍA en su dispositivo a un tercero; y en consecuencia asumirá los efectos legales si no llegare a cumplir con esta condición. Esta prohibición también incluye expresamente, sin limitarla, a la práctica de Screen Scraping o raspado de pantalla para obtener información, y el uso por parte de un tercero o en beneficio de un tercero. LA COMPAÑÍA no se hará responsable del mal uso que se haga de los CANALES VIRTUALES. El usuario se hará responsable de toda copia, emulación, alteración o modificación que afecte la integridad de LA COMPAÑÍA. <br></br>

                                El usuario garantizará a LA COMPAÑÍA que no usará los CANALES VIRTUALES para fines contrarios a la ley o a lo estipulado en el presente acuerdo de Términos y Condiciones de Uso. En caso de utilizarse, legitimará a LA COMPAÑÍA a desactivar e impedir que el usuario continúe ingresando a la aplicación y al contenido comercial del mismo, y a iniciar las acciones legales, si a ello hubiere lugar. <br></br>
                                
                                La categoría de “usuario” se obtiene al momento de registrarse en los CANALES VIRTUALES de LA COMPAÑÍA, creando el vínculo contractual con LA COMPAÑÍA.
                                <br></br>
                                La categoría de usuario se perderá en los siguientes eventos:
                                  <br></br>
                                    1.	Cuando el estado de la cuenta del usuario en los CANALES VIRTUALES de LA COMPAÑÍA, esté suspendida o cancelada.
                                    <br></br>
                                    2.	En el evento en que se logre demostrar que existió suplantación de identidad.
                                    <br></br>
                                    3.	En cualquier momento en que el usuario registrado realice alguna actuación considerada como violatoria de estos Términos y Condiciones de Uso, de la Política de Privacidad, o cualquier conducta contraria a la legislación colombiana, el orden público o las buenas costumbres. <br></br>

                                <br></br>
                                <b>CREACIÓN DE USUARIO PARA EL ACCESO A LOS CANALES VIRTUALES DE LA COMPAÑÍA.</b> <br/>
                                El usuario de los CANALES VIRTUALES de LA COMPAÑIA entiende y acepta que, para hacer uso de los mismos, se requiere la creación de una cuenta que lo identifique como tal. Para efectuar el registro del usuario se deberá ingresar entre otros datos, la información de la identificación, los nombres y apellidos, el correo electrónico y una contraseña de uso personal e intransferible. El usuario garantiza la autenticidad y veracidad de todos aquellos datos personales e información que entregue para registrarse, y se compromete a completar el formulario de suscripción con el resto de datos personales que se le solicitará al registrarse a cualquiera de los CANALES VIRTUALES de LA COMPAÑIA, incluyendo el aporte de imágenes para personalizar el perfil de su cuenta. <br></br>
                                <br></br>
                                <b>Uso de la contraseña.</b> <br/>
                                El usuario acepta que las contraseñas ingresadas al momento de su registro en los CANALES VIRTUALES de LA COMPAÑÍA, con la cual podrá iniciar sesión en su cuenta, son privadas e intransferibles, por lo que tendrá la obligación de custodia de las mismas, siendo el único responsable de las consecuencias derivadas del uso que otras personas o terceros hagan de ellas, por la falta del cumplimiento del deber de custodia de dichas contraseñas. <br></br>

                                Por lo anterior, el usuario se compromete a informar a LA COMPAÑÍA sobre la pérdida o robo de su contraseña, del uso no autorizado de su contraseña por parte de terceros, o cualquier circunstancia que a su juicio deba ser conocida por LA COMPAÑÍA, a más tardar dentro del día hábil siguiente a tener conocimiento de tal situación, con el fin de evitar actos fraudulentos en contra de su propia persona, de LA COMPAÑÍA, o de terceros. <br></br>

                                El usuario al hacer uso de su contraseña se obliga a abstenerse de realizar las siguientes acciones: a) acceder a documentos confidenciales o datos de salud de personas de las que no se encuentra legitimado por ley o por convención para hacerlo. b) suministrar información falsa a título personal o de su grupo familiar, así como omitir datos necesarios para la buena prestación del servicio y el registro clínico. b) dar un uso de los CANALES VIRTUALES de LA COMPAÑÍA contrario a la Ley, la moral y las buenas costumbres. c) realizar acciones tendientes a ocasionar daño o interrupción del servicio de los CANALES VIRTUALES de LA COMPAÑÍA. <br></br>

                                El usuario que viole cualquiera de las condiciones contenidas en este acuerdo de Términos y Condiciones de Uso, será responsable por los daños y perjuicios de cualquier naturaleza que pueda sufrir LA COMPAÑÍA, o cualquier tercero que resulte perjudicado por su actuación. <br></br>
                                <br></br>
                                <b>Obligaciones del Usuario</b> <br/>
                                El usuario se compromete a hacer uso de los CANALES VIRTUALES de LA COMPAÑÍA, de conformidad con la ley colombiana, a estos Términos y Condiciones de Uso, a la Política de Privacidad y a las demás instrucciones que sean puestas en su conocimiento por parte de LA COMPAÑÍA, así como de conformidad con el orden público, la moral y las buenas costumbres. <br></br>

                                El usuario garantiza la autenticidad y veracidad de todos aquellos datos personales e información que entregue para completar el formulario de suscripción o registro. Así mismo, el usuario se compromete y se responsabiliza de mantener actualizada toda la información que haya entregado, permitiendo con ello prestar un mejor servicio por parte de LA COMPAÑÍA <br></br>

                                Cuando el usuario inserte o incorpore cualquier información a los CANALES VIRTUALES de LA COMPAÑÍA, garantiza que la información es completa y veraz, que posee todos los derechos sobre la misma y que se encuentra autorizado para entregarla. <br></br>

                                El uso de los CANALES VIRTUALES de LA COMPAÑÍA únicamente se encuentra permitido para personas mayores de 18 años y para personas sin condiciones de incapacidad legal. Los menores de 18 años de edad y las personas en condiciones de incapacidad legal, podrán usar los CANALES VIRTUALES siempre y cuando cuenten con la autorización de los padres o sus representantes legales. En ese sentido y por el principio de la buena fe, se entiende que la persona que está accediendo a los CANALES VIRTUALES de LA COMPAÑÍA es mayor de edad, no tiene condición de incapacidad legal, o está autorizada, monitorizada o acompañada por sus padres o representantes legales, razón por la que no existirá responsabilidad alguna para LA COMPAÑÍA, por las actuaciones del usuario. <br></br>
                                <br></br>
                                <b>DERECHOS DE PROPIEDAD INDUSTRIAL E INTELECTUAL.</b> <br/>
                                Todas las marcas, nombres comerciales, signos distintivos, diseños industriales, modelos de utilidad, patentes, servicios, contenidos e informaciones de cualquier clase que aparecen en los CANALES VIRTUALES son propiedad de LA COMPAÑÍA, por lo que no podrán ser reproducidos, distribuidos, comunicados públicamente, transformados o modificados sin autorización expresa. <br></br>
                                
                                Por lo anterior, el usuario se abstendrá de obtener los contenidos de los CANALES VIRTUALES de LA COMPAÑÍA, empleando para ello medios o procedimientos distintos de los que, en algunos casos, se han puesto a su disposición o, en general, de los que se empleen habitualmente en internet siempre que, estos últimos, no entrañen un riesgo o daño o inutilización de los CANALES VIRTUALES de LA COMPAÑÍA y sus contenidos. <br></br>

                                En ningún caso se entenderá que el acceso y la navegación del usuario en los CANALES VIRTUALES de LA COMPAÑÍA, implica que LA COMPAÑÍA haya otorgado una autorización o haya renunciado, transmitido, cedido total o parcialmente sus derechos, ni la concesión de ningún derecho ni expectativa de derecho y en concreto, de la alteración, transformación, explotación, reproducción, distribución o comunicación pública sobre los mismos. <br></br>

                                Los titulares de los derechos de propiedad industrial podrán iniciar acciones legales comerciales, civiles, penales, o de cualquier clase, en contra del usuario de la aplicación que realice actos contrarios a las reglas de propiedad industrial contenidas en la ley, o en el presente acuerdo de Términos y Condiciones de Uso y la Política de Privacidad. <br></br>
                                <br></br>
                                <b>DERECHOS DE AUTOR.</b> <br/>
                                
                                Todo el contenido de cualquier clase que aparezca en los CANALES VIRTUALES de LA COMPAÑÍA, susceptible de ser objeto de derechos patrimoniales de autor, conforme a la Ley 23 de 1982 y demás normas que regulen esta materia, son propiedad de MEDICALL24 SAS, por lo que no podrán ser reproducidos, distribuidos, comunicados públicamente, transformados, copiados o modificados sin autorización expresa. <br></br>
                                
                                Conforme a lo anterior, los CANALES VIRTUALES serán en todo momento de titularidad de LA COMPAÑÍA. El usuario no tendrá ningún derecho de dominio ni disposición sobre los CANALES VIRTUALES de LA COMPAÑÍA, por lo que no podrá realizar actos de disposición, gravámenes, licenciamientos, ni cesiones sobre ellos. <br></br>

                                En ningún caso se entenderá que el acceso y la navegación del usuario en los CANALES VIRTUALES de LA COMPAÑÍA, implica que LA COMPAÑÍA haya otorgado una autorización o haya renunciado, transmitido, cedido total o parcialmente sus derechos, ni la concesión de ningún derecho ni expectativa de derecho y en concreto, de la alteración, transformación, explotación, reproducción, distribución, copia o comunicación pública sobre los mismos. <br></br>

                                Respecto del contenido comercial sobre el cual el usuario tiene acceso, este será exclusivamente de los clientes de LA COMPAÑIA, por lo que los Licenciatarios, no se harán responsables del contenido comercial de sus clientes. La función de LA COMPAÑÍA solo se limitará a permitir el acceso comercial mediante los CANALES VIRTUALES. <br></br>

                                El Usuario se abstendrá de obtener los contenidos de los CANALES VIRTUALES de LA COMPAÑÍA empleando para ello medios o procedimientos distintos de los que, en algunos casos, se han puesto a su disposición o, en general, de los que se empleen habitualmente en internet, siempre que, estos últimos, no entrañen un riesgo, daño o inutilización de los CANALES VIRTUALES de LA COMPAÑÍA y sus contenidos. <br></br>

                                LA COMPAÑÍA, como titulares de los derechos patrimoniales de los CANALES VIRTUALES, se reservará el derecho de iniciar las acciones legales comerciales, civiles, penales o de cualquier clase, en contra del usuario que realicen actos contrarios a las reglas de derechos de autor contenidas en la ley o en el presente acuerdo de Términos y Condiciones de Uso. <br></br>

                                <br></br>
                                <b>LIMITACIÓN DE RESPONSABILIDAD.</b> <br/>

                                El usuario utilizará los CANALES VIRTUALES bajo su exclusiva responsabilidad, teniendo en cuentas las siguientes circunstancias: <br></br>
                                <br></br>
                                •	LA COMPAÑÍA garantiza al usuario que los servidores contarán con procedimientos de seguridad necesarios para evitar la pérdida, alteración o acceso de terceros a la información personal del usuario, sin embargo, cada situación deberá analizarse en particular, puesto que el acceso ilícito a la información podrá constituir un caso de fuerza mayor o caso fortuito, en caso de que los Licenciatarios cumplan con las garantías mínimas de seguridad.
                                <br></br>
                                •	LA COMPAÑÍA no será responsable de las destinaciones o transmisiones de dinero inválidas, fraudes y atentados a la seguridad de la información que se realicen por medio de las compañías proveedoras de internet.
                                <br></br>
                                •	Bajo ninguna circunstancia, LA COMPAÑÍA, sus clientes, patrocinadores, anunciantes o proveedores de bienes y servicios se harán responsables de manera enunciativa, de daños directos, indirectos, incidentales, especiales, consecuenciales, o de cualquier otra clase, que sufra el usuario o tercero, por la utilización indebida de los CANALES VIRTUALES, de cualquier información, producto, servicios y demás gráficos relacionados que se obtengan a través de los servicios ofrecidos por LA COMPAÑÍA, ya sea con fundamento contractual, extracontractual, negligencia, responsabilidad objetiva o de cualquier régimen. <br></br>

                                <br></br>
                                <b>RESTRICCIONES Y PROHIBICIONES.</b> <br/>
                                El Usuario tendrá prohibido para sí o para terceras personas, autorizar la reproducción, copia, modificación o comercialización de los CANALES VIRTUALES de LA COMPAÑÍA y su contenido. El contenido de los CANALES VIRTUALES de LA COMPAÑÍA se extiende a textos, documentos, material audiovisual, imágenes gráficas, software, y sonidos en general, que genere la aplicación. Por lo anterior, el usuario bajo ninguna modalidad o circunstancia podrá explotar comercialmente, copiar, vender, distribuir, licenciar, ceder, o modificar el contenido de la aplicación. Tampoco podrá publicar el contenido de la aplicación en otras aplicaciones, blog´s o sitios web sin autorización previa, expresa y por escrito de LA COMPAÑÍA. <br></br>

                                También le estará prohibido al usuario la realización o promoción de actividades en la aplicación que sean contrarios a la ley, a las sanas costumbres, al orden público y a la moral. <br/> 

                                Con carácter enunciativo y no limitativo, se describirán algunas conductas prohibidas para los usuarios:
                                <br></br>
                                •	Utilizar la aplicación para publicar contenido pornográfico, explotación sexual en cualquiera de sus modalidades, actos de racismo o apología del mismo, consumos de drogas, contenido de propaganda política, estafa o cualquier actitud contraria a las leyes de la República de Colombia y tratados Internacionales de ius cogens.
                                <br></br>
                                •	Publicar contenido contrario a las reglas establecidas en la ley y en el presente acuerdo de Términos y Condiciones de Uso, respecto a los derechos de autor y a la propiedad industrial, así como cualquier acto de apología a la piratería o a la realización de copias ilegales protegidas por las leyes vigentes.
                                <br></br>
                                •	Utilizar o fomentar el uso de los CANALES VIRTUALES de LA COMPAÑÍA, destinados a violar la intimidad de las personas, infringir la ley estatutaria de habeas data en cualquiera de sus modalidades o enviar correos masivos o spam.
                                <br></br>
                                •	Realizar o promover la modificación del contenido de la aplicación, introducir de manera directa o indirecta virus, gusanos, troyanos y, en general, cualquier actitud tendiente a hackear el sistema y los ordenadores  de los CANALES VIRTUALES de LA COMPAÑÍA. En consecuencia, si LA COMPAÑÍA detecta cualquier contenido que no guarde relación con el material de LA COMPAÑÍA, podrá retirarlo de los CANALES VIRTUALES en cualquier momento y sin previo aviso al usuario. <br></br>
                                <br></br>
                                <b>EXCLUSIÓN DE RESPONSABILIDADES Y GARANTÍAS.</b> <br/>
                                <p>Disponibilidad y continuidad</p>
                                Por las características y necesidades tecnológicas de los CANALES VIRTUALES de LA COMPAÑÍA es posible que se presenten problemas en la disponibilidad o continuidad en el acceso y uso de los servicios de la misma, así como la ocurrencia de fallas técnicas en los servidores de acceso a la red. Por lo anterior, el usuario entiende y acepta que frente a la ocurrencia de fallas en los CANALES VIRTUALES debido a la indisponibilidad del servicio por causas de fuerza mayor, errores en las redes telemáticas de transferencia de datos o por causas ajenas a la voluntad de LA COMPAÑÍA, ésta no será responsable por los daños y perjuicios de cualquier naturaleza que puedan generarse al usuario y en esa medida, el usuario exime a LA COMPAÑÍA de cualquier responsabilidad en relación con las consecuencias de la ocurrencia de este tipo de eventos. <br></br>

                                El acceso a la información y servicios que prestan los CANALES VIRTUALES de LA COMPAÑÍA, tienen en principio una duración indefinida, pero la misma dependerá de la decisión de LA COMPAÑÍA en continuar con el servicio prestado a través de los CANALES VIRTUALES. No obstante lo anterior, el acceso a los CANALES VIRTUALES podrá suspenderse o terminarse en cualquier momento en caso de que se compruebe cualquier violación a los esquemas de seguridad informática, o en caso de que se verifique suplantación en la identidad del usuario. Por lo anterior, LA COMPAÑÍA no será responsable por el retiro de los CANALES VIRTUALES o suspensión de la prestación de los servicios a través de la misma. <br></br>

                                En caso de que se pierda la calidad de usuario por cualquier causa, el usuario no podrá hacer uso de los CANALES VIRTUALES para consultar. <br></br>
                                <br></br>
                                <b>Exclusión de responsabilidad.</b> <br/>
                               
                                LA COMPAÑÍA no será responsable de los daños, pérdida de negocio, ingresos o beneficios, daño emergente, lucro cesante o de oportunidades de negocio, de ahorro de gastos y de desaparición o deterioro de datos, así como tampoco será en ningún caso responsable de: (a) Los costos, multas, sanciones, indemnizaciones, cargos, daños u honorarios que se deriven como consecuencia del incumplimiento por parte del usuario de sus obligaciones; (b) La violación del usuario de cualquier norma que pudiera resultar aplicable a causas o en relación con la utilización de los CANALES VIRTUALES, por lo tanto, el usuario es y será el único responsable de: (i) el uso que realice de los CANALES VIRTUALES; (ii) el cumplimiento íntegro de cualquier norma que pudiera resultar aplicable a causa o en relación con la utilización de los CANALES VIRTUALES, incluyendo, a título enunciativo pero no limitativo, las normas de uso de los CANALES VIRTUALES aquí contenidas, las disposiciones en materia de protección de datos, confidencialidad, secreto de las comunicaciones y derecho a la intimidad. <br></br>
                                <br></br>
                                <b>Virus y códigos maliciosos</b> <br/>
                                
                                El usuario exime a LA COMPAÑÍAS de cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan ser causados por, o que puedan deberse a la presencia de virus u otros códigos maliciosos en los contenidos que puedan producir cualquier tipo de daños en el sistema informático, documentos electrónicos o ficheros de los usuarios, incluyendo a título meramente enunciativo y sin carácter limitativo, "virus informáticos", gusanos, "caballos de troya", errores en la funcionalidad y operatividad ("bugs"), "bombas de tiempo", "cancelbots", "Spyware", "phishing", "dialers", "hoaxes", "jokes" etc. <br></br>
                                <br></br>
                                <b>Uso ilícito.</b> <br/> 

                                LA COMPAÑÍA cuenta con normas y procedimientos, restricciones de acceso y uso a la información que garantizan que solamente personal altamente calificado e idóneo maneje las bases de datos o de archivos sensibles cumpliendo con los protocolos para el manejo de esta información. <br></br>
                                
                                Por lo anterior LA COMPAÑÍA garantiza que los CANALES VIRTUALES se manejarán con adecuados estándares de seguridad, confidencialidad de la información y confiabilidad, de manera que cada usuario pueda mantener la reserva de su información. Sin embargo, no se hace responsable del incumplimiento de cualquier norma aplicable en que puedan incurrir los usuarios en su acceso a los CANALES VIRTUALES y/o utilización de las informaciones contenidas en la misma. Tampoco se hace responsable del uso ilegítimo que terceras personas puedan hacer de la información allí contenida. <br></br>
                                <br></br>
                                <b>CLÁUSULA DE INDEMNIDAD A FAVOR DE LA COMPAÑÍA.</b> <br/>

                                El usuario se obliga a mantener indemne a LA COMPAÑÍA de cualquier reclamación, perdida, daño, costo y pago de honorarios e indemnizaciones que se ocasione en virtud o como consecuencia directa o indirecta del uso de la aplicación, sobre el contenido al cual se tiene acceso y a la violación de leyes o derechos de terceros por parte del usuario. <br></br>
                                <br></br>
                                <b>LEGISLACIÓN Y JURISDICCIÓN.</b> <br/>
                                
                                Los presentes Términos y Condiciones de Uso se regirán por la Legislación colombiana, y la jurisdicción competente para conocer de cualquier demanda que el uso de los CANALES VIRTUALES de LA COMPAÑÍA suscite, será la de los Juzgados y Tribunales de la República de Colombia. <br></br>
                                <br></br>
                                <b>NULIDAD E INEFICACIA DE LOS NUMERALES O CLÁUSULAS.</b> <br/>
                                
                                Si cualquier numeral o cláusula incluida en estos Términos y Condiciones de Uso fuese declarado, total o parcialmente, nulo o ineficaz, tal nulidad o ineficacia afectará tan sólo a dicha disposición o a la parte de la misma que resulte nula o ineficaz, subsistiendo los Términos y Condiciones de Uso en todo lo demás. <br></br>
                                <br></br>
                                <b>NATURALEZA DE LA RELACIÓN COMERCIAL.</b> <br/>
                                
                                El usuario reconoce y acepta que por el solo hecho de utilizar los CANALES VIRTUALES de LA COMPAÑÍA y aceptar los Términos y Condiciones de Uso, no tendrá la calidad de socio, mandatario, agente, empleado o representante de LA COMPAÑÍA. Bajo ninguna circunstancia, el usuario podrá realizar actos a nombre de los Licenciatarios en representación directa ni indirecta. <br></br>
                                <br></br>
                                <b>FUERZA MAYOR O CASO FORTUITO.</b> <br/>
                                
                                Salvo las obligaciones dinerarias, entre ellas, las de pago, ninguna parte será responsable por demoras o responsable por no cumplimiento de las obligaciones pactadas por caso fortuito o fuerza mayor, así como circunstancias imprevisibles o irresistibles, de manera enunciativa y o limitativa, actos de guerra o conflicto civil, desastres naturales, paros, revoluciones y demás que no cuenten con un margen de previsión por las partes. <br></br>
                                <br></br>
                                
                                <b>CLÁUSULA DE INTEGRACIÓN DE CONTRACTUAL.</b> <br/>
                                
                                Las partes acuerdan que los presentes Términos y Condiciones de Uso serán el acuerdo único y completo, por lo que se podrá tener como fuente de interpretación de la relación comercial y, en consecuencia, sustituye cualquier acuerdo previo, escrito o verbal que hayan estipulado las partes. <br></br>
                                <br></br>
                                <b>QUEJAS, SOLICITUDES O RECLAMOS</b> <br/>

                                En caso de que el usuario pretenda presentar una petición, queja, reclamo o solicitud, podrá enviarla por medio del siguiente link: https://medicall24.com.co/pqrs/. <br></br>
                                <br></br>
                                <b>OBLIGACIONES DEL USUARIO.</b> <br/>
                                •	Guardar confidencialidad y buen uso de su cuenta y clave privada e intransferible de acceso a los CANALES VIRTUALES de LA COMPAÑÍA.
                                <br></br>
                                •	Ser el responsable de todas las transacciones realizadas a través de su cuenta y clave privada e intransferible de acceso a los CANALES VIRTUALES de LA COMPAÑÍA.
                                <br></br>
                                •	Notificar por escrito debidamente suscrito a LA COMPAÑÍA cualquiera de las siguientes situaciones:
                                <br></br>
                                  -	Pérdida o hurto de su cuenta o clave privada e intransferible de acceso a los CANALES VIRTUALES.
                                  <br></br>
                                  -	Uso no autorizado de su cuenta o clave personal e intransferible de acceso a los CANALES VIRTUALES.
                                  <br></br>
                                  -	Fallas, errores o hechos inusuales al recibir algún mensaje en relación con una orden ejecutada por el usuario a través del sistema electrónico, o que haya sido recibida y/o ejecutada a través del mismo.
                                  <br></br>
                                  -	Anulación de órdenes no emitidas por el usuario, o de impresiones o desacuerdos en la trasmisión de la información
                                <br></br>
                                •	Aceptar que la sesión de transacción no se cerrará hasta que el usuario lo decida haciendo clic en el botón cerrar sesión.
                                <br></br>
                                •	Asumir la responsabilidad por las claves de acceso y las cuentas de usuario que llegase a delegar.
                                <br></br>
                                •	Respetar la propiedad intelectual y derechos de autor sobre cualquier signo distintivo, información, material o contenido de los CANALES VIRTUALES de LA COMPAÑÍA.
                                <br></br>
                                •	Aceptar los Términos y Condiciones de Uso que modifiquen o deroguen las condiciones de acceso y uso de los CANALES VIRTUALES de LA COMPAÑÍA.
                                <br></br>
                                •	Será responsable del uso correcto de los CANALES VIRTUALES de LA COMPAÑÍA, así como de la veracidad de los datos que provea en el momento del registro de usuario.
                                <br></br> <br></br>
                                <b>EXIMENTES DE RESPONSABILIDAD.</b> <br/>
                                
                                •	Publicidad de bienes o servicios no prestados directamente por LA COMPAÑÍA.
                                <br></br>
                                •	Intermitencia o suspensión del funcionamiento de los CANALES VIRTUALES de LA COMPAÑÍA.
                                <br></br>
                                •	Suspensión del usuario por causas no imputables a LA COMPAÑÍA.
                                <br></br>
                                •	Cambio sin previo aviso en el contenido de los CANALES VIRTUALES de LA COMPAÑÍA.
                                <br></br>
                                •	Enlaces o conexiones a otras páginas web que no sean de propiedad de LA COMPAÑÍA.
                                <br></br>
                                •	Dar por terminado este servicio e impedir el acceso a los CANALES VIRTUALES, cuando el usuario haya perdido dicha calidad.
                                <br></br>  <br></br>
                                <b>POLÍTICA DE PRIVACIDAD.</b> <br/>
                                Última actualización: 5 de diciembre de 2023 <br></br> <br></br>
                                
                                Esta Política de Privacidad aplica para los productos producidos, suministrados y controlados por MEDICALL24 SAS (en adelante MEDICALL24), los cuales incluyen los siguientes CANALES VIRTUALES: i) Plataforma web para prestadores de salud denominada “Panel Web”; ii) Aplicación para dispositivos móviles denominada "App MEDICALL24", y iii) Página web con dirección www.medicall24.com.co. <br></br>
                                En esta Política de Privacidad se explica cómo recopilamos, usamos, compartimos y tratamos la información personal de los usuarios u organizaciones que usan nuestros CANALES VIRTUALES, por favor léala cuidadosamente. MEDICALL24 SAS se compromete a proteger y respetar su privacidad, sin embargo, si usted no está de acuerdo con esta Política de Privacidad, no debe usar nuestros CANALES VIRTUALES. <br></br>
                                <br></br>
                                <b>¿Qué información recopilamos?</b>  <br></br>
                                Información que usted nos proporciona <br></br>

                                La información de registro y perfil. Cuando un usuario se registra en cualquiera de los CANALES VIRTUALES de MEDICALL24, nos proporciona información que define el rol del usuario, el cual puede ser cualquiera de los siguiente roles: 1). Rol paciente, 2). Rol médico, y 3). Rol prestador de salud. <br></br>

                                Cuando el usuario se registra con el rol paciente, nos proporciona la información como el tipo de identificación, el número de identificación, sus nombres y apellidos, la dirección de correo electrónico y la contraseña de la cuenta; este usuario podrá proporcionar también los datos de afiliación en salud y el régimen de afiliación si así lo desea.  <br></br>

                                Cuando el usuario se registra con el rol médico, nos proporciona la información como el tipo de identificación, el número de identificación, sus nombres y apellidos, la especialidad, la dirección de correo electrónico y la contraseña de la cuenta.  <br></br>

                                A los usuarios registrados con roles de pacientes y médicos, les recopilamos la información que el usuario suministra al ingresar a su cuenta y completar su perfil, como lo es: el género (si es masculino o femenino), la fecha de nacimiento, el estado civil, la ocupación, la dirección, el teléfono, los nombres y apellidos de la persona responsable del usuario, el parentesco de la persona responsable del usuario, el teléfono de la persona responsable del usuario, y finalmente una imagen de perfil. <br></br>

                                El registro de un usuario como un prestador de salud, aplica para personas naturales o jurídicas debidamente habilitadas por los órganos de vigilancia y control para prestar el servicio de salud en Colombia. En este registro este usuario nos proporciona información como el tipo de identificación, el número de identificación, el dígito de verificación (del Número de Identificación Tributaria), el código del prestador, los nombres y apellidos en caso de ser personas naturales, o la razón social en caso de ser personas jurídicas; la clase de prestador (que pueden ser Instituciones - IPS, Profesionales Independientes o Transporte Especial de Pacientes), la dirección de correo electrónico,  la naturaleza jurídica, el nivel de atención, el tipo de empresa (si es pública, privada o mixta), la dirección del domicilio, el teléfono, la página web, los datos del representante legal, la contraseña de la cuenta, una imagen de portada de su perfil y un logo. <br></br>

                                Contenido del usuario. Recopilamos la información de contenido que genera el usuario a través de los CANALES VIRTUALES, incluidas imágenes, archivos en pdf, audios y videos. Así mismo, recopilamos información de retroalimentación, reseñas y transmisiones en vivo que el usuario realiza, y los metadatos asociados, como, por ejemplo, ¿cuándo?, ¿dónde?, y ¿por quién? se creó el contenido, y los usuarios involucrados. <br></br>

                                Información en salud. Recopilamos información relacionada con la salud de los usuarios que utilizan los CANALES VIRTUALES de MEDICALL24. Esta información puede incluir la información médica, como el historial médico del paciente, las condiciones de salud actuales, los antecedentes farmacológicos, los antecedentes alérgicos, los antecedentes quirúrgicos, los antecedentes familiares, sus hábitos, y cualquier otra información relevante para que un profesional o especialista de la salud en Colombia pueda instaurar el tratamiento médico que el paciente requiera. <br></br>

                                Mensajes. Recopilamos la información que el usuario proporciona cuando redacta, envía o recibe mensajes a través de las funcionalidades de mensajería de los CANALES VIRTUALES. Esa información incluye el contenido del mensaje y la información sobre el mensaje, como por ejemplo, cuándo se envió, recibió o leyó, y los participantes del mensaje. Tenga en cuenta que los mensajes que elija enviar a otros usuarios desde los CANALES VIRTUALES, podrán ser consultados por dichos usuarios y que no somos responsables de la manera en que esos usuarios usen o compartan los mensajes. <br></br>

                                Información de compra. Cuando el usuario realiza una compra o pago a través de los CANALES VIRTUALES, recopilamos información sobre la compra o transacción de pago, como por ejemplo, información de la tarjeta de pago, facturación, entrega e información de contacto, y el concepto de la compra. <br></br>

                                Solicitudes y PQRS. Recopilamos la información en las comunicaciones que nos envían los usuarios, incluso cuando se comunican con nosotros para obtener asistencia o realizar una PQRS. <br></br>
                                <br></br>
                                <b>Información recopilada automáticamente.</b> <br/>
                                
                                Información de uso. Recopilamos información sobre cómo interactúa el usuario con los CANALES VIRTUALES, lo que incluye el contenido que le mostramos, el contenido que le gusta, el contenido que no le gusta, el contenido que le interesa y los problemas detectados. <br></br>

                                MEDICALL24 almacenará automáticamente información general, así como la cantidad y periodicidad de consulta del usuario en nuestros CANALES VIRTUALES. La mencionada información personal sólo se utilizará para obtener un correcto funcionamiento de la aplicación, junto con los fines estadísticos y comerciales. <br></br>

                                Datos de registro. Recopilamos información sobre las computadoras, teléfonos y otros dispositivos utilizados al interactuar con los CANALES VIRTUALES, que puede incluir información sobre los parlantes, el micrófono, la cámara, la versión del sistema operativo, la identificación del disco duro, el nombre de la PC o dispositivo, el modelo, la dirección del protocolo de internet (IP), el tipo de red, la configuración de la aplicación al utilizar nuestro servicio, la fecha y hora de uso del servicio, y cualquier otro dato que sea de relevancia para MEDICALL24. Cuando el usuario inicie sesión desde varios dispositivos, podremos usar la información de su perfil para identificar su actividad en los diferentes dispositivos que use. <br></br>

                                Información de imagen y texto. Recopilamos información sobre imágenes y mensajería de texto que forman parte del contenido y las transmisiones de datos durante la interacción de los usuarios. Podemos recopilar esta información para la moderación del contenido, para clasificación demográfica y para recomendaciones de contenido; además, para mejorar la interacción de los usuarios que utilizan los CANAES VIRTUALES. <br></br>

                                Contenido y contexto de salas de video y otras funciones: Recopilamos la información y el contenido de las salas de reuniones virtuales (videollamadas), generadas a través del uso de los CANAES VIRTUALES de MEDICALL24, que pueden incluir imágenes, grabaciones de audio y video, y la transcripción y edición del texto registrado por el usuarios, antes, durante y después de la realización de la videollamada. El contenido del usuario puede contener su voz e imagen, según los permisos concedidos por el usuario. <br></br>

                                Información de uso de los CANALES VIRTUALES: recopilamos información sobre cómo los usuarios y sus dispositivos interactúan con las funcionalidades de los CANALES VIRTUALES; cuándo los participantes entran y salen de una sala; si los usuarios enviaron mensajes y con quién lo hicieron; si quedó registro de transcripciones y ediciones de texto; qué usuarios agregan contenido; qué usuarios reaccionan al contenido agregado por otro usuario, y otra información y métricas de uso. Esto también incluye información sobre cuándo y cómo la persona se registró o no, en un producto o servicio de MEDICALL24, y las visitas e interacciones que tuvo con los CANALES VIRTUALES. <br></br>

                                Los datos personales, archivos de imágenes, documentos, audio y/o videos que se obtengan en virtud del uso de los CANALES VIRTUALES, tendrán fines de suministro de información seleccionada para la interacción de los usuarios, para mejorar las funcionalidades de los CANALES VIRTUALES y será de uso exclusivo para MEDICALL24; por lo tanto, no se cederán ni se suministrarán a ningún tercero. El usuario, al aceptar esta Política de Privacidad, acepta el tratamiento de sus datos personales conforme a los fines mencionados y según lo dispuesto en la Ley 1581 de 2012 y el Decreto 1377 de 2013 en el territorio de Colombia. <br></br>
                                <br></br>
                                <b>Recolección de información proveniente de terceros, servidores publicitarios y patrocinadores</b> <br></br>

                                A pesar de que el contenido publicado en los CANALES VIRTUALES de MEDICALL24 pueda direccionar a los usuarios a páginas web de servidores publicitarios y empresas que ofrezcan bienes y servicios, esta Política de Privacidad solo tendrá por objeto los CANALES VIRTUALES de MEDICALL24. En consecuencia, MEDICALL24 SAS no se hará responsable, ni asumirá ninguna responsabilidad por el contenido, la información publicitaria, las promociones de bienes y servicios, la información y demás contenido que los usuarios publiquen en los CANALES VIRTUALES de MEDICALL24, ya que, en ningún caso, tal información comercial guarda relación con el objeto social de MEDICALL24 SAS. <br></br>

                                <b>Uso de cookies, clear, Gifs, web beacons o cualquier tecnología similar</b> <br></br>

                                MEDICALL24 SAS, así como los anunciantes y patrocinadores podrán usar cookies, clear, Gifs, web beacons o cualquier tecnología similar cuando el usuario utilice los CANALES VIRTUALES. El ingreso a los CANALES VIRTUALES se tomará como autorización expresa sobre el uso de cookies. La finalidad exclusiva del uso de cookies es recordarle al usuario sus preferencias comerciales, país, características del navegador, y la información del uso de los CANALES VIRTUALES, entre otros. <br></br>

                                Nuestros servidores podrán utilizar cookies, web beacons y otras tecnologías similares para apoyar a la presentación de dichos anuncios, y para medir la eficacia de los anuncios de publicidad.  <br></br>

                                El usuario, en caso de ingresar a páginas web o a enlaces de internet de terceros, a través de las publicaciones realizadas en los CANALES VIRTUALES de MEDICALL24, deberá leer y aceptar por su cuenta las políticas de privacidad y los términos y condiciones de las mencionadas páginas y aplicaciones. Cada enlace y página web que direccione a páginas de clientes o terceros, se regirá por sus propias políticas de privacidad y de términos y condiciones, de las cuales MEDICALL24 SAS no se hace responsable. <br></br>

                                Las mencionadas páginas web o servidores de los anunciantes podrán emplear cookies, clear, Gifs, web beacons o cualquier tecnología similar para soportar las presentaciones de dichos anuncios. La utilización de esas nuevas tecnologías por terceras partes se rige por sus propias políticas de privacidad y no son objeto de regulación por esta Política de Privacidad. <br></br>
                                <br></br>
                                <b>¿Cómo usamos su información?</b> <br></br>

                                Los empleados de MEDICALL24 SAS no acceden ni utilizan el contenido del usuario sin la autorización del propietario de la cuenta, o según lo requieran razones legales o de seguridad. MEDICALL24 SAS no utiliza su información de audio, video, texto, imágenes y archivos adjuntos u otro contenido similar a comunicaciones, para entrenar modelos de inteligencia artificial propios o de terceros. <br></br>

                                Utilizamos su información para mejorar, para brindar asistencia y administrar los CANALES VIRTUALES de MEDICALL24, para permitirle utilizar de mejor manera las funcionalidades y para cumplir y hacer cumplir nuestros Términos de servicio. También podemos usar su información para personalizar el contenido que ve en los CANALES VIRTUALES, para la ampliación de los servicios y para personalizar su experiencia según las preferencias y gustos del usuario. <br></br>

                                Además, utilizamos la información que recopilamos para realizar las siguientes actividades: <br></br>

                                •	Para cumplir con las funcionalidades de los CANALES VIRTUALES, para operaciones internas incluidas la resolución de problemas, análisis de datos, pruebas, investigación, fines estadísticos y comerciales.
                                <br></br>
                                •	Para enviar materiales promocionales, incluso por mensajería instantánea o correo electrónico.
                                <br></br>
                                •	Para mejorar y desarrollar nuestros CANALES VIRTUALES y llevar a cabo el desarrollo de otros productos.
                                <br></br>
                                •	Para apoyar las funciones de los CANALES VIRTUALES, lo cual incluye permitirle a usted y a otras personas contactarse entre sí (por ejemplo, a través de nuestra funcionalidad de videollamadas), y para que usted y otras personas compartan e interactúen con el contenido que publican a través de los CANALES VIRTUALES.
                                <br></br>
                                •	Para inferir más información sobre usted, como su rango de edad, género e intereses.
                                <br></br>
                                •	Para ayudarnos a detectar y combatir abusos, actividades perjudiciales, fraudes, correos no deseados y actividades ilegales en los CANALES VIRTUALES.
                                <br></br>
                                •	Para garantizar que el contenido se presente de la manera más eficiente para usted y su dispositivo.
                                <br></br>
                                •	Para promover la seguridad de los CANALES VIRTUALES, lo que incluye escanear, analizar y revisar el contenido del usuario, los mensajes y los metadatos asociados para detectar violaciones de nuestros Términos de servicio, normas comunitarias, así como otras condiciones y políticas.
                                <br></br>
                                •	Para verificar su identidad o edad.
                                <br></br>
                                •	Para comunicarnos con usted, lo cual incluye notificarle cambios en nuestros servicios.
                                <br></br>
                                •	De acuerdo con los permisos que usted haya otorgado, para proporcionarle servicios basados en la ubicación, como publicidad y otro contenido personalizado.
                                <br></br>
                                •	Para capacitar y mejorar nuestra tecnología, como nuestros modelos y algoritmos de aprendizaje automático.
                                <br></br>
                                •	Para cumplir con la ley aplicable o responder a un proceso legal válido, incluso de agencias policiales o gubernamentales, para investigar o participar en descubrimientos civiles, litigios u otros procedimientos legales contradictorios, protegerlo a usted, a nosotros y a otros contra fraudes y maliciosos, actividades engañosas, abusivas o ilegales, y para hacer cumplir o investigar posibles violaciones de nuestros Términos de servicio o políticas.
                                <br></br> <br></br>
                                <b>¿Cómo compartimos su información?</b> <br></br>

                                Proporcionamos datos personales a terceros solo con su consentimiento o en una de las siguientes circunstancias: <br></br>

                                Información del usuario: cuando utilice los servicios de los CANALES VIRTUALES de MEDICALL24, la información de su perfil, sus datos personales, la información relacionada con su salud, y el contenido de su cuenta, serán visibles para otros usuarios, siempre y cuando usted autorice que otros usuarios tengan acceso a su información. <br></br>

                                Proveedores de servicios: Proporcionamos información y contenido a proveedores de servicios que brindan asistencia a nuestra empresa, como por ejemplo proveedores de servicios en la nube y proveedores de servicios de moderación de contenido, y proveedores de servicios que nos ayuden a comercializar los CANALES VIRTUALES. <br></br>

                                Procesadores de pagos y proveedores de cumplimiento de transacciones: Si elige realizar transacciones relacionadas con pagos, compartiremos datos con el procesador de pagos correspondiente para facilitar esta transacción. <br></br>

                                Proveedores de análisis: Utilizamos proveedores de análisis que nos ayudan a optimizar y mejorar los CANALES VIRTUALES. <br></br>

                                Por razones legales: podemos compartir datos personales según sea necesario para:  <br></br>

                                •	Cumplir con la ley aplicable o responder, investigar o participar en procesos y procedimientos legales válidos, incluso de agencias policiales o gubernamentales;
                                <br></br>
                                •	Hacer cumplir o investigar posibles violaciones de sus Términos de servicio o políticas;
                                <br></br>
                                •	Detectar, prevenir o investigar posibles fraudes, abusos o problemas de seguridad, incluidas las amenazas al público;
                                <br></br>
                                •	Proteger nuestros derechos y propiedades y los de nuestros clientes;
                                <br></br>
                                •	Resolver disputas y hacer cumplir acuerdos.

                                Cambio de control: podemos compartir datos personales con adquirentes reales o potenciales, sus representantes y otros participantes relevantes en, o durante las negociaciones de, cualquier venta, fusión, adquisición, reestructuración o cambio de control que involucre todo o una parte del negocio de MEDICALL24 SAS o sus activos, incluso en relación con procedimientos de quiebra o similares. <br></br>
                                <br></br>
                                <b>¿Dónde almacenamos su información?</b> <br></br>

                                Su información puede almacenarse en servidores ubicados dentro o fuera del país donde vive, como, por ejemplo, Canadá o los Estados Unidos. <br></br>
                                <br></br>
                                <b>Sus derechos y opciones </b> <br></br>

                                Usted tiene derechos y puede opinar en lo que respecta a su información. Es posible que las leyes aplicables le otorguen ciertos derechos, que pueden incluir el derecho a acceder, eliminar, actualizar o rectificar sus datos, a ser informado del tratamiento de sus datos, a presentar denuncias ante las autoridades y posiblemente otros derechos.  <br></br>

                                Puede presentar una solicitud para ejercer sus derechos en virtud de las leyes aplicables en la siguiente dirección: https://medicall24.com.co/pqrs/. Puede objetar cualquier decisión que hayamos tomado sobre su solicitud siguiendo las instrucciones en la comunicación que reciba de nosotros en la que se le notifique nuestra decisión.  <br></br>

                                Tenga en cuenta que usted puede editar la información de su perfil iniciando sesión en la cuenta que tenga activa en nuestros CANALES VIRTUALES. También puede limitar y controlar quien ve su contenido, entre otras cosas. Si decide hacerlo, puede eliminar toda su cuenta en la sección de Configuración y privacidad. <br></br>
                                <br></br>
                                <b>Seguridad de su información</b> <br></br>

                                Tomamos las medidas necesarias para garantizar que se trate su información de manera segura y de acuerdo con esta política. <br></br>

                                Aunque la transmisión de información a través de Internet no es completamente segura, contamos con medidas técnicas y administrativas apropiadas para garantizar un nivel de seguridad adecuado al riesgo de probabilidad e impacto para sus derechos y libertades, así como los de otros usuarios. Por lo anterior, implementamos medidas razonables para proteger y garantizar la seguridad de sus datos personales, por ejemplo, mediante el uso de encriptación en tránsito o de cifrado en tránsito, que protege los datos en caso de que se intercepten las comunicaciones mientras se transfieren datos entre un sitio y el proveedor de servicios en la nube o entre dos servicios. <br></br>

                                Mantendremos estas medidas técnicas y administrativas y las actualizaremos para mejorar la seguridad de nuestros sistemas cuando corresponda. <br></br>
                                <br></br>
                                <b>Información relacionada con niños y adolescentes</b> <br></br>

                                Menores de 18 años: Los menores de 12 años de edad podrán registrarse como usuarios de los CANALES VIRTUALES de MEDICALL24, siempre y cuando cuenten con la autorización de los padres o representantes legales. Los menores a su cargo, deberán manifestar su consentimiento en relación con las condiciones aquí descritas y aportar la información personal requerida para el registro del usuario; sin embargo, los padres o representantes legales del menor, serán los responsables del registro del menor en los CANALES VIRTUALES de MEDICALL24. <br></br>

                                Para el caso de los padres o representantes legales de los menores de edad que sean mayores adultos (esto es mayores de 12 años), únicamente podrán abrir las cuentas de usuarios de estos menores adultos cuando cuenten con autorización para ello. Se entenderá que la apertura de cuentas de usuarios menores adultos, por parte de sus padres o representantes legales, cuenta con la autorización de los menores adultos, razón por la que MEDICALL24 SAS no se hará responsable por la apertura de cuentas de usuario que carezcan de tal autorización. Los padres o representantes legales deben informar a los menores sobre la finalidad del tratamiento de sus datos personales por parte de MEDICALL24 SAS. <br></br>

                                <b>Personas incapaces o adultos mayores: </b> En los casos de representación por condiciones de incapacidad legal, o por relaciones de parentesco; por ejemplo en caso de que un hijo mayor de edad quiera manejar la cuenta de sus padres adultos mayores, o un padre quiera manejar la cuenta de su hijo adolescente, los representantes legales o parientes tendrán la posibilidad de abrir cuenta de usuarios en los CANALES VIRTUALES de MEDICALL24 para las personas a su cargo; sin embargo, deberá contar con autorización expresa de las partes relacionadas o del tutor o curador del incapaz, en donde conste que se actúa como la persona a cargo de la información del incapaz o adulto mayor y en consecuencia, que podrá tener acceso directo a la cuenta de este. <br></br>
                                <br></br>
                                <b>¿Cuánto tiempo conservamos su información?</b> <br></br>

                                Conservamos datos personales durante el tiempo necesario para realizar los usos descritos en esta Política de Privacidad, a menos que la ley aplicable exija un período de retención distinto. <br></br>

                                Conservamos datos personales si tenemos la obligación legal de hacerlo, por ejemplo, ciertas leyes nos exigen mantener registros de sus transacciones durante un cierto período de tiempo antes de que podamos eliminarlos. <br></br>

                                Se conservarán datos personales si la retención es aconsejable a la luz de nuestra posición legal, como en lo que respecta a la aplicación de nuestros acuerdos, la resolución de disputas y los estatutos de limitaciones, litigios o investigaciones regulatorias aplicables. <br></br>
                                <br></br>
                                <b>Actualización de la Política de Privacidad</b> <br></br>

                                Actualizaremos esta Política de Privacidad cuando lo consideremos necesario. Al actualizar la Política de Privacidad, le notificaremos actualizando la fecha de la “Última actualización” que aparece en la parte superior de esta política y publicando la nueva o proporcionando cualquier otro aviso que exija la ley aplicable. Si usted continúa usando a los CANALES VIRTUALES de MEDICALL24, después de la fecha de actualización de la política, se considerará que acepta la política actualizada.  <br></br>
                                <br></br>
                                <b>Contáctenos</b> <br></br>

                                Para ejercer sus derechos, o si tiene alguna pregunta o comentario relacionado con la privacidad de sus datos, o con respecto a esta Política de Privacidad, envíenos una petición haciendo clic en el siguiente link: https://medicall24.com.co/pqrs/.
 
                                

                              </div>
                                                  
                          
                          </div>

                        

                          <div className="row justify-content-md-center"> <br/>
      
                              <div  className="col-md-10">

                                    <div className="form-group form-check">
                                      <input type="radio" className="form-check-input" id="exampleCheck1" onClick={this.GetTerminosstado} value="true" checked={this.state.checkedT}   />
                                      <label id="check_terminos" className="form-check-label" htmlFor="exampleCheck1" >  Acepto los Términos y condiciones de uso y la Política de privacidad </label>
                                    </div>

                              </div>
                                                  
                          
                          </div>

                            
                              
                    </div> 
    
                    <div className="modal-footer justify-content-between">                                   
                    
                          <button type="button" className="btn btn-danger" data-dismiss="modal"  onClick={this.GetTerminos_desmarcados}>No acepto</button>            
              
                          <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={this.state.active_button_terminos} > Continuar</button>  
                             
                    </div>

                  </div>
                  
                </div>
                
              </div>    
          

        </div>
         
      )

  }  
  
  
}

function Firma(){

  let sigPad = useRef({})

  return(
    <div>
      
         <button type="button" className="btn btn-primary" onClick={()=> $('#modal-firma').modal('show')}> Firmar contrato</button>
          
    
          <div className="modal fade" id="modal-firma">
            
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title"><b>Firmar contrato</b></h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
  
                  <div className="modal-body">

                    <SignatureCanvas canvasProps={{ 
                      className: 'firmacanvas' }}
                      ref={sigPad}
                    />                       
                      
                  </div> 
  
                  <div className="modal-footer justify-content-between">      
                                      
                        <button type="button" className="btn btn-primary" > Guardar</button>  

                        <button type="button" className="btn btn-success" > Limpiar</button>         
                  
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>            
            
                           
                  </div>
                </div>
                
              </div>
                    
          </div>

    </div>

    
  )

}






export default Crear_Cuenta;