import React, {Component, useRef} from 'react';
import SignatureCanvas from 'react-signature-canvas'
import * as ReactBootStrap from 'react-bootstrap';
import Departamentos from '../API/Departamentos';
import '../tools/css/Detalle_compra.css';
import axios from 'axios';
import img from '../tools/images/logoblanco.png';
import icono_aprobado from '../tools/images/aprobado icono.svg'
import icono_rechazada from '../tools/images/rechazado icono.svg'
import Moment from 'react-moment';
import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';
import { FaTextHeight } from 'react-icons/fa';

const ApiURL="https://medicallapi.azurewebsites.net/";


var proceso=null;
var estado=null
var mensaje='';

var queryString = window.location.search;
var urlParams = new URLSearchParams(queryString);
var id_orden = urlParams.get('id');
var valor = 0;
var detalle_compra='';
var banco='';
var id_comprador='';
var nom_comprador='';

const body = {
    background: '#ffffff',
    height: 'auto'
  };
  



class Detalle_compra extends Component  { 

  constructor(props)
    {
     super()
      this.state={
        show:false,
        Detalle_orden:[],
        Banco:[],
        Comprador:[],
        loading: false,
        loading_button: true,
        active_button: true,
        loading_button_activacuenta: true,
        button_activacuenta: false,
          form:{   
          tipo_id:'',
          dv:'',  
          id_inst:'',
          nom_inst:'',
          email_inst:'',
          clase_pres:'',
          cod_pres:'',
          tipo_naturaleza:'',
          nivel:'',
          ese:'no',
          cod_depa:'',
          cod_muni:'',
          dir_inst:'',
          tele_inst:'',
          tele_inst2:'',
          pag_web:'',
          tipo_idrl:'',
          id_rl:'',
          nom_rl:'',
          pass:'',
          pass_confirm:'',
          selectedFile:null,
          selectedFilep:null,
          cod_activa:'',
          municipio:'',
          btnpaso1: <button id="btn_save" className="btn btn-save" onClick={()=> this.Validar_Paso1()} > Continuar
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" class="bi bi-arrow-right-square" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                      <path fill-rule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                      </svg>
                    </button>
          }
          
        }

    }

 

    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);
    
    
    }    


    componentDidMount(){
      $('#label_razonsocial').hide(); 
      $('#link_panelweb').hide();
      $('#vista_imgs').hide();
      $('#vista_imgs1').hide();
      $('#vista_imgs2').hide();

      //alert(id_orden)
      this.Detalle_idorden()
    }  

    empty_page_hide(){
      $('#detalle_compra_true').hide();     
      $('#detalle_compra_false').hide();
      
    }


    Detalle_idorden=async()=>{

      this.empty_page_hide();
      
      this.setState({ loading: this.state.loading = false })

      axios.get(ApiURL+"/api/Payments/GetStatusOrder/"+id_orden,

        )  
      .then(response=>{
        console.log(response.data)

        this.setState({Detalle_orden:response.data.order})

        proceso=response.data.data.status;
       
        mensaje  =response.data.message;      

        valor=response.data.data.amount_in_cents/100

        nom_comprador=this.state.Detalle_orden.names.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +" "+ this.state.Detalle_orden.lastNames.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))

        valor=(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP"}).format(valor));

        if(proceso==='APPROVED'){
          estado='Aprobada'
          
      }else{
        if(proceso==='DECLINED'){
          estado='Rechazada'
        }
      }

        if(proceso==='APPROVED'){
          $('#detalle_compra_false').hide();
          $('#detalle_compra_true').show();
        }else{
          $('#detalle_compra_false').show();
          $('#detalle_com pra_true').hide();
        }     

        if(response.data.order.type==='plan-personal' || response.data.order.type==='cuota-moderadora'){
          $('#btn_finalizarcompra').hide();
        }else{

          $('#btn_finalizarcompra').show();

        }
      

        this.setState({ loading: this.state.loading = true })
         
        
      })
      .catch(error=>{
        console.log(error.response);
        

        this.setState({Detalle_orden:error.response.data.order})       
      
        proceso=error.response.data.data.status;
       
        mensaje=error.response.data.message;
      
        valor=error.response.data.data.amount_in_cents/100

        valor=(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP"}).format(valor));

        nom_comprador=this.state.Detalle_orden.names.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +" "+ this.state.Detalle_orden.lastNames.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))

        if(proceso==='APPROVED'){
          estado='Aprobada'
          
      }else{
        if(proceso==='DECLINED'){
          estado='Rechazada'
        }
      }

        if(proceso==='DECLINED'){
            $('#detalle_compra_false').show();
            $('#detalle_com pra_true').hide();
        }else{
         
        } 
        
        if(error.response.data.order.type==='plan-personal' || error.response.data.order.type==='cuota-moderadora'){
          $('#btn_finalizarcompra_false').hide();
        }else{

          $('#btn_finalizarcompra_false').show();

        }      

        this.setState({ loading: this.state.loading = true })
     
    
    })
  
  
    } 

    imprimir_pagina(){
      window.print(); 
    }

    pagina_principal(){
      window.location.href="/perfil";
    }


   render(){  
      const {form} = this.state;

      return(      
        
       
        <div style={body} className=""> 

                <nav className="navbar navbar-expand navbar-white navbar-light" id="nav2">       
                
               
                 
                      <a id="img_center"  href="https://medicall24.com.co/" className="navbar-brand"> <img  src={img} alt="logo"  width= "230"/> </a>
                  
    
                </nav>
         
                <div className="content">

                    <div className="container-fluid">

                        <div className="row d-flex justify-content-center"> 
                        
                            <div className="d-flex justify-content-center">
                    
                                  {this.state.loading?false: <ReactBootStrap.Spinner animation="border" size="md" />}
                              
                            </div>
                                                     

                            <div id="detalle_compra_true" className="col-lg-8">

                                <div className="row">
                          
                                    <div className="col-sm-12">
                                    <br></br>   

                                        <div className="text-center"> 
                                             <h4> <b> Resumen de la compra </b>  </h4> 
                                             <h6> <b id="title"> {"Orden N° "+id_orden} </b>  </h6>
                                        
                                        </div>
                          
                                    </div>                          
                                  
                          
                                </div>
                                

                            
                                  
                                  <div className="card-body">
                                    <div className="tab-content" id="custom-tabs-three-tabContent">
                                      <div className="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                                        
                                              <div className="row d-flex justify-content-center">
                                                                 
                                              <b id="estado" > {estado} </b > <img src={icono_aprobado} alt="logo"  width= "35"/>                                                     
                                                                         
                                              </div>

                                              <br/>

                                              <div className="row d-flex justify-content-center">
                                                                 
                                              <label > Información del pago </label >                                                     
                                                                         
                                              </div>

                                              <br/>

                                              <div className="row d-flex justify-content-center">

                                                    <div className="col col-lg-5 text-right">
 
                                                        
                                                            <div className="form-group">                    
                                                               <label id="lavel" >Estado de la orden:</label>                                          
                                                                                     
                                                            </div>     

                                                            <div className="form-group">                    
                                                               <label id="lavel">ID orden:</label>                                          
                                                                                   
                                                            </div>   

                                                            <div className="form-group">                    
                                                               <label id="lavel">Referencia:</label>                                          
                                                                                   
                                                            </div> 

                                                            <div className="form-group">                    
                                                              <label id="lavel">Total:</label>                                          
                                                                                   
                                                            </div>  

                                                             <div className="form-group">                    
                                                                <label id="lavel">Descripción de la compra:</label>                                          
                                                                                    
                                                            </div>         
                                                        
 
 
                                                    </div>
 
                                                    <div className="col col-lg-5" id="hr_vertical">
 
                                                            <div className="form-group">                    
                                                                <label id="lavel"> {estado}   </label>                                                                                
                                                            </div>

                                                            <div className="form-group">                    
                                                                <label id="lavel"> {this.state.Detalle_orden.id} </label >                                          
                                                                                   
                                                            </div>

                                                            <div className="form-group">                    
                                                                <label id="lavel"> {this.state.Detalle_orden.reference}  </label >                                         
                                                                                   
                                                            </div>

                                                            <div className="form-group">                    
                                                                <label id="lavel"> {valor}    </label >                                        
                                                                                   
                                                            </div>

                                                            <div className="form-group">                    
                                                            <label id="lavel"> {this.state.Detalle_orden.description} </label >                                  
                                                                             
                                                            </div>
 
                                                        
                                                    </div>



                                              </div>  

                                              <br/>  
                                              <br/>                              

                                              <div className="row d-flex justify-content-center">
                                                                 
                                              <label > Información del método de pago </label >                                                     
                                                                         
                                              </div>

                                              <br/>

                                              <div className="row d-flex justify-content-center">

                                                    <div className="col col-lg-5 text-right">
 
                                                            <div className="form-group">                    
                                                                <label id="lavel">Banco:</label>                                          
                                                                                   
                                                            </div>

                                                            <div className="form-group">                    
                                                              <label id="lavel">IP movimiento:</label>                                          
                                                                                   
                                                            </div> 
 
 
                                                    </div>
 
                                                    <div className="col col-lg-5" id="hr_vertical">
 
                                                            <div className="form-group">                    
                                                                <label id="lavel">  {this.state.Detalle_orden.paymentMethod}   </label >                                     
                                                                            
                                                            </div>

                                                            <div className="form-group">                    
                                                                <label id="lavel"> {this.state.Detalle_orden.paymentRemoteIP} </label>                                       
                                                                                   
                                                            </div>
 
                                                    </div>

                                              </div>

                                              <br/>
                                              <br/>

                                              <div className="row d-flex justify-content-center">
                                                                 
                                              <label > Datos del comprador </label >                                                     
                                                                         
                                              </div>

                                              <br/>

                                              <div className="row d-flex justify-content-center">

                                                   <div className="col col-lg-5 text-right">

                                                            <div className="form-group">                    
                                                                <label id="lavel">Nombre:</label>                                          
                                                                                       
                                                            </div>
                                                            
                                                            <div className="form-group">                    
                                                                <label id="lavel">Identificación:</label>                                          
                                                                                 
                                                            </div> 

                                                            <div className="form-group">                    
                                                              <label id="lavel">Correo:</label>                                          
                                                                                   
                                                            </div> 

                                                   </div>

                                                   <div className="col col-lg-5" id="hr_vertical">

                                                            <div className="form-group">                    
                                                              <label id="lavel">  {nom_comprador}  </label >                                       
                                                                                       
                                                            </div>

                                                            <div className="form-group">                    
                                                              <label id="lavel"> {this.state.Detalle_orden.identification} </label >                                        
                                                                       
                                                            </div>

                                                            <div className="form-group">                    
                                                              <label id="lavel"> {this.state.Detalle_orden.email}   </label >                                       
                                                                                   
                                                            </div>

                                                   </div>

                                              </div>
                                      
                                      </div>                 
                                     
                                    </div>
                    
                                  </div>

                                  <div className="card-body col-md-12 col-auto text-center"> 

                                    <button type="button" id="btn" className="btn btn-primary" onClick={()=> this.imprimir_pagina()}> Imprimir                                     
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="16" fill="currentColor" class="bi bi-printer-fill" viewBox="0 0 16 15">
                                              <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"/>
                                              <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                                            </svg>
                                    </button>
                                    &nbsp; &nbsp;                                  

                                    <a type="button" id="btn_finalizarcompra" className="btn btn-primary" href="/perfil"> Finalizar compra </a>

 
                                  </div>
                            
            
                            </div>


                            <div id="detalle_compra_false" className="col-lg-8">

                                <div className="row">
                          
                                    <div className="col-sm-12">
                                    <br></br>   

                                        <div className="text-center"> 
                                             <h4> <b> Resumen de la compra </b>  </h4> 
                                             <h6> <b id="title"> {"Orden N° "+id_orden} </b>  </h6>
                                        
                                        </div>
                          
                                    </div>                          
                                  
                          
                                </div>

                            
                                  
                                  <div className="card-body">
                                    <div className="tab-content" id="custom-tabs-three-tabContent">
                                      <div className="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                                        
                                              <div className="row d-flex justify-content-center">
                                                                 
                                              <b id="estado" > {estado} </b > <img src={icono_rechazada} alt="logo"  width= "35"/>                                                     
                                                                         
                                              </div>

                                              <br/>

                                              <div className="row d-flex justify-content-center">
                                                                 
                                              <label > Información del pago </label >                                                     
                                                                         
                                              </div>

                                              <br/>

                                              <div className="row d-flex justify-content-center">

                                                    <div className="col col-lg-5 text-right">
 
                                                        
                                                            <div className="form-group">                    
                                                               <label id="lavel" >Estado de la orden:</label>                                          
                                                                                     
                                                            </div>     

                                                            <div className="form-group">                    
                                                               <label id="lavel">ID orden:</label>                                          
                                                                                   
                                                            </div>   

                                                            <div className="form-group">                    
                                                               <label id="lavel">Referencia:</label>                                          
                                                                                   
                                                            </div> 

                                                            <div className="form-group">                    
                                                              <label id="lavel">Total:</label>                                          
                                                                                   
                                                            </div>  

                                                             <div className="form-group">                    
                                                                <label id="lavel">Descripción de la compra:</label>                                          
                                                                                    
                                                            </div>         
                                                        
 
 
                                                    </div>
 
                                                    <div className="col col-lg-5" id="hr_vertical">
 
                                                            <div className="form-group">                    
                                                                <label id="lavel"> {estado}   </label>                                                                                
                                                            </div>

                                                            <div className="form-group">                    
                                                                <label id="lavel"> {this.state.Detalle_orden.id} </label >                                          
                                                                                   
                                                            </div>

                                                            <div className="form-group">                    
                                                                <label id="lavel"> {this.state.Detalle_orden.reference}  </label >                                         
                                                                                   
                                                            </div>

                                                            <div className="form-group">                    
                                                                <label id="lavel"> {valor}    </label >                                        
                                                                                   
                                                            </div>

                                                            <div className="form-group">                    
                                                            <label id="lavel"> {this.state.Detalle_orden.description} </label >                                  
                                                                             
                                                            </div>
 
                                                        
                                                    </div>



                                              </div>  

                                              <br/>  
                                              <br/>                              

                                              <div className="row d-flex justify-content-center">
                                                                 
                                              <label > Información del método de pago </label >                                                     
                                                                         
                                              </div>

                                              <br/>

                                              <div className="row d-flex justify-content-center">

                                                    <div className="col col-lg-5 text-right">
 
                                                            <div className="form-group">                    
                                                                <label id="lavel">Banco:</label>                                          
                                                                                   
                                                            </div>

                                                            <div className="form-group">                    
                                                              <label id="lavel">IP movimiento:</label>                                          
                                                                                   
                                                            </div> 
 
 
                                                    </div>
 
                                                    <div className="col col-lg-5" id="hr_vertical">
 
                                                            <div className="form-group">                    
                                                                <label id="lavel">  {this.state.Detalle_orden.paymentMethod}   </label >                                     
                                                                            
                                                            </div>

                                                            <div className="form-group">                    
                                                                <label id="lavel"> {this.state.Detalle_orden.paymentRemoteIP} </label>                                       
                                                                                   
                                                            </div>
 
                                                    </div>

                                              </div>

                                              <br/>
                                              <br/>

                                              <div className="row d-flex justify-content-center">
                                                                 
                                              <label > Datos del comprador </label >                                                     
                                                                         
                                              </div>

                                              <br/>

                                              <div className="row d-flex justify-content-center">

                                                   <div className="col col-lg-5 text-right">

                                                            <div className="form-group">                    
                                                                <label id="lavel">Nombre:</label>                                          
                                                                                       
                                                            </div>
                                                            
                                                            <div className="form-group">                    
                                                                <label id="lavel">Identificación:</label>                                          
                                                                                 
                                                            </div> 

                                                            <div className="form-group">                    
                                                              <label id="lavel">Correo:</label>                                          
                                                                                   
                                                            </div> 

                                                   </div>

                                                   <div className="col col-lg-5" id="hr_vertical">

                                                            <div className="form-group">                    
                                                              <label id="lavel">  {nom_comprador}  </label >                                       
                                                                                       
                                                            </div>

                                                            <div className="form-group">                    
                                                              <label id="lavel"> {this.state.Detalle_orden.identification} </label >                                        
                                                                       
                                                            </div>

                                                            <div className="form-group">                    
                                                              <label id="lavel"> {this.state.Detalle_orden.email}   </label >                                       
                                                                                   
                                                            </div>

                                                   </div>

                                              </div>
                                      
                                      </div>                 
                                     
                                    </div>
                    
                                  </div>

                                  <div className="card-body col-md-12 col-auto text-center"> 

                                    <button type="button" id="btn" className="btn btn-primary" onClick={()=> this.imprimir_pagina()}> Imprimir                                     
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="16" fill="currentColor" class="bi bi-printer-fill" viewBox="0 0 16 15">
                                              <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"/>
                                              <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                                            </svg>
                                    </button>
                                    &nbsp; &nbsp;                                  

                                    <a type="button" id="btn_finalizarcompra_false" className="btn btn-primary" href="/perfil"> Finalizar compraa </a>
                                    
                                   
                                  </div>
                            
            
                            </div>




  
                        </div>

                    </div>
                    
                </div>

         
              <footer className="d-flex justify-content-center" id="footer" >
                  <strong>Copyright &copy; 2020 Medicall24  <a href="http://adminlte.io"> Terminos y condiciones</a> - </strong>
                     Todos los derechos reservados. 
                     <b> Version </b> 2.0.4
                    <div class="float-right d-none d-sm-inline-block">            
                    </div>
              </footer>


              


              

    
          

        </div>
         
      )

  }  
  
  
}






export default Detalle_compra;