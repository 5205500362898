import React, {Component,useState} from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/Convenios.css';
import Profesionales from '../API/Profesionales';
import TypeService from '../API/TypeService';
import Servicios_cobro from '../API/Servicios_cobro';
import Logo_nuevo from '../tools/images/Nuevo';
import Logo_aceptar from '../tools/images/Acept';
import BtnEdit from '../components/BtnEdit';
import BtnCancel from '../components/BtnCancel';
import BtnDelete from '../components/BtnDelete';
import Buscar from '../tools/images/Buscar';
import emptypage from '../tools/images/carpeta.gif';
import Select from 'react-select';

import iconofirma from '../tools/images/icono firmaconvenio.svg'

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';
import { FaTumblrSquare } from 'react-icons/fa';

var queryString = window.location.search;
var urlParams = new URLSearchParams(queryString);
var param = urlParams.get('nuevo');


const ApiURL="https://medicallapi.azurewebsites.net/";
var institution_id =sessionStorage.getItem('id_institution');
var mensaje='';
var id_profe='0';
var id_servi=0;
var id_servi_edit='0';
var id_especialidad=0;
var id_servicobro=null;
var buscar_convenio='';
var id_convenio='0';
var id_especialidad_edit=0;
var id_servicobro_edit=0;
var id_espe_delete=0;
var id_servi_delete=0;
var nombre_tiposervicio="";
var firma='';
var num_file='Sin archivo';
var firmap='';
var num_filep='Sin archivo';
var getfirma=null;
var estado_conv=''
var archivoSeleccionado = [];
var tipo_id="null";
var llave_publi='';


class Convenios extends Component {

  constructor()
    {
     super()
      this.state={
        showMe:false,        
        profe: '',
        Contrato: [],
        Contrato_particular: [],
        Convenio:[], 
        servicios: [],
        Servicios_Multiple: [],
        servicios_multi: null,
        servicios_multiedit: [],
        Pager_Convenios: [],
        pager_infoconvenio:'',
        Type_services:[],
        Servi_convenio:[], 
        Especialidades:[], 
        Servi_convenionew:[], 
        Type_servicenew:[],
        Tipo_id:[],
        espe:'0',
        loading: false,
        loading_button: true,
        active_button: true, 
        active_button_nuevo: false,
        loading_button_firma: true,
        active_button_firma: true,     
        loading_button_eliminar: true,
        active_button_eliminar: true,      
        form:{   
          id_conve_edit:'',  
          id_profe_edit:'',
          identificacion_med:'',
          nom_med:'',
          nom_medico_edit:'',   
          nom2_medico_edit:'',
          apell1_medico_edit:'',
          apell2_medico_edit:'',
          espe_edit:'',
          fecha_ini:'',
          fecha_fin:'',
          name_tipo_servi:'',
          tipo_servi_edit:'',
          fecha_ini_edit:new Date,
          fecha_fin_edit:new Date,
          Type_service_delete:'',
          servio_cobro_delete:'',
          selectedFile:null,
          selectedFilep:null,
          buscar_convenios:'',
          currentDateTime: Date().toLocaleString(),    
          value:'',
          }     
              
      }      
      
    }


    handleChange=async e=>{
      
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);
         //this.Selectdrop();
    }

    Campo_numerico(){
      $(".camponum").keypress(function(tecla)
      {
        if(tecla.charCode<48 || tecla.charCode>57){
         
          return false;

        }
      })
    }

    _handleKeyDown = (e) => {
      this.Campo_numerico();
      if (e.key === 'Enter' || e.key=='Tab') {
       
        this.Buscar_Profesional()
        
      }
    }


    Buscar_Profesional=async()=>{

      if(tipo_id=='null' || this.state.form.identificacion_med.length==0){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        axios.post(ApiURL+"/api/Users/GetUser",
        {
        identification: this.state.form.identificacion_med,
        typeId: tipo_id
        
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  =response.data.id +'-'+ response.data.user.name1 +' '+ response.data.user.name2 +' '+ response.data.user.lastname1 +' '+ response.data.user.lastname2;
        id_profe=response.data.id

        var rol=response.data.rol

        if(rol=='Professional'){

          var name1= response.data.user.name1 ? response.data.user.name1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '';
          var name2= response.data.user.name2 ? response.data.user.name2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''
          var lastname1= response.data.user.lastname1 ? response.data.user.lastname1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''
          var lastname2= response.data.user.lastname2 ? response.data.user.lastname2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''

          this.setState({
            form:{      
              nom_med:  name1 +' '+ name2 +' '+  lastname1 +' '+ lastname2               
            }
          })
  
          this.Cargar_Especialidades(id_profe)
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          /*store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })*/
  
          this.setState({ buscar_pcte: this.state.buscar_pcte = true })  

        }else{

          store.addNotification({
            title: 'Atención',
            message:'La identificación del Profesional o Especialista al que le intenta crear el Convenio, no se encuentra registrada en la App Medicall24.',
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

        }
        //alert(mensaje);
            
        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

      }
  
     }
    

    Selectdrop(){
      if(id_profe==0 && this.state.form.fecha_ini!=''){
        $("#api_profesional").val($('#api_profesional > option:first').val()); 
        $("#espe").val($('#espe > option:first').val());
        $("#api_servicio").val($('#api_servicio > option:first').val());
        $("#servicio_cobro").val($('#servicio_cobro > option:first').val()); 

      }

      if(id_especialidad!==0 && id_servi==0 && id_servicobro==null ){
        
        $("#api_servicio").val($('#api_servicio > option:first').val());
        $("#servicio_cobro").val($('#servicio_cobro > option:first').val()); 

      }

      if(id_profe!==0 && id_servi==0 && id_servicobro==null ){
        
        $("#api_servicio").val($('#api_servicio > option:first').val());
        $("#servicio_cobro").val($('#servicio_cobro > option:first').val()); 

      }
      
    }


    handlerSelectdrop(){
      
      $("#api_profesional").val($('#api_profesional > option:first').val());
      $("#espe").val($('#espe > option:first').val());
      $("#api_servicio").val($('#api_servicio > option:first').val());
      $("#servicio_cobro").val($('#servicio_cobro > option:first').val());
      $("#nom_agenda").val("");
      $("#fecha_ini").val("");
      $("#fecha_fin").val("");
      $("#div_convenio1").find("*").prop('disabled', false);
      $("#div_convenio2").find("*").prop('disabled', false);
      $("#div_convenio3").find("*").prop('disabled', false);
      $("#div_convenio4").find("*").prop('disabled', false);
      $('#div_convenio5').hide();
      $('#cb_servicio').hide();

      //this.setState({ active_button_nuevo: this.state.active_button_nuevo = false })

      id_profe=0;
      id_especialidad=0;
      id_servi=0;
      id_servicobro=null;
      firma=''; 
      //num_file='Sin archivo'; 
      $('#ini_imagen').hide();
      $('#sin_imagen').show();
      $('#con_imagen').hide();    
     
     
    }

   


    handleModalnewconvenio()
    {      
      $('#modal-newconvenio').modal('show'); // <- to show modal crear sede 
    }
  
    handleModaleditconvenio()
    {
      
      $('#modal-editconvenio').modal('show'); // <- to show modal crear sede 
    } 

    handleModaleditconveniodrop()
    {     
      $('#modal-editconvenio').on('show.bs.modal', function (event) {        
        $("#modal-editconvenio file").val(null);    
        $("#modal-editconvenio select").val("Seleccione...");    
    }); 
    
      
    }
    

    handleModaleliminarservicio()
    {
      
      $('#modal-eliminar_servicio').modal('show'); // <- to show modal crear sede 
    }

    handleModaleliminarespecialidad()
    {
      
      $('#modal-eliminar_especialidad').modal('show'); // <- to show modal crear sede 
    }

    handleModalAnularconvenio(){

      $('#modal-anular_convenio').modal('show');

     }

     handleGuardarConvenio(){

      $('#modal-guardar_convenio').modal('show');

     }

     handleDeshacerFormulario(){

      if(id_profe!=='0' || this.state.form.fecha_ini!=='' || this.state.form.fecha_fin!=='' || id_servi!==0 || id_servicobro!==null ||  id_especialidad!==0){
        $('#modal-deshacerform').modal('show');
      }else{
        
      }

      

     }

     deshacerform(){

      window.location.href="/convenios?nuevo=true"

     }

    empty_page_hide(){
      $('#tabla1').hide();     
      $('#empty_page').hide();
      $('#text_page').hide();
    }



    GetEspecialidad = (es) =>{
     
      //this.setState({espe:es.target.value})
      //this.setState({selectedFile: null})
      id_servicobro=null
      console.log(es.target.value)
      id_especialidad=(es.target.value)      
      
      //this.Selectdrop();      
     
    }
    

    GetEspecialidadedit = (ese) =>{     
      
      //this.setState({selectedFile: null})
      //id_servicobro_edit=null      
      id_especialidad_edit=(ese.target.value)
      console.log(ese.target.value)

      //alert(id_especialidad_edit)      
      
      //this.Selectdrop();      
     
    }

    BuscarConvenio = (bc) =>{

      this.setState({buscar_convenios:bc.target.value})
      console.log(bc.target.value)
      buscar_convenio=(bc.target.value)
      this.searchDataconvenios(buscar_convenio)
     
    }

    GetImge = (i) =>{     

      this.setState({selectedFile:i.target.files[0]})
      console.log(i.target.value)
      firma=(i.target.value);

      archivoSeleccionado.push(this.state.selectedFile);
      num_file=(i.target.files.length)+" imagen"
     
      
      i.currentTarget.files = null;
      console.log(archivoSeleccionado.length);
      //alert("evento img")      
     
    }

    GetImg_portada = (ip) =>{     
      this.setState({selectedFilep:ip.target.files[0]})
      console.log(ip.target.value)
      firmap=(ip.target.value);
      //files_viewp=URL.createObjectURL(ip.target.files[0]);
      num_filep=(ip.target.files.length)+" imagen"
     
      //this.modal_img_portada() 
      //alert(cover)
    }

    GettipoID = (id) =>{
      //this.setState({tipoid:id.target.value})
      console.log(id.target.value)
      tipo_id=(id.target.value)     
    }

    dropfirma(){

      //alert("evento firma")  

      if(archivoSeleccionado.length>0){
        //alert("mayor a 0")
        $('#ini_imagen').hide();
        $('#sin_imagen').hide();
        $('#con_imagen').show();
        
        firma='1'; 
        num_file=(archivoSeleccionado.length)+" archivo"
      }else{
        //alert("no es mayor a 0")
      }

    }


    componentDidMount(){  

      if(param=='true'){

        $('#custom-tabs-three-profile-tab').click();

      }

      $('[data-toggle="tooltip"]').tooltip()
      this.Cargar_tipo_id();
      this.Cargar_convenios();
      this.Cargar_servicios();
      this.getServicioMultiple();
      this.Datos_Institucion();
      $('#div_convenio5').hide();
      
     
      //$('#cb_servicio').hide();

    }

    Datos_Institucion(){    
      
      axios.get(ApiURL+"/api/Institutions/GetInstitution/"+institution_id)
      .then((response) => {
        //console.log(response);

        this.setState({DataIntitucion: response.data.institution})       
      

          llave_publi=this.state.DataIntitucion.publicKey

          //alert(llave_publi)      

        
      })
      .catch((error) => {
        console.log(error);
      })
    }

    Cargar_tipo_id=async()=>{    
    
      await axios.get(ApiURL+"/api/TypeIdentification/ListTypeIdentification/profesional")
        .then((response) => {
          console.log(response);
          this.setState({Tipo_id: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
    }


    Cargar_servicios=async()=>{    
    
      await axios.get("https://medicallapi.azurewebsites.net//api/Services/ListAll")
        .then((response) => {
          console.log(response);
          this.setState({servicios: response.data.data})

          if(this.state.servicios.length==0){

            $('#modal-verificahorario').modal('show');
         
          }else{

            
          }

        })
        .catch((error) => {
          console.log(error);
        })
      }

      async getServicioMultiple(){
        const res = await axios.get('https://medicallapi.azurewebsites.net//api/TypeServices')
        const data = res.data
    
        const options = data.map(d => ({
          "value" : d.id,
          "label" : d.name
    
        }))
    
        this.setState({Servicios_Multiple: options})
    
      }


      Cargar_contratos(){  

        axios.get(ApiURL+"/api/Contracts/List")
        .then((response) => {
         // console.log(response);
          this.setState({Contrato: response.data.data})
         

          if(this.state.Contrato.length==0){

            //this.setState({ active_button: this.state.active_button = false })
    
            $('#modal-contrato').modal('show');
           
         
          }else{

            
            //alert(this.state.form.Contrato_particular.length)

              this.handleGuardarConvenio()

              //this.handleGuardarConvenio()
              //this.setState({ active_button: this.state.active_button = true })
           
            
          }


        })
        .catch((error) => {
          console.log(error);
        })
      }


      Cargar_contrato_particular(){         
      
    
        axios.get(ApiURL+"/api/Contracts/List")
        .then((response) => {
         // console.log(response);
         this.setState({Contrato_particular:response.data.data.filter(item => item.entityId == 999)})
         
          
          if(this.state.Contrato_particular.length == 0){

            //this.setState({ active_button: this.state.active_button = false })
            
            
            $('#modal-contrato-particular').modal('show');
           
         
          }else{

            this.handleGuardarConvenio()    
            
          }


        })
        .catch((error) => {
          console.log(error);
        })
      }

    Cargar_convenios(){
      
      this.empty_page_hide();
      
      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Agreements/ListAll")
      .then((response) => {
        console.log(response);
        this.setState({Convenio: response.data.data})
        this.setState({Pager_Convenios: response.data.pager.items})
        this.setState({pager_infoconvenio: response.data.pager.showData})
        //this.Selectdrop()

        if(this.state.Convenio.length==0){
          $('#tabla1').hide();
          $('#empty_page').show();
          $('#text_page').show();
        }else{
          $('#tabla1').show();
          $('#empty_page').hide();
          $('#text_page').hide();
        }

        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })

    }

    loadMoreDataconvenios(page_conve){ 
      var page=page_conve.pagina     
      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Agreements/ListAll?numeropagina="+page)
      .then((response) => {
        console.log(response);
        this.setState({Convenio: response.data.data})
        this.setState({Pager_Convenios: response.data.pager.items})
        this.setState({pager_infoconvenio: response.data.pager.showData})

        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })

    }


    searchDataconvenios(buscar_convenio){ 
         
      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Agreements/ListAll?texto="+buscar_convenio)
      .then((response) => {
        console.log(response)
        this.setState({Convenio: response.data.data})
        this.setState({Pager_Convenios: response.data.pager.items})
        this.setState({pager_infoconvenio: response.data.pager.showData})

        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })

    }

    
    seleccionarconvenio=(convenio)=>{
      id_convenio=convenio.id
      id_profe=convenio.professional.id
      getfirma=convenio.signature
      estado_conv=convenio.status
      firma=''
      this.seleccionarconvenio_servicio_especialidad(id_convenio);
      this.Cargar_Especialidades(id_profe);
      this.setState({
          form:{
          id_conve_edit:convenio.id, 
          id_profe_edit:convenio.professionalId,
          nom_medico_edit: convenio.professional.user.name1 ? (convenio.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',   
          nom2_medico_edit: convenio.professional.user.name2 ? (convenio.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          apell1_medico_edit: convenio.professional.user.lastname1 ? (convenio.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          apell2_medico_edit:convenio.professional.user.lastname2 ? (convenio.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
          espe_edit:convenio.description ,
          tipo_servi_edit:convenio.description,
          fecha_ini_edit:moment(convenio.startDate).format("YYYY-MM-DD"),
          fecha_fin_edit:moment(convenio.endDate).format("YYYY-MM-DD"),
          name_tipo_servi:convenio.typeService.name     
        }
      })
     
      //var  dato  = solicitud.user.identification;
      //alert(this.state.form.fecha_ini_edit);
      
    }
    

    seleccionarconvenio_servicio_especialidad(id_convenio){  
      
      if(estado_conv=='anulado' || estado_conv=='vencido' ){

        $('#rowfirma').hide();

      }else{

        $('#rowfirma').show();

      }
      
      axios.get(ApiURL+"/api/Agreements/"+id_convenio)
      .then((response) => {

        console.log(response);
        this.setState({
          Type_services:response.data.agreement.typeService,
          Servi_convenio:response.data.agreement.serviceAgreements
        })

        id_especialidad_edit=0

        if(this.state.Servi_convenio.length>0){ 

          $("#espe_edit").val($('#espe_edit > option:first').val());
          
          $('#select_servi_edit').hide();
          $('#select_espe_edit').hide();    

        }else{     
          
          $("#espe_edit").val($('#espe_edit > option:first').val());

          $('#select_servi_edit').show();
          $('#select_espe_edit').show();
         
        }
        
      })
      .catch((error) => {
        console.log(error);
      })      
    
      //var  dato  = solicitud.user.identification;
      //alert(dato);
    }


    seleccionarconvenio_servicio_especialidadnew(id_convenio){     
      
      axios.get(ApiURL+"/api/Agreements/"+id_convenio)
      .then((response) => {

        console.log(response);
        this.setState({
          Type_servicenew:response.data.agreement.typeService,
          Servi_convenionew:response.data.agreement.serviceAgreements
        })
        
      })
      .catch((error) => {
        console.log(error);
      })      
    
      //var  dato  = solicitud.user.identification;
      //alert(dato);
    }

    Borrar_servicio(service){
      id_servi_delete  = service.id;

      this.handleModaleliminarservicio();

     }


    seleccionartypeservice=()=>{
      this.setState({        
        form:{
          Type_service_delete:id_servi_delete, 
          nom_medico_edit: this.state.form.nom_medico_edit,   
          nom2_medico_edit: this.state.form.nom2_medico_edit,
          apell1_medico_edit: this.state.form.apell1_medico_edit,
          apell2_medico_edit: this.state.form.apell2_medico_edit,
                   
        }
      })
     
      axios.delete(ApiURL+"/api/Agreements/RemoveTypeService/"+id_servi_delete)
      .then((response) => {
        console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.seleccionarconvenio_servicio_especialidad(id_convenio);
          this.seleccionarconvenio_servicio_especialidadnew(id_convenio);
          this.componentDidMount();
        
      })
      .catch((error) => {
        console.log(error);
      })
    }


    Borrar_especialidad(servi_convenio){
      id_espe_delete  = servi_convenio.id;

      this.handleModaleliminarespecialidad();

     }


    seleccionarserviconvenio=()=>{
      this.setState({        
        form:{
          servio_cobro_delete:id_espe_delete,
          nom_medico_edit: this.state.form.nom_medico_edit,   
          nom2_medico_edit: this.state.form.nom2_medico_edit,
          apell1_medico_edit: this.state.form.apell1_medico_edit,
          apell2_medico_edit: this.state.form.apell2_medico_edit,
          fecha_ini_edit:this.state.form.fecha_ini_edit, 
          fecha_fin_edit:this.state.form.fecha_fin_edit,                
        }
      })
     
      //alert(dato);
      axios.delete(ApiURL+"/api/Agreements/RemoveServiceSpecialty/"+id_espe_delete)
      .then((response) => {
        console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.seleccionarconvenio_servicio_especialidad(id_convenio);
          this.seleccionarconvenio_servicio_especialidadnew(id_convenio);
          this.componentDidMount();
        
      })
      .catch((error) => {
        console.log(error);
      })
    }

    Validar_form_coonvenio(){

      if( !moment(this.state.form.fecha_ini,'YYYY-MM-DD', true).isValid() || !moment(this.state.form.fecha_fin,'YYYY-MM-DD', true).isValid()){
          
        store.addNotification({
          title: 'Atención',
          message: "La fecha ingresada no es valida",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })
      
      }else{     

        if(id_profe==0 || this.state.form.fecha_ini=='' || this.state.form.fecha_fin=='' || id_servi==0 || id_servicobro==null ||  id_especialidad==0){

          store.addNotification({
            title: 'Atención',
            message: "Faltan datos por ingresar",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

        }else{

          if(this.state.selectedFilep==null || firmap==''){

            store.addNotification({
              title: 'Atención',
              message: "Falta seleccionar la firma del médico",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

         
          }else{

            if(id_servi==1 || id_servi==2){

              this.Cargar_contratos();
              
      
            }else{

              if(id_servi==3){

                if(llave_publi==null || llave_publi==''){

                  $('#modal-llaveswompi').modal('show');
        
                }else{
  
                this.Cargar_contrato_particular()
  
                }
                

              }
              
              //this.setState({ active_button: this.state.active_button = true })
              //this.saveconvenios();
   

            }            

          }           

        } 
        
      }

    }
    
    
    saveconvenios=async()=>{
      
      if(this.state.form.fecha_ini.length>10 || this.state.form.fecha_fin.length>10){

        store.addNotification({
          title: 'Atención',
          message: "Ingrese un rango de fecha valido",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

       

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.put(ApiURL+"/api/Agreements/Create",  

          {
            professionalId: id_profe,
            startDate: this.state.form.fecha_ini,
            endDate: this.state.form.fecha_fin,
            typeServiceId: id_servi,
             servicesAgreement: 
              [
            {
              serviceId: id_servicobro,
              specialtyId: id_especialidad
            }
              ]
          } 
        )  
  
        .then(response=>{

          console.log(response.data)
          id_convenio  = response.data.agreement.id;

          this.seleccionarconvenio_servicio_especialidadnew(id_convenio);
          

          if(this.state.selectedFilep!==null && firmap!==''){

            this.subir_firmaconvenio();
    
          }
          //this.setState({
            //Type_servicenew:response.data.agreement.typeServiceAgreements,
            //Servi_convenionew:response.data.agreement.serviceAgreements
          //})
          //alert(id_convenio);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: "El convenio se guardó",
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          this.setState({ active_button_nuevo: this.state.active_button_nuevo = true })
          //this.setState({selectedFile: null})
          //num_file='Sin archivo';
          
          $('#div_convenio5').show();
          $("#div_convenio1").find("*").prop('disabled', true);
          $("#div_convenio2").find("*").prop('disabled', true);
          $("#div_convenio3").find("*").prop('disabled', true);
          $("#div_convenio4").find("*").prop('disabled', true);
          //this.seleccionarconvenio_servicio_especialidad(id_convenio);
          //this.componentDidMount();
          
        })
        .catch(error=>{
          
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

     }   
   
    }


     editconvenios=async()=>{

      if( !moment(this.state.form.fecha_ini_edit,'YYYY-MM-DD', true).isValid() || !moment(this.state.form.fecha_fin_edit,'YYYY-MM-DD', true).isValid()){
          
        store.addNotification({
          title: 'Atención',
          message: "Ingrese un rango de fecha valido",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })
      
      }else{ 
     
      if( id_convenio==0 || id_profe==0 || this.state.form.fecha_ini_edit.length==0 || this.state.form.fecha_fin_edit.length==0 ){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        if(id_especialidad_edit==0){

          this.editconvenios_fecha();

        }else{

        await axios.put(ApiURL+"/api/Agreements/Edit",  

            {
              agreementId: id_convenio,
              professionalId: id_profe,
              startDate: this.state.form.fecha_ini_edit,
              endDate: this.state.form.fecha_fin_edit,
              typeServiceId: this.state.Type_services.id,
               servicesAgreement: 
                [
              {
                serviceId: id_servicobro,
                specialtyId: id_especialidad_edit
              }
                ]
            } 
          )  
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(dato);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          
          this.seleccionarconvenio_servicio_especialidad(id_convenio);
          this.Cargar_convenios();
          firma='';
          
        })
        .catch(error=>{
          
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ loading_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ loading_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ loading_button: this.state.active_button = true })
        }
        //console.log(error.config);
      
      })

      }

      }

      }
  
     }


     editconvenios_fecha=async()=>{

      if( !moment(this.state.form.fecha_ini_edit,'YYYY-MM-DD', true).isValid() || !moment(this.state.form.fecha_fin_edit,'YYYY-MM-DD', true).isValid()){
          
        store.addNotification({
          title: 'Atención',
          message: "Ingrese un rango de fecha valido",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })
      
      }else{ 
     
      if( id_convenio==0 || id_profe==0 || this.state.form.fecha_ini_edit.length==0 || this.state.form.fecha_fin_edit.length==0 ){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        
        await axios.put(ApiURL+"/api/Agreements/Edit",  

            {
              agreementId: id_convenio,
              professionalId: id_profe,
              startDate: this.state.form.fecha_ini_edit,
              endDate: this.state.form.fecha_fin_edit,
              typeServiceId: this.state.Type_services.id
            } 
          )  
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(dato);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          
          this.seleccionarconvenio_servicio_especialidad(id_convenio);
          this.Cargar_convenios();
          firma='';
          
        })
        .catch(error=>{
          
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ loading_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ loading_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ loading_button: this.state.active_button = true })
        }
        //console.log(error.config);
      
      })

      }

      }
  
     }



    Anular_convenio=async()=>{

      this.setState({ loading_button_eliminar: this.state.loading_button_eliminar = false })
      this.setState({ active_button_eliminar: this.state.active_button_eliminar = false })
     
      axios.delete(ApiURL+"/api/Agreements/VoidAgreement/"+id_convenio)
      .then((response) => {
        console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
          
          this.setState({ loading_button_eliminar: this.state.loading_button_eliminar = true })
          this.setState({ active_button_eliminar: this.state.active_button_eliminar = true })
          
          $('#modal-anular_convenio').modal('hide');
          $('#modal-editconvenio').modal('hide');
          this.Cargar_convenios();

        
      })
      .catch((error) => {
        
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button_eliminar: this.state.loading_button_eliminar = true })
          this.setState({ active_button_eliminar: this.state.active_button_eliminar = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
          this.setState({ loading_button_eliminar: this.state.loading_button_eliminar = true })
          this.setState({ active_button_eliminar: this.state.active_button_eliminar = true })
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({ loading_button_eliminar: this.state.loading_button_eliminar = true })
          this.setState({ active_button_eliminar: this.state.active_button_eliminar = true })
      }
      //console.log(error.config);

      })
    }


     subir_firma=async()=>{
      

      if(this.state.selectedFile==null || firma==''){

        store.addNotification({
          title: 'Atención',
          message: "Seleccione la imagen con la firma del profesional",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        
        this.setState({ loading_button_firma: this.state.loading_button_firma = false })
        this.setState({ active_button_firma: this.state.active_button_firma = false })

        let formData = new FormData();        
        formData.append('Id', id_convenio)
        formData.append('File', this.state.selectedFile)

        await axios.put(ApiURL+"/api/Agreements/UploadSignature", formData,{

          headers: {
            'Content-Type': 'multipart/form-data'
          }

        })  
        .then(response=>{
          console.log(response.data)
          //var  mensaje  = response.data.agreement.signature;
          getfirma=response.data.agreement.signature
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: 'La firma ha sido cargada',
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })

          this.setState({ loading_button_firma: this.state.loading_button_firma = true })
          this.setState({ active_button_firma: this.state.active_button_firma = true })

          $('#alert-activacuenta').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
          this.Cargar_convenios();
          firma='';
          
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

          this.setState({ loading_button_firma: this.state.loading_button_firma = true })
          this.setState({ active_button_firma: this.state.active_button_firma = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.status);
            this.setState({ loading_button_firma: this.state.loading_button_firma = true })
            this.setState({ active_button_firma: this.state.active_button_firma = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button_firma: this.state.loading_button_firma = true })
            this.setState({ active_button_firma: this.state.active_button_firma = true })
        }
        //console.log(error.config);
      
      })

      }      
  
     }

     subir_firmaconvenio=async()=>{
      

      if(this.state.selectedFilep==null || firmap==''){

        store.addNotification({
          title: 'Atención',
          message: "Seleccione la imagen con la firma del profesional",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        
       

        let formData = new FormData();        
        formData.append('Id', id_convenio)
        formData.append('File', this.state.selectedFilep)

        await axios.put(ApiURL+"/api/Agreements/UploadSignature", formData,{

          headers: {
            'Content-Type': 'multipart/form-data'
          }

        })  
        .then(response=>{
          console.log(response.data)
          //var  mensaje  = response.data.agreement.signature;
          getfirma=response.data.agreement.signature
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: 'La firma ha sido cargada',
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })

          //firma='';

          window.location.href="/convenios?nuevo=true"
          
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

         

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.status);
            
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            
        }
        //console.log(error.config);
      
      })

      }      
  
     }

     Cargar_Especialidades(id_profe){    
      
      axios.get(ApiURL+"/api/Specialties/GetSpecialtiesProfessional/"+id_profe)
      .then((response) => {
        console.log(response);
        this.setState({Especialidades: response.data})
        
        //$("#espe").val($('#espe > option:first').val());
        //$("#api_servicio").val($('#api_servicio > option:first').val());
        //$("#servicio_cobro").val($('#servicio_cobro > option:first').val());
        
      })
      .catch((error) => {
        console.log(error);

        const fs = require('fs');
        let promesaEscrituraArchivo = new Promise((resolve, reject) => {
          fs.writeFile('ex313_archivo_texto.txt', 'Node.js v. 10.0', (error) => {
              if(error){
                  reject(error);
              } else {
                  resolve();
              }
          });
      });

      promesaEscrituraArchivo.then(() => {
    console.log('La escritura del archivo ha sido satisfactoria.');
    })
    .catch((error) => {
        console.log('Ha ocurrido un error:', error);
    });

       
      })
    }

 

    handleridprofesional = (p) => {
      console.log(p)
      id_profe=(p)
      //alert(id_profe)
      this.Cargar_Especialidades(id_profe)

      
    }



    /*handleridservi =  servicios_multi => {      
    
      this.setState({ servicios_multi});
      console.log(servicios_multi)     
      
      
    }*/

    

    /*handleridservi_edit =  servicios_multiedit => {      
    
      this.setState({ servicios_multiedit});
      console.log(servicios_multiedit)      
      
      
    }*/

    handleridservi = (s) => {
      console.log(s)
      id_servi=(s)
     
     
      //alert(id_especialidad)
    }
    

    handleridserviedit = (se) => {
      console.log(se)
      id_servi_edit=(se)
     
      //alert(id_especialidad)
    }

    handleridespe = (es) => {
      console.log(es)      
      id_especialidad_edit=(es)
   

     
      //alert(id_especialidad)
    }

    handleridservicobro = (ec) => {
      console.log(ec)
      id_servicobro=(ec)
      id_servicobro_edit=(ec)
     
      //alert(id_servicobro)
      //console.log(id_servicobro)
    }
    
  

    render(){     
     const {form} = this.state;

     return ( 

        <div className="content-wrapper">


              <div className="col-12 col-sm-12">

                    <div className="row">
              
                        <div className="col-sm-10">
                          <br></br>
                           <div className="text-center" id="title"> <h6> <b> GESTIONAR CONVENIOS </b>  </h6> </div>
              
                        </div>
              
              
                    </div>

                    <div className="card card-primary card-outline card-tabs">

                          <div className="card-header p-0 pt-1 border-bottom-0">
                               <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                                  <li className="nav-item">
                                    <a className="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true" onClick={()=>{this.Cargar_convenios()}} >Convenios <Logo_aceptar/> </a>
                                  </li>
                                  <li className="nav-item">
                                    <a className="nav-link" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false"  >Crear Convenios <Logo_nuevo/> </a>
                                  </li>                
                              </ul>
                            
                          </div>

                          <div className="card-body">

                              <div className="tab-content" id="custom-tabs-three-tabContent">
                                
                                  <div className="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                                    
                                        <div class="d-flex justify-content-center">
                                          
                                          {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                        
                                        </div>
                                        
                                        <div className="card table-responsive" id="tabla1">  

                                            <div className="row">
                                      
                                                <div className="col-sm-3">
                                                      <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                         <div className="input-group-prepend">
                                                           <div className="input-group-text"><Buscar/></div>
                                                         </div>
                                                         <input type="search" className="form-control" placeholder="Buscar" name="buscar_convenios" onChange={this.BuscarConvenio}/>
                                                      </div>                                
                                                </div>
                                                
                                            </div>                                        
                                          
                                            <table id="tabla_convenios" className="table table-bordered table-striped table-hover">
                                              <thead>
                                                <tr>
                                                  <th>ID</th>
                                                  <th>Profesional</th>  
                                                  <th>Especialidad</th>  
                                                  <th>Tipo de servicio</th>       
                                                  <th>Fecha inicial</th>
                                                  <th>Fecha final</th>
                                                  <th>Estado</th>
                                                  <th>Editar</th>                                                  
                                                </tr>
                                              </thead>
                                              <tbody>
                                              {this.state.Convenio.map(convenio=>{

                                                  nombre_tiposervicio=convenio.typeService.name;
                                                  var Especialidad_convenio=[]
                                                  Especialidad_convenio=convenio.serviceAgreements
                                                return(     
                                                
                                                <tr>          
                                                  <td>{convenio.id}</td>
                                                  <td>{convenio.professional.user.name1 ? (convenio.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {convenio.professional.user.name2 ? (convenio.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {convenio.professional.user.lastname1 ? (convenio.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {convenio.professional.user.lastname2 ? (convenio.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}</td>
                                                  <td>{Especialidad_convenio.map(espe=>{ return( espe.specialty.name.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) )})}</td>
                                                  <td>{nombre_tiposervicio}</td>
                                                  <td> 
                                                  <Moment format="YYYY/MM/DD">
                                                  {convenio.startDate}
                                                  </Moment>
                                                  </td>
                                                  <td> 
                                                  <Moment format="YYYY/MM/DD">
                                                  {convenio.endDate}
                                                  </Moment>
                                                  </td>
                                                  <td>{(convenio.status).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                  <td onClick={()=>{this.handleModaleditconvenio();this.seleccionarconvenio(convenio)}}> <BtnEdit  /> 
                                                  </td>       
                                                </tr>
                                        
                                                  )
                                                 }
                                               )}                
                                              </tbody>

                                            </table>

                                            <span>{this.state.pager_infoconvenio}</span>
                                                <nav className="nav">
                                                
                                                    <ul className="pagination">
              
                                                        {this.state.Pager_Convenios.map(page_conve=>{
                                                        
                                                          var activo=page_conve.activo
                                                          if(activo==true){
                                                            activo='page-item active'
                                                          }else{
                                                            activo='page-item'
                                                          }
              
                                                         // alert(activo)
                                                          
                                                         return(                          
                                                                  
                                                                 <li className={activo}>
                                                                   <a className="nav-link" onClick={()=>{this.loadMoreDataconvenios(page_conve)}}>{page_conve.texto}</a>
                                                                 </li>                                            
                                                           )
                                                          }
                                                         )
                                                        }
              
                                                    </ul>
                                                          
                                                </nav>
                                          
                                        </div> 

                                        <div className="d-flex justify-content-center">                              
                                            <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                             
                                        </div> 

                                        <div className="d-flex justify-content-center">                  
                                            <h5 id="text_page"> <b>No hay información que mostrar</b> </h5>                          
                                        </div>                                    
                          
                                  </div>                        
                        
                                  <div className="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                                                                            
                                
                                      <div className="row" id="div_convenio1">
                          
                                          {/* <div className="col-md-6">
                                          
                                                  <div className="form-group">                    
                                                              <label >Profesional:</label>
                                                              <Profesionales handleridprofesional={this.handleridprofesional} />                       
                                                  </div>
                          
                                          </div> */}

                                          <div className="col-md-3">
                                                  <div className="form-group">                    
                                                          <label >Tipo ID:</label>                                                          

                                                          <select onChange={this.GettipoID}  className="form-control" id="id_tipoid" name="id_tipoid">
                    
                                                              <option  value="null"> Seleccione...</option> ,
                                                              {this.state.Tipo_id.map(item=>                              
                                                              <option key={item.code} value={item.code}>  {item.description ? (item.description).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }  </option>  
                                                              )}
                                                                    
                                                          </select>                                                 
                                                  </div>                              
                                          </div>

                                          <div className="col-md-3">            
                                                  <div className="form-group">                    
                                                          <label >Identificación:</label>
                                                          <input type="text" maxLength="10" className="form-control camponum" id="id_pacie" data-toggle="tooltip" data-placement="top" title="Presione la tecla Enter o Tab para consultar" placeholder="Identificación" name="identificacion_med" onChange={this.handleChange} onKeyDown={this._handleKeyDown} />                                                          
                                                  </div>                        
                              
                                          </div>

                                          <div className="col-md-6">
                                                   <div className="form-group">                    
                                                        <label >Nombre del profesional:</label>
                                                        <input type="text" className="form-control" id="nom_pacien"  placeholder="Nombre" name="nom_pacien" value={form.nom_med} readOnly/>                       
                                                   </div>
                                          </div>                                         
                          
                                      </div>                                
                                            
                                      <div className="row" id="div_convenio2">

                                          <div className="col-md-3">
                                                 
                                                 <div className="form-group">                    
                                                         <label >Fecha inicio:</label>
                                                         <input type="date" className="form-control" id="fecha_ini" name="fecha_ini" onChange={this.handleChange}/>            
                                                 </div>
                                          </div>
                     
                                          <div className="col-md-3">
                                                
                                                 <div className="form-group">                    
                                                         <label >Fecha terminación:</label>
                                                         <input type="date" className="form-control" id="fecha_fin" name="fecha_fin" onChange={this.handleChange}/>               
                                                 </div>
                                          </div>

                                          <div className="col-md-3">                                 
                                              <div className="form-group">                    
                                                <label >Especialidad:</label>
                                                <select onChange={this.GetEspecialidad}  className="form-control" id="espe" name="espe">
                                                    <option  value="0"> Seleccione...</option> ,
                                                    {this.state.Especialidades.map(item=>                              
                                                    <option key={item.id} value={item.id}> {(item.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                    )}
                                                            
                                                </select>                      
                                              </div>
                                          </div>                      
                                                                                                    
                                          <div className="col-md-3">                                                           
                                                  <div className="form-group">                    
                                                            <label >Tipo de servicio:</label>
                                                            <TypeService handleridservi={this.handleridservi}/>                        
                                                  </div>              
                                          </div>                                                          
                      
                                      </div>


                                      <div className="row" id="div_convenio3">

                                          <div className="col-md-6" id="cb_servicioo">                                 
                                              <div className="form-group">                    
                                                        <label >Servicio:</label>
                                                        <Servicios_cobro handleridservicobro={this.handleridservicobro} />                       
                                              </div>
                                          </div>          
  
                                          <div className="col-md-6">                
                                              {/*<div className="form-group">    

                                                  <label >Firma médico:</label>
                                                  <div className="custom-file">
                                                    <input type="file" name="selectedFile" id="exampleInputFile"  accept="image/jpg,image/jpeg,image/bmp,image/png" onChange={i => this.GetImge(i)}/>
                                                    <label className="custom-file-label" for="exampleInputFile" >{form.selectedFile} {firma}</label>
                                                  </div>
                                                                       
                                              </div> */}
                                              <label >Firma médico:</label>
                                              <div className="custom-file">
                                                  
                                                  <input type="file" name="selectedFilep" id="exampleInputFilep" className="inputfile inputfile-7" accept="image/jpg,image/jpeg,image/bmp,image/png" onChange={ip => {this.GetImg_portada(ip)}}/>
                                                
                                                  <label htmlFor="file-7" for="exampleInputFilep">
                                                    <figure>
                                                    <img src={iconofirma} width="43"/>                                                  
                                                    </figure>                                                  
                                                    <span className="iborrainputfile">{num_filep}</span>
                                                  </label>
                                                  
                                              </div>
                                                                           
                                          </div>                                                                                   
                            
                                      </div>

                            
                                      <div className="row" id="div_convenio5">
                      
                                          <div className="col-md-6">
                                               
                                              <div className="card table-responsive">                                            
                                                
                                                    <table id="tabla_convenios" className="table table-bordered table-striped table-hover">
                                                      <thead>
                                                        <tr>
                                                          <th>ID</th>
                                                          <th>Tipo de servicio</th> 
                                                          {/*<th>Borrar</th>*/}        
                                                          
                                                          
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                          
                                                           
                                                           <tr>          
                                                             <td>{this.state.Type_servicenew.id}</td>                                  
                                                             <td>{this.state.Type_servicenew.name}</td> 
                                                             {/*<td onClick={()=>{this.Borrar_servicio(this.state.Type_servicenew.ide)}}> <BtnDelete  />  </td> */}                           
                                                           </tr>
                                                           
                                                      </tbody>
                                                    </table>
                                                
                                              </div>                                           
                                           
                        
                                          </div>
                      
                                          <div className="col-md-6">
                                               
                                                <div className="card table-responsive">
                                        
                                                      <table id="tabla_convenios" className="table table-bordered table-striped table-hover">
                                                        <thead>
                                                          <tr>
                                                            <th>ID</th>
                                                            <th>Especialidad</th>
                                                            <th>Servicio</th>  
                                                            {/*<th>Borrar</th>*/}       
                                                            
                                                            
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                           {this.state.Servi_convenionew.map(servi_convenio=>{
                                                            
                                                             return(      
                                                             
                                                             <tr> 
                                                               <td>{servi_convenio.id}</td>         
                                                               <td>{(servi_convenio.specialty.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>                                  
                                                               <td>{(servi_convenio.service.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                               {/*<td onClick={()=>{this.Borrar_especialidad(servi_convenio)}}> <BtnDelete />  </td>  */}                           
                                                             </tr>
                                                     
                                                                )
                                                               }
                                                             )
                                                           }                
                                                        </tbody>
                                                      </table>
                                                 
                                              </div>                     
                                          
                                          </div>
                      
                                      </div>                   
      
                                      <div className="modal-footer"> 

                                          <div id="div_convenio4">
                                              <button id="btn_save"  className="btn btn-save"  disabled={!this.state.active_button} onClick={()=> this.Validar_form_coonvenio()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar
                                                <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                   <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                   <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                                </svg>
                                              </button> 
                                          </div>
                                              <button  className="btn btn-primary"  onClick={()=>{this.handleDeshacerFormulario()}} > Nuevo
                                                   <svg width="1.2em" height="1.2em" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                   </svg>
                                              </button>
                                          </div>              
                                   
                                  </div>  
                   
                              </div>

                          </div>
          
                    </div>

              </div>



              <div className="modal fade" id="modal-newconvenio">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Crear Convenio</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      
                                        
                   
                    </div>
                    <div className="modal-footer justify-content-between">        
                      <button type="button" className="btn btn-primary">Guardar</button>
                      <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>
              {/* /.modal */}



              <div className="modal fade" id="modal-editconvenio">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">

                      <div className="modal-header">
                        <h4 className="modal-title"><b>Editar Convenio</b></h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>

                      <div className="modal-body">
            
                          <div className="row">
            
                                <div className="col-md-1">               
                                       <div className="form-group">                    
                                                   <label >ID:</label>
                                                   <input type="text" className="form-control" id="id_conve_edit" name="id_conve_edit" value={form.id_conve_edit} readOnly />                      
                                       </div>                 
                                </div>
                  
                                <div className="col-md-5">
                              
                                        <div className="form-group">                    
                                                    <label >Profesional:</label>
                                                    <input type="text" className="form-control" id="nom_medico_edit" name="nom_medico_edit" value={form.nom_medico_edit +' '+ form.nom2_medico_edit +' '+ form.apell1_medico_edit +' '+ form.apell2_medico_edit} readOnly />                                                          
                                        </div>
                  
                                </div>
            
                                <div className="col-md-3">
                                        <div className="form-group">                    
                                                <label >Fecha inicio:</label>
                                                <input type="date" className="form-control" name="fecha_ini_edit" onChange={this.handleChange} value={form.fecha_ini_edit}/>
                                        </div>
                    
                                </div>
                                
                                <div className="col-md-3">
                
                                    <div className="form-group">                    
                                            <label >Fecha terminación:</label>
                                            <input type="date" className="form-control" name="fecha_fin_edit" onChange={this.handleChange} value={form.fecha_fin_edit}/>
                                    </div>
                
                                </div>
            
                         
            
                          </div> 
                           
                                    
                          <div className="row">

                                  <div className="col-md-6">               
                                         <div className="form-group">                    
                                                     <label >Tipo de servicio:</label>
                                                     <input type="text" className="form-control" id="nombre_tiposervicio" name="nombre_tiposervicio" value={form.name_tipo_servi} readOnly />                      
                                         </div>                 
                                  </div>

                                  <div className="col-md-3" id="select_espe_edit">                                 
                                      <div className="form-group">                    
                                        <label >Especialidad:</label>
                                        <select onChange={this.GetEspecialidadedit}  className="form-control" name="espe_edit" id="espe_edit">
                                            <option  value="0"> Seleccione...</option> ,
                                            {this.state.Especialidades.map(item=>                              
                                            <option key={item.id} value={item.id}> {(item.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                            )}
                                                    
                                        </select>                      
                                      </div>
                                  </div>
                                 
                                  {/*<div className="col-md-3">                                                           
                                          <div className="form-group">                    
                                                    <label >Tipo de servicio:</label>
                                                    <TypeService handleridservi={this.handleridservi}/>                        
                                          </div>              
                                  </div> */}                                 
                                  
  
                                  <div className="col-md-3" id="select_servi_edit">                                 
                                      <div className="form-group">                    
                                                <label >servicio:</label>
                                                <Servicios_cobro handleridservicobro={this.handleridservicobro}/>                       
                                      </div>
                                  </div>          
                                                   
                                 
                          </div>
            
            
                          <div className="row">
            
                              {/*<div className="col-md-6">
                                   
                                 <div className="card table-responsive">
                          
                                     <table id="tabla_convenios" className="table table-bordered table-striped">
                                       <thead>
                                         <tr>
                                           <th>ID</th>
                                           <th>Tipo de servicio</th>         
                                           <th>Borrar</th>
                                           
                                         </tr>
                                       </thead>
                                       <tbody>
                                             
                                            
                                            <tr>          
                                              <td>{this.state.Type_services.id}</td>                                  
                                              <td>{this.state.Type_services.name}</td>                             
                                              <td onClick={()=>{this.Borrar_servicio(this.state.Type_services.id)}}> <BtnDelete  />  </td>
                                            </tr>
                                              
                                       </tbody>
                                     </table>
                                   
                               </div>                                   
            
                              </div> */}
            
                              <div className="col-md-12">
                                   
                                  <div className="card table-responsive">                        
                                  
                                     <table id="tabla_convenios" className="table table-bordered table-striped">
                                       <thead>
                                         <tr>
                                           <th>ID</th>
                                           <th>Especialidad</th>
                                           <th>servicio</th>         
                                           <th>Borrar</th>
                                           
                                         </tr>
                                       </thead>
                                       <tbody>
                                          {this.state.Servi_convenio.map(servi_convenio=>{
                                            //id_especialidad_edit=servi_convenio.specialty.id
                                            id_servicobro=servi_convenio.service.id
                                            var btn_delete=<td></td>
                                            if(estado_conv=='activo'){
                                              btn_delete=<td onClick={()=>{this.Borrar_especialidad(servi_convenio)}}> <BtnDelete />  </td>
                                            }else{

                                              btn_delete=<td></td>

                                            }
                                            
                                            return(      
                                            
                                            <tr> 
                                              <td>{servi_convenio.id}</td>         
                                              <td><h6>{(servi_convenio.specialty.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</h6></td>                                  
                                              <td><h6>{(servi_convenio.service.name).toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</h6></td>                             
                                              {btn_delete}
                                            </tr>
                                    
                                               )
                                              }
                                            )
                                          }                
                                       </tbody>
                                     </table>
                                   
                                  </div>
                               
                              </div>
            
                            </div> 

                            <form>
  
                            <div className="row" >          
  
                                <div className="col-md-6" id="rowfirma">                
                                    <div className="form-group">                    
                                        <label >Firma médico:</label>
                                        <div className="custom-file">
                                          <input type="file" name="selectedFile" id="exampleInputFile" onChange={i => this.GetImge(i)}/>
                                          <label className="custom-file-label" for="exampleInputFile" >{form.selectedFile} {firma}</label>
                                        </div>
                                        <button type="button"  id="btn_save"  className="btn btn-save"  disabled={!this.state.active_button_firma} onClick={()=> this.subir_firma()}> {this.state.loading_button_firma?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Subir firma </button>
                                                             
                                    </div>
                                                                 
                                </div>                     
                                      
                                <div className="col-md-3"> 

                                    <div className="form-group">
                                        <label >Imagen:</label>                         
                                        <img src={getfirma} width= "150" className="rounded" />                                                                  
                                    </div>

                                </div>                         
                            
                            </div> 

                            </form>
  
                      </div>
            
                      <div className="modal-footer justify-content-between">        
                         <button type="button" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.editconvenios()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar </button>
                         <button type="button" id="btn_save" className="btn btn-save" onClick={()=> this.handleModalAnularconvenio()}>Anular</button>
                         <button type="button" className="btn btn-danger" onClick={()=> this.handleModaleditconveniodrop} data-dismiss="modal">Cerrar</button>
                      </div>

                  </div>
               
                </div>
               
              </div>


              <div className="modal fade" id="modal-verificahorario" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-12 modal-title text-center" id="correo_activacion"><b>Atención</b></h4>
                     
                    </div>
    
                    <div className="modal-body">

                          
                          {/* /<span id="alert-recoverypass"> </span> */}
                      
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 

                              <div className="col-md-12">

                                 Aún no se han creado los servicios de telemedicina que el prestador quiere brindar, estos deben crearse para que puedan ser asociados a los convenios de los médicos. Los servicios definen el concepto y el valor a cobrar por las consultas realizadas, por eso son fundamentales en la configuración de la cuenta. 
                                 <br/><br/>
                                 <a id="btn-registrarse" href="/servicios">  Ingresa aquí </a> &nbsp;
                                 para crear los servicios del prestador.

                              </div>
                                                  
                          
                          </div>

                          <br/>
                       
                             
                              
                    </div> 
    
                   

                  </div>
                  
                </div>
                
              </div>

              <div className="modal fade" id="modal-contrato" data-backdrop="static">

                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Atención</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 

                              <div className="col-md-12">

                                 El tipo de servicio seleccionado para crear el convenio, no puede ser usado sin que exista contrato con una EPS, &nbsp; <a id="btn-registrarse" href="/contratos">  ingresa aquí </a> &nbsp;
                                 para crear uno.

                              </div>
                                                  
                          
                          </div>
                                 
                    </div>
          
                        
                  </div>                
                </div>
            
              </div>

              <div className="modal fade" id="modal-contrato-particular" data-backdrop="static">

                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Atención</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 

                              <div className="col-md-12">

                                 El tipo de servicio seleccionado para crear el convenio, no puede ser usado sin que exista contrato Particular, &nbsp; <a id="btn-registrarse" href="/contratos">  ingresa aquí </a> &nbsp;
                                 para crear uno.

                              </div>
                                                  
                          
                          </div>
                                 
                    </div>
          
                        
                  </div>                
                </div>
            
              </div>


              <div className="modal fade" id="modal-llaveswompi" data-backdrop="static">

                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Llaves asociadas a Wompi</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 
                              <div className="col-md-12">
                                Debe integrar las llaves de autenticación de WOMPI generadas para su comercio. Estas llaves son la forma en la que se identifica el comercio a la hora de procesar transacciones.
                                  <br></br>
                                  <br></br>
                                Si todavía no tiene las llaves de autenticación, regístrese en <a id="btn-registrarse" href="https://comercios.wompi.co/" target="_blank">  comercios.wompi.co </a>  y obtenga sus llaves en segundos. Consulte el instructivo de autogestión para crear una cuenta en WOMPI pulsando  <a id="btn-registrarse" href="https://soporte.wompi.co/hc/es-419/articles/4402327655699-Pasos-para-crear-el-registro-en-la-pasarela-de-pagos-Wompi" target="_blank"> aquí. </a>
                                  <br></br>
                                  <br></br>
                                Recuerde que debe registrar su comercio bajo el modelo GATEWAY, seleccionando el plan AVANZADO.
                              </div>                                               
                          
                          </div>                               
                    </div>
                    <div className="modal-footer justify-content-between">
                    <a  href="/perfil" >  <button type="button" className="btn btn-primary"   > Integrar llaves</button> </a> 
                     
                    </div>
          
                        
                  </div>                
                </div>
          
              </div>


              <div className="modal fade" id="modal-eliminar_especialidad" data-backdrop="static">

                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Eliminar Especialidad</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
  
                            ¿Seguro que deseas eliminar la especialidad?
  
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=> this.seleccionarserviconvenio()}>Si</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">No</button>
                          </div>
                  </div>                
                </div>
            
              </div>


              <div className="modal fade" id="modal-eliminar_servicio" data-backdrop="static">

                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Eliminar Servicio</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
  
                            ¿Seguro que deseas eliminar el servicio?
  
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=> this.seleccionartypeservice()}>Si</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">No</button>
                          </div>
                  </div>                
                </div>
            
              </div>


              <div className="modal fade" id="modal-anular_convenio" data-backdrop="static">

                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Importante</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
  
                            ¿Seguro que deseas anular el convenio?
  
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=> this.Anular_convenio()}>Si</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">No</button>
                          </div>
                  </div>                
                </div>
            
              </div>


              <div className="modal fade" id="modal-deshacerform" data-backdrop="static">

                <div className="modal-dialog modal-md">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Importante</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
  
                            Tienes datos seleccionados en este formulario, ¿Seguro que deseas deshacerlos?
  
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=> this.deshacerform()}>Si</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">No</button>
                          </div>
                  </div>                
                </div>
            
              </div>

              <div className="modal fade" id="modal-guardar_convenio" data-backdrop="static">

                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Importante</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
  
                            ¿Seguro que deseas guardar el convenio?
  
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=> this.saveconvenios()}>Si</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">No</button>
                          </div>
                  </div>                
                </div>
            
              </div>



        </div>      
      
    )

  }
  
}


export default Convenios;