import React, {Component} from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import fileDownload from 'js-file-download';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import * as ReactBootStrap from 'react-bootstrap';
import emptypage from '../tools/images/carpeta.gif';
import BtnVer from '../components/BtnVer';
import Buscar from '../tools/images/Buscar';
import '../tools/css/Home.css';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

const ApiURL="https://medicallapi.azurewebsites.net/";

var mensaje='';
var buscar_consulta='';
var id_consulta=0;


class Consultas_urg extends Component {

  constructor()
    {
     super()
      this.state={
        Consulta: [],
        Pager_Consultas: [],
        pager_infoconsultas:'',
        buscar_consultas:'',
        loading: false,
        loading_recurso:false,
        loading_button: true,
        active_button: true,
        Historia:[],
        diagnostico:[],
        form:{ 
          idconsulta:'',
          nom_paciente:'',
          nom2_paciente:'',
          apell1_paciente:'',
          apell2_paciente:'',
          id_paciente:'',
          eps:'',
          nom_medico:'',
          nom2_medico:'',
          apell1_medico:'',
          apell2_medico:'',
          eps:'',
          fecha_consulta:new Date,           
          }        

      }
    }
    

    handleModalconsultashow()
    {      
      $('#modal-consulta').modal('show'); // <- to show modal datos de la consulta       
    }

    handleModalrecurso()
    { 
        $('#modal-loader').modal('show'); // <- to show modal descarga video   
    }

    handleModalrecursodrop()
    {  
      $('#modal-loader').modal('hide'); // <- to hide modal descarga video 
      if(this.state.loading_recurso==true){
        $('#modal-loader').modal('hide'); // <- to hide modal descarga video
      }   
          
    }

    empty_page_hide(){
      $('#tabla1').hide();     
      $('#empty_page').hide();
      $('#text_page').hide();
    }
    
    BuscarConsulta = (bc) =>{
      this.setState({buscar_consultas:bc.target.value})
      console.log(bc.target.value)
      buscar_consulta=(bc.target.value)
      this.searchDataconsulta(buscar_consulta)
     
    }


    handlePagependientes = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * this.state.perPage;

      this.setState({
          currentPage: selectedPage,
          offset: offset
      }, () => {
          this.loadMoreDatapedientes()
      });

  };


  loadMoreDatapedientes() {
    const data = this.state.orgtableData;
    
    const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      Consulta:slice
    })
  
    }


    componentDidMount(){

      this.ListarConsultas();
      
    }

    ListarConsultas(){
      this.empty_page_hide();

      this.setState({ loading: this.state.loading = false })

      axios.get(ApiURL+"/api/Consultations/ListUrgency")
      .then((response) => {
        console.log(response);
        
        this.setState({Consulta: response.data.data })  
        this.setState({Pager_Consultas: response.data.pager.items})
        this.setState({pager_infoconsultas: response.data.pager.showData}) 

        if(this.state.Consulta.length==0){
          $('#tabla1').hide();
          $('#empty_page').show();
          $('#text_page').show();
        }else{
          $('#tabla1').show();
          $('#empty_page').hide();
          $('#text_page').hide();
        }
        
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      }) 

    }

    loadMoreDataconsultas(page_consulta) {
      var page=page_consulta.pagina
      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/Consultations/ListUrgency?numeropagina="+page)
      .then((response) => {
        console.log(response);

        this.setState({Consulta: response.data.data})  
        this.setState({Pager_Consultas: response.data.pager.items})
        this.setState({pager_infoconsultas: response.data.pager.showData}) 
      
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    
      }

      searchDataconsulta(buscar_consulta) {

        this.setState({ loading: this.state.loading = false })
        
        axios.get(ApiURL+"/api/Consultations/ListUrgency?texto="+buscar_consulta)
        .then((response) => {
          console.log(response);
  
          this.setState({Consulta: response.data.data})  
          this.setState({Pager_Consultas: response.data.pager.items})
          this.setState({pager_infoconsultas: response.data.pager.showData}) 
        
          this.setState({ loading: this.state.loading = true })
        })
        .catch((error) => {
          console.log(error);
        })
      
        }


        seleccionarconsulta=(consultas)=>{

          id_consulta=consultas.id
          this.setState({ Historia:consultas.consultationData })
          //this.saverecurso(id_consulta);

          this.setState({
              form:{
              idconsulta:consultas.id, 
              id_paciente:consultas.patient.user.typeId +'-'+ consultas.patient.user.identification,
              nom_paciente: consultas.patient.user.name1 ? consultas.patient.user.name1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
              nom2_paciente: consultas.patient.user.name2 ? consultas.patient.user.name2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
              apell1_paciente: consultas.patient.user.lastname1 ? consultas.patient.user.lastname1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
              apell2_paciente: consultas.patient.user.lastname2 ? consultas.patient.user.lastname2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
              eps:consultas.patient.eps.name.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
              nom_medico: consultas.professional.user.name1 ? consultas.professional.user.name1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))  : '',  
              nom2_medico: consultas.professional.user.name2 ? consultas.professional.user.name2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',               
              apell1_medico : consultas.professional.user.lastname1 ? consultas.professional.user.lastname1.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
              apell2_medico : consultas.professional.user.lastname2 ? consultas.professional.user.lastname2.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''
            }
          })
          //var  dato  = solicitud.user.identification;
          //alert(Historia);
        }



        saverecurso=async()=>{
        

          this.setState({ loading_recurso: this.state.loading_recurso = false })
          $('#modal-loader').modal('show') // <- to show modal descarga video
          $('#modal-consulta').modal('hide') // <- to show modal descarga video

          await axios.get(ApiURL+"/api/Consultations/GetVideoResource/"+id_consulta,{            
            
            responseType:'blob'

          })  
      
          .then(response=>{
            
            fileDownload(response.data,'Consultation-'+id_consulta+'.mp4')

            this.setState({ loading_recurso: this.state.loading_recurso = true })
            $('#modal-loader').modal('hide'); 
            
            store.addNotification({
              title: 'Mensaje',
              message: "El video ha sido descargado",
              type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })           
          })
          .catch((error)=>{

            if (error.response) {

            console.log(error.response);
            mensaje=error.message;                    

            store.addNotification({
              title: 'Atención',
              message: "El video se está generando, intente la descarga más tarde",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })            
            
            this.setState({ loading_recurso: this.state.loading_recurso = true })
            this.handleModalrecursodrop(); 
  
          } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
          } else {
              // Something happened in setting up the request and triggered an Error
              console.log('Error', error.message);
          }
          //console.log(error.config);                       
            
        })
      
      
       }


       descargar_consulta=async()=>{        

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })
        //$('#modal-loader').modal('show') // <- to show modal descarga video
        //$('#modal-consulta').modal('hide') // <- to show modal descarga video

        await axios.get(ApiURL+"/api/Consultations/PrintConsultation/"+id_consulta,{            
          
          responseType:'blob'

        })  
    
        .then(response=>{
          
          fileDownload(response.data,'Consultation-'+id_consulta+'.pdf')
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const objectUrl = window.URL.createObjectURL(blob)
          window.open(objectUrl)
          
          store.addNotification({
            title: 'Mensaje',
            message: "El documento ha sido descargado",
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })  
          
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          //this.handleModalarecurdodrop();
        })
        .catch((error)=>{

          if (error.response) {

          console.log(error.response);
          mensaje=error.message;                    

          store.addNotification({
            title: 'Atención',
            message: "No se encontró información",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })            
          
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          //this.handleModalrecursodrop(); 

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);                       
          
      })
    
    
     }

     descargar_formula=async()=>{        

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })
      //$('#modal-loader').modal('show') // <- to show modal descarga video
      //$('#modal-consulta').modal('hide') // <- to show modal descarga video

      await axios.get(ApiURL+"/api/Consultations/PrintMedicalPrescription/"+id_consulta,{            
        
        responseType:'blob'

      })  
  
      .then(response=>{
        
        fileDownload(response.data,'Formula-'+id_consulta+'.pdf')
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const objectUrl = window.URL.createObjectURL(blob)
        window.open(objectUrl)
 
        
        store.addNotification({
          title: 'Mensaje',
          message: "El documento ha sido descargado",
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })  
        
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
        //$('#modal-loader').modal('hide');
      })
      .catch((error)=>{

        if (error.response) {

        console.log(error.response);
        mensaje=error.message;                    

        store.addNotification({
          title: 'Atención',
          message: "No se encontró información",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })            
        
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
        //this.handleModalrecursodrop(); 

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);                       
        
    })
  
  
   }


   descargar_orden=async()=>{        

    this.setState({ loading_button: this.state.loading_button = false })
    this.setState({ active_button: this.state.active_button = false })
    //$('#modal-loader').modal('show') // <- to show modal descarga video
    //$('#modal-consulta').modal('hide') // <- to show modal descarga video

    await axios.get(ApiURL+"/api/Consultations/PrintMedicalOrder/"+id_consulta,{            
      
      responseType:'blob'

    })  

    .then(response=>{
      
      fileDownload(response.data,'Orden-'+id_consulta+'.pdf')
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const objectUrl = window.URL.createObjectURL(blob)
      window.open(objectUrl)
      
      store.addNotification({
        title: 'Mensaje',
        message: "El documento ha sido descargado",
        type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 10000,
          showIcon:true
        }
      })    
      
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
      //$('#modal-loader').modal('hide'); 

    })
    .catch((error)=>{

      if (error.response) {

      console.log(error.response);
      mensaje=error.message;                    

      store.addNotification({
        title: 'Atención',
        message: "Faltan datos por ingresar",
        type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 10000,
          showIcon:true
        }
      })            
      
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
      //this.handleModalrecursodrop(); 

    } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
    } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
    }
    //console.log(error.config);                       
      
  })


 }
       

    render(){ 
      const {form} = this.state; 

     return (

     
        <div className="content-wrapper">


            <div className="col-12 col-sm-12">

                <div className="row">
          
                    <div className="col-sm-10">
                    <br></br>
                       <div className="text-center" id="title"> <h6> <b> CONSULTA POR URGENCIAS  </b>  </h6> </div>
          
                    </div>          
                   
          
                </div>

                <div className="card card-primary card-outline card-tabs">                
  
                    <div className="card-body">

                          <div class="d-flex justify-content-center">
                                                
                              {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                                
                          </div>
  
                          <div className="card table-responsive" id="tabla1">                             

                              <div className="row">
                          
                                    <div className="col-sm-3">
                                          <div className="input-group input-group-sm mb-3 mr-sm-2">
                                             <div className="input-group-prepend">
                                               <div className="input-group-text"><Buscar/></div>
                                             </div>
                                             <input type="search" className="form-control" placeholder="Buscar" name="buscar_consultas" onChange={this.BuscarConsulta}/>
                                          </div>                                
                                    </div>
                                    
                              </div>                       
                          
                    
                              
                                <table className="table table-bordered table-striped">
                                  <thead>
                                    <tr>
                                      <th>N°</th>
                                      <th>Paciente</th>
                                      <th>Identificación</th>
                                      <th>Entidad</th>
                                      <th>Profesional</th>
                                      <th>Fecha Consulta</th>
                                      <th>Calificación</th>
                                      <th>Estado</th>
                                      <th>Ver</th>          
                                    </tr>
                                  </thead>
                            
                                  <tbody>
                                                  
                                      {this.state.Consulta.map(consultas=>{
                                        var tipo_consulta=consultas.accessRequestId
                                        var estado=consultas.status
                                        if(tipo_consulta!==null){
             
                                          if(estado=='finalizada'){
                                            estado=<td  onClick={()=>{this.handleModalconsultashow();this.seleccionarconsulta(consultas)}}><BtnVer  /></td>

                                          }else{
                                            estado=''
                                          }
                                        
                                        return(      
                                        
                                        <tr>          
                                          <td>{consultas.id}</td>              
                                          <td>{consultas.patient.user.name1 ? (consultas.patient.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {consultas.patient.user.name2 ? (consultas.patient.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { consultas.patient.user.lastname1 ? (consultas.patient.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {consultas.patient.user.lastname2 ? (consultas.patient.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}</td>
                                          <td>{consultas.patient.user.typeId} - {consultas.patient.user.identification}</td>
                                          <td>{(consultas.patient.eps.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                          <td>{consultas.professional.user.name1 ? (consultas.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {consultas.professional.user.name2 ? (consultas.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {consultas.professional.user.lastname1 ? (consultas.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {consultas.professional.user.lastname2 ? (consultas.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}</td>
                                          <td> 
                                          <Moment format="YYYY/MM/DD HH:mm">
                                          {consultas.date}
                                          </Moment>
                                          </td>
                                          <td>{consultas.score}</td>
                                          <td>{(consultas.status).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                          {estado}    
                                                   
                                        </tr>
                                
                                          )
                                        }else{

                                        }
                                         }
                                        )}
                                      
                                                        
                                      </tbody>
                                 
                                </table>

                                <span>{this.state.pager_infoconsultas}</span>
                                
                                      <nav className="nav">
                                      
                                          <ul className="pagination">
              
                                              {this.state.Pager_Consultas.map(page_consulta=>{
                                              
                                                var activo=page_consulta.activo
                                                if(activo==true){
                                                  activo='page-item active'
                                                }else{
                                                  activo='page-item'
                                                }
              
                                               // alert(activo)
                                                
                                               return(                          
                                                        
                                                       <li className={activo}>
                                                         <a className="nav-link" onClick={()=>{this.loadMoreDataconsultas(page_consulta)}}>{page_consulta.texto}</a>
                                                       </li>                                            
                                                 )
                                                }
                                               )
                                              }

                                          </ul>
                                                
                                      </nav>                  
                           
                          </div>

                          <div className="d-flex justify-content-center">                              
                                <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                             
                          </div>
                          <div className="d-flex justify-content-center">                  
                               <h5 id="text_page"> <b>No hay información que mostrar</b> </h5>                          
                          </div>
  
                    </div>
                   
                </div>

            </div>



            <div className="modal fade" id="modal-consulta">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Detalle de consulta</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>

                        <div className="modal-body">                         
                             
                            <div className="row">
                                
                                <div className="col-md-2">
                                      <div className="form-group">                    
                                        <label >ID:</label>
                                        <input type="text" className="form-control" name="id_consulta" value={form.idconsulta} readOnly />                      
                                      </div>                 
                                </div>
                    
                                <div className="col-md-4">
                                      <div className="form-group">                    
                                        <label >ID Paciente:</label>
                                        <input type="text" className="form-control" name="id_paciente" value={form.id_paciente} readOnly />                      
                                      </div>                 
                                </div>

                                <div className="col-md-6">
                                      <div className="form-group">                    
                                        <label >Paciente:</label>
                                        <input type="text" className="form-control" name="nom_paciente" value={form.nom_paciente + ' '+ form.nom2_paciente + ' '+ form.apell1_paciente + ' '+ form.apell2_paciente} readOnly />                      
                                      </div>                 
                                </div>
                    
                            </div>

                            <div className="row">
                                
                                <div className="col-md-6">              
                                    <div className="form-group">                    
                                            <label >Médico:</label>
                                            <input type="text" className="form-control" name="nom_medico" value={form.nom_medico + ' '+ form.nom2_medico + ' '+ form.apell1_medico + ' '+ form.apell2_medico} readOnly />
                                    </div>              
                                </div>
                                                  
                                <div className="col-md-6">
                                    <div className="form-group">                    
                                            <label >EPS:</label>
                                            <input type="text" className="form-control" name="fechafin_edit" value={form.eps} readOnly/>                    
                                    </div>                 
                                </div>                                                                
                                                                        
                            </div> 

                           

                                  {this.state.Historia.map(histo=>{
                                     return( 
                                       
                                                
                                          <div className="form-group">                    
                                                  <label >{histo.field}:</label>
                                                  <textarea className="form-control" rows="2" name="nom_medico" value={histo.value} readOnly> </textarea> 
                                          </div>              
                                    
                                   
                              
                                        )
                                       }
                                     )
                                  }              
                                                                                       
                          


                       
                        </div>

                        <div className="modal-footer justify-content-between">        
                              
                              <div className="btn-group dropright">
                                  <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={!this.state.active_button}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Imprimir
                                          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
                                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
                                          </svg>
                                  </button>
                                  <div className="dropdown-menu">
                                    <a className="dropdown-item" href="#" onClick={()=> this.descargar_consulta()}>Historia clinica</a>
                                    <a className="dropdown-item" href="#" onClick={()=> this.descargar_formula()}>Formula médica</a>
                                    <a className="dropdown-item" href="#" onClick={()=> this.descargar_orden()}>Orden médica</a>
                                  </div>
                              </div>
                              <button type="button" className="btn btn-success" onClick={()=> this.saverecurso()}> Descargar video
                                <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16">
                                  <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                                  <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
                                </svg>
                              </button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                        </div>

                  </div>
                 
                </div>
               
              </div>         


              <div className="modal fade" id="modal-loader" data-backdrop="static">
                <div className="modal-dialog modal-lg">
                  
                      <br/> <br/> <br/> <br/>
                 
                      <div class="d-flex justify-content-center">                                                
                            {this.state.loading_recurso?false: <ReactBootStrap.Spinner animation="grow"/>}                        
                      </div>
    
                      <div class="d-flex justify-content-center">                                                
                            <spam id="spamloader"> <h2>Descargando video</h2> </spam>                           
                      </div>                      
                  
                </div>

              </div>
            
        </div>      
      
    )

  }
  
}



export default Consultas_urg;