import React, {Component} from 'react';
import axios from 'axios';
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/ContratosAdd.css';
import '../tools/css/Home.css'
import TypeService from '../API/TypeService';
import BtnSave from '../components/BtnSave';
import BtnCancel from '../components/BtnCancel';
import BtnEdit from '../components/BtnEdit';

import emptypage from '../tools/images/carpeta.gif';
import Logo_nuevo from '../tools/images/Nuevo';
import Logo_edit from '../tools/images/Edit';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';


const ApiURL="https://medicallapi.azurewebsites.net/";
var mensaje='';
var id_servi='0';

class Consentimientos extends Component {

  constructor()
    {
     super()
      this.state={
        show:false,                
        Datos: [],
        loading: false,
        loading_button: true,
        active_button: true,
        form:{ 
          nameconsent: '',
          descrip: '',
           
          id_consen_edit:'',  
          nombre_consen_edit:'',   
          descrip_edit:''
          }
      }   
    
    }

    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);
         this.Selectdrop();
    }

    Selectdrop(){
      
      if( this.state.form.nameconsent!='' && id_servi==0 ){
        $("#servicio").val($('#servicio > option:first').val());        
      }

      if(this.state.form.descrip!='' && id_servi==0){
        $("#servicio").val($('#servicio > option:first').val());        
      }

         

    }

    empty_page_hide(){
      $('#tabla1').hide();     
      $('#empty_page').hide();
      $('#text_page').hide();
    }


    componentDidMount(){   

    }

    Listar_Consentimientos(){ 

      this.empty_page_hide(); 
      
      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Consents/List")
      .then((response) => {
        console.log(response);
        this.setState({Datos: response.data})

        if(this.state.Datos.length==0){
          $('#tabla1').hide();
          $('#empty_page').show();
          $('#text_page').show();
        }else{
          $('#tabla1').show();
          $('#empty_page').hide();
          $('#text_page').hide();
        }

        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    }

    seleccionarconsentiemineto=(consent)=>{
      this.setState({
        form:{
          id_consen_edit:consent.id,  
          nombre_consen_edit:consent.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),   
          descrip_edit:consent.description        
         
        }
      })
      //var  dato  = solicitud.user.identification;
      //alert(dato);
    }

    Limpiar_form=()=>{
      this.setState({
        form:{          
          nameconsent:'',
          descrip:''      
        }
      })
     
    }


    saveconsent=async()=>{

      if(this.state.form.nameconsent=='' || this.state.form.descrip=='' || id_servi==='0'){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ loading_button: this.state.active_button = false })

        await axios.post(ApiURL+"/api/Consents",
  
          {
          name: this.state.form.nameconsent,
          description: this.state.form.descrip,
          typeServiceId: id_servi
          } )  
  
        .then(response=>{
          console.log(response.data)
          //var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Mensaje',
            message: "El consentimiento se guardó",
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          $("#div_consen1").find("*").prop('disabled', true);
          $("#div_consen2").find("*").prop('disabled', true);
          $("#div_consen3").find("*").prop('disabled', true);

          this.Limpiar_form();
          
        })
        .catch(error=>{
            
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
      
      })

      }      
  
    }


    editconsent=async()=>{

      if(this.state.form.nombre_consen_edit==0 || this.state.form.descrip_edit==0){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ loading_button: this.state.active_button = false })

        await axios.put(ApiURL+"/api/Consents/"+this.state.form.id_consen_edit,  
        {
          id: this.state.form.id_consen_edit,
          name: this.state.form.nombre_consen_edit,
          description: this.state.form.descrip_edit
        } )          
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ loading_button: this.state.active_button = true })

          this.Listar_Consentimientos();

          $('#modal-consentimientoedit').modal('hide');
          
        })
        .catch(error=>{
          console.log(error)
          store.addNotification({
            title: 'Atención',
            message: 'Error al actualizar consentimiento !!!',
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ loading_button: this.state.active_button = true })
      
      })
        
      }      
  
    }
    

    handleModalconsentsoedit()
    {     
      $('#modal-consentimientoedit').modal('show'); // <- to show modal editar contrato 
    }
    

    handlerSelectdrop=async()=>{
      
      $("#servicio").val($('#servicio>option:first').val());
      $("#nameconsent").val("");
      $("#descrip").val("");
      $("#div_consen1").find("*").prop('disabled', false);
      $("#div_consen2").find("*").prop('disabled', false);
      $("#div_consen3").find("*").prop('disabled', false);
      
      id_servi='0';
     
    }

    handleridservi = (e) => {
      console.log(e)
      id_servi=(e)
      //alert(id_servi)
    }
       

    render(){  
      const {form} = this.state;

     return (

      <div className="content-wrapper">
   

            <div className="col-12 col-sm-12">
                <div className="row">
          
                    <div className="col-sm-10">           
                    <br></br>
                       <div className="text-center" id="title"> <h6> <b> CONSENTIMIENTO INFORMADO</b>  </h6> </div>
          
                    </div>  
          
                </div>
          
                <div className="card card-primary card-outline card-tabs">
                    <div className="card-header p-0 pt-1 border-bottom-0">
                      <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true" onClick={()=>{this.handlerSelectdrop()}}>Crear consentimiento <Logo_nuevo/>  </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false" onClick={()=>{this.Listar_Consentimientos()}}>Editar consentimientos <Logo_edit/></a>
                        </li>
                       
                       
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="tab-content" id="custom-tabs-three-tabContent">
                        <div className="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                          
                            <div className="card">            
                           
                  
                                <div className="row" id="div_consen1">
                      
                                    <div className="col-md-6">              
                                        <div className="form-group">                    
                                                <label >Nombre del consentimiento:</label>
                                                <input type="text" maxLength="80" className="form-control" placeholder="Nombre" name="nameconsent" id="nameconsent" onChange={this.handleChange}/>                       
                                        </div>              
                                    </div>
                                                      
                                    <div className="col-md-6">
                                        <div className="form-group">                    
                                          <label >Seleccione el consentimiento por tipo de servicio:</label>
                                          <TypeService handleridservi={this.handleridservi}/>                    
                                        </div>                 
                                    </div>                               
                                                      
                                                              
                                </div>
                              
                              
                                <div className="row" id="div_consen2">
                                                
                                    <div className="col-md-12">
                                        <div className="form-group">                    
                                            <label >Describa el consentimiento:</label>
                                            <textarea placeholder="Descripción del consentimiento" class="form-control"  rows="6" id="descrip" name="descrip" onChange={this.handleChange} ></textarea>                    
                                        </div>                 
                                    </div>                               
                                    
                                            
                                </div>
                  
                                <div className="modal-footer"> 

                                      <div id="div_consen3"> 

                                        <button id="btn_save"  className="btn btn-save" disabled={!this.state.active_button} onClick={()=> this.saveconsent()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar
                                          <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                             <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                             <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                          </svg>
                                        </button> 

                                      </div> 

                                        <button  className="btn btn-primary" onClick={()=> this.handlerSelectdrop()} > Nuevo
                                               <svg width="1.2em" height="1.2em" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                               </svg>
                                        </button>
                                </div>               
                    
                         
                            </div>                         
                
                        </div>
                        
                        <div className="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                          
                            <div class="d-flex justify-content-center">                                                
                                {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}                                                                  
                            </div>
                            
                            <div className="card table-responsive" id="tabla1">               
                
                                <table className="table table-bordered table-striped">
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>Nombre de consentiemiento</th>
                                        <th>Servicio</th>          
                                        <th>Editar</th>          
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.Datos.map(consent=>{
                                      return(
                              
                                      <tr>
                                        <td>{consent.id}</td>
                                        <td>{(consent.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                        <td>{(consent.typeService.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>               
                                        <td onClick={()=>{this.handleModalconsentsoedit();this.seleccionarconsentiemineto(consent)}}> <BtnEdit  />
                                        </td>
                                      </tr>
                                      )
                                     }
                                   )}
                                      
                                                     
                                    </tbody>

                                </table>                                   
                    
                            </div>

                            <div className="d-flex justify-content-center">                              
                                <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                             
                            </div>

                            <div className="d-flex justify-content-center">                  
                               <h5 id="text_page"> <b>No hay información que mostrar</b> </h5>                          
                            </div>
                  
                        </div>
                
                
                      </div>
                        
                       
                    </div>

                </div>
    
  
          </div>


          <div className="modal fade" id="modal-consentimientoedit">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Editar Consentiemiento</b></h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  
                      <div className="row">
          
                            <div className="col-md-2">
                                  <div className="form-group">                    
                                    <label >ID:</label>
                                    <input type="text" className="form-control" id="id_consen_edit" name="id_consen_edit" value={form.id_consen_edit} readOnly />                      
                                  </div>                 
                            </div>
          
                            <div className="col-md-10">
                                  <div className="form-group">                    
                                    <label >Nombre consentimiento:</label>
                                    <input type="text" className="form-control" id="nombre_consen_edit" name="nombre_consen_edit" onChange={this.handleChange} value={form.nombre_consen_edit} />                      
                                  </div>                 
                            </div>
          
                      </div>
                         
                      <div className="row">
                                       
                            <div className="col-md-12">
                               <div className="form-group">                    
                                   <label >Describción del consentimiento:</label>
                                   <textarea class="form-control"  rows="6" id="id_consen_edit" name="descrip_edit" onChange={this.handleChange} value={form.descrip_edit}  ></textarea>                    
                               </div>                 
                            </div>        
                                   
                      </div>            
          
          
                    </div>
          
                <div className="modal-footer justify-content-between">
                  <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.editconsent()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>
                  <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>        
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}


      </div>      
      
    )

  }
  
}


export default Consentimientos;