import React, {Component} from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment/locale/es';
import Sound from '../components/Sound';
import Home from '../pages/Home';
import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

import  * as signalR from '@aspnet/signalr';
import img from '../tools/images/logomedicall24horizontal.svg';
import '../tools/css/Navbar.css';

//var miString = 'visita la web de nube colectiva';
//miString=miString.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
//console.log(miString)


const ApiURL="https://medicallapi.azurewebsites.net/";
const Token =sessionStorage.getItem('token');

//permite validar el token para las conexiones con axios
axios.interceptors.request.use(
  config =>{
    config.headers.authorization = `Bearer ${Token}`;
    return config;
  },
  error =>{
    return Promise.reject(error);
  }
)
         

    class Navbar extends Component  {

      constructor()
    {
     super()
      this.state={
        show:false,       
        Notification: [], 
        acepta:'',
        SignalrMessage:'',
        msg1:'',
        msg2:'',
        num:'',
        estado_sonido:true,
       
        form:{   
        id_soli_acep:'',     
        id_pacie_acep:'',
        nom_pcte_acep:'',
        descrip:''        
        
        }
        
      }
    }


    SolicitudesURG(){

      const protocol = new signalR.JsonHubProtocol();
      const SignalRconnection = new signalR.HubConnectionBuilder()
      .withUrl(ApiURL+'/api/notifications', 
      {
          accessTokenFactory: () => Token
      }) 
      .withHubProtocol(protocol)    
      .build();
      this.setState({SignalRconnection}, () =>{
        this.state.SignalRconnection.start()
        .then(() =>console.log('Conexion iniciada'))
        .catch(err =>console.log('Error de Conexion signalR'))

        this.state.SignalRconnection.on("ShowNotification", (message) => {
         const SignalrMessage = message.split('-');
         const msg1=SignalrMessage[0];
         const msg2=SignalrMessage[1];
         const num=msg1.split('-').length;

         console.log( SignalrMessage);         
         this.setState({msg1});
         this.setState({msg2});
         this.setState({num});

        
         this.Notification(); 
         
         this.setState({estado_sonido:this.state.estado_sonido = true})

                  
         store.addNotification({
          title: msg1,
          message: msg2,
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })

        this.setState({ estado_sonido: this.state.estado_sonido = false })

      });
             
      })

    }


    componentDidMount(){  
    
      if(!sessionStorage.getItem('token')){
        window.location.href="/";
      }
    
    }



    Notification(){
      axios.get(ApiURL+"/api/Notifications/ListAll")
      .then((response) => {
        console.log(response);
        this.setState({Notification: response.data})
          
      })
      .catch((error) => {
        console.log(error);
      })      
   
    }

    
    
  render(){
        
    
    return(          
      
    <nav className="main-header navbar navbar-expand navbar-white navbar-light"> 
      
      {this.state.sound_notification}
     
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
        </li>
        <a  href="/home" className="navbar-brand"> <img src={img} alt="logo"  width= "200"/> </a>
       
      </ul>
      
        {/* SEARCH FORM */}
                    

        {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">

        <li className="nav-item dropdown">
          
          <a className="nav-link" href="https://medicall24.com.co/pqrs/"  target="_blank">
            
            <span className="fas fa-question-circle" id="link_pqrs" ><b>Ayuda</b></span>
                     
          </a>         

        </li>
    
        <li className="nav-item dropdown" onClick={()=>{this.Notification()}}>
          <a className="nav-link" data-toggle="dropdown" href="#">
            <i className="far fa-bell fa-lg" />
            <span className="badge badge-danger navbar-badge">{this.state.num}</span>
            
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <a href="#" className="dropdown-item">
              {/* Message Start */}
              <div className="media"> 
                 
                <div className="media-body">

                    <a href="#" className="dropdown-item dropdown-header">Notificaciones</a>
                    
                    <div className="card table-responsive" id="tabla_noti">

                          {this.state.estado_sonido?false:<Sound/>}
    
                          {this.state.Notification.map(noty=>{
                            return( 
                              <div>
                              <div id="hrNoty" className="dropdown-divider" />  
                              <p className="dropdown-item-title" id="titleNoty">
                              {noty.title}:
                              </p>                
                              <p className="text-md" id="messageNoty">
                              {noty.message}
                              </p>
                              <p id="messageNoty"><Moment fromNow locale='es'>
                                  {noty.date}
                                 </Moment>
                              </p>                    
                              </div>               
                              
                              )
                             }
                            )               
                          }
    
                    </div>
                      
                </div>
              </div>
              {/* Message End */}
            </a>
            <div className="dropdown-divider" />
           
            
          </div>

        </li>
    
      </ul>

      
    </nav>
    

        
    )
  }

}

export default Navbar;