import React, {Component,useState} from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import Moment from 'react-moment'

const ApiURL="https://medicallapi.azurewebsites.net/";

//Componente para boton agendar citas

class Calendar extends Component { 

  constructor()
    {
     super()
      this.state={      
        Cita:[]   
      }
    }

  
  
    componentDidMount(){    
    
      axios.get(ApiURL+"/api/Citas/ListAll")
      .then((response) => {
        console.log(response.data.data);
       
  
          const info = response.data.data
      
          const option = info.map(d => ({
            "title" :d.patient.user.name1,
            "date" : '2020-12-02'     
          }))
  
          console.log(option);
      
          this.setState({Cita: option})
      })
      .catch((error) => {
        console.log(error);
      })

    }     

    render(){
                
     
       return (         
         
       
          <FullCalendar
         
             
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              weekends={false}
              events={[  {             
                title:this.state.Cita,
              }                                                     
              ]}
          
          />     

        
      )
  
    }
    
  }  
  
  
  export default Calendar;