import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types'

//Component0 para listar en select los regimen de afiliación consumido en API

class Regimen extends Component {   

    constructor()
    {
     super()
      this.state={        
        regimen: [],
        id_regimen:''
      }
    }

    componentDidMount(){    
    
        axios.get("https://medicallapi.azurewebsites.net//api/Regimes")
        .then((response) => {
          console.log(response);
          this.setState({regimen: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
      }  
      
      
      handleChange = (e) =>{
        this.setState({id_regimen:e.target.value})
        //console.log(e.target.value)
        //var med=(e.target.value)
        //alert(med)
      }
      

    render(){
      
      const {handleridregimen} = this.props  
      const {id_regimen} = this.state
        
   
     return (   
     
        <div >

                <select onChange={this.handleChange} onClick={()=>handleridregimen(id_regimen)} value={this.state.id_regimen} className="form-control" id="regimen">
                    <option  value="null"> Seleccione...</option> ,
                    {this.state.regimen.map(item=>                              
                    <option key={item.id} value={item.id}> {item.nombre.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                    )}
                            
                </select>                    
         
        </div>    
      
    )

  }
  
} 


Regimen.propTypes = {
  handleridregimen:PropTypes.func.isRequired
}


export default Regimen;