import React, {Component} from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment/locale/es';
import Sound from './Sound';
import Home from '../pages/Home';
import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

import banner_platinium from '../tools/images/Banner membresía Platinum.svg'
import banner_golde from '../tools/images/Banner membresía golden.svg'
import banner_diamond from '../tools/images/Banner membresía diamond.svg'

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

import  * as signalR from '@aspnet/signalr';
import img from '../tools/images/logomedicall24horizontal.svg';
import '../tools/css/Navbar.css';


const ApiURL="https://medicallapi.azurewebsites.net/";
const Token =sessionStorage.getItem('token');

//permite validar el token para las conexiones con axios
axios.interceptors.request.use(
  config =>{
    config.headers.authorization = `Bearer ${Token}`;
    return config;
  },
  error =>{
    return Promise.reject(error);
  }
)
         

    class Navbar extends Component  {

      constructor()
    {
     super()
      this.state={
        show:false,       
        Notification: [], 
        acepta:'',
        SignalrMessage:'',
        msg1:'',
        msg2:'',
        num:'',
        sound_notification:false,
       
        form:{   
        id_soli_acep:'',     
        id_pacie_acep:'',
        nom_pcte_acep:'',
        descrip:''        
        
        }
        
      }
    }


    SolicitudesURG(){

      const protocol = new signalR.JsonHubProtocol();
      const SignalRconnection = new signalR.HubConnectionBuilder()
      .withUrl(ApiURL+'/api/notifications', 
      {
          accessTokenFactory: () => Token
      }) 
      .withHubProtocol(protocol)    
      .build();
      this.setState({SignalRconnection}, () =>{
        this.state.SignalRconnection.start()
        .then(() =>console.log('Conexion iniciada'))
        .catch(err =>console.log('Error de Conexion signalR'))

        this.state.SignalRconnection.on("ShowNotification", (message) => {
         const SignalrMessage = message.split('-');
         const msg1=SignalrMessage[0];
         const msg2=SignalrMessage[1];
         const num=msg1.split('-').length;

         console.log( SignalrMessage);         
         this.setState({msg1});
         this.setState({msg2});
         this.setState({num});

         this.setState({ sound_notification: this.state.sound_notification = true })
         this.Notification(); 
         //Home.SolicitudesPendientes();

                  
         store.addNotification({
          title: msg1,
          message: msg2,
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })
      });
             
      })

    }

      componentDidMount(){

        this.SolicitudesURG();        
      
      if(!sessionStorage.getItem('token')){
        window.location.href="/";
      }
    
    }


    Notification(){
      axios.get(ApiURL+"/api/Notifications/ListAll")
      .then((response) => {
        console.log(response);
        this.setState({Notification: response.data})
          
      })
      .catch((error) => {
        console.log(error);
      })      
   
    }

    
    
  render(){
        
    
    return(          
      
     <nav className="main-header navbar navbar-expand navbar-white navbar-light">
  {/* Left navbar links */}
  <ul className="navbar-nav">
    <li className="nav-item">
      <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
    </li>
    <li className="nav-item d-none d-sm-inline-block">
      <a href="../../index3.html" className="nav-link">Home</a>
    </li>
    <li className="nav-item d-none d-sm-inline-block">
      <a href="#" className="nav-link">Contact</a>
    </li>
  </ul>
  {/* SEARCH FORM */}
  <form className="form-inline ml-3">
    <div className="input-group input-group-sm">
      <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
      <div className="input-group-append">
        <button className="btn btn-navbar" type="submit">
          <i className="fas fa-search" />
        </button>
      </div>
    </div>
  </form>
  {/* Right navbar links */}
  <ul className="navbar-nav ml-auto">
    {/* Messages Dropdown Menu */}
    <li className="nav-item dropdown">
      <a className="nav-link" data-toggle="dropdown" href="#">
        <i className="far fa-comments" />
        <span className="badge badge-danger navbar-badge">3</span>
      </a>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <a href="#" className="dropdown-item">
          {/* Message Start */}
          <div className="media">
            <img src="../../dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
            <div className="media-body">
              <h3 className="dropdown-item-title">
                Brad Diesel
                <span className="float-right text-sm text-danger"><i className="fas fa-star" /></span>
              </h3>
              <p className="text-sm">Call me whenever you can...</p>
              <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
            </div>
          </div>
          {/* Message End */}
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          {/* Message Start */}
          <div className="media">
            <img src="../../dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
            <div className="media-body">
              <h3 className="dropdown-item-title">
                John Pierce
                <span className="float-right text-sm text-muted"><i className="fas fa-star" /></span>
              </h3>
              <p className="text-sm">I got your message bro</p>
              <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
            </div>
          </div>
          {/* Message End */}
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          {/* Message Start */}
          <div className="media">
            <img src="../../dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
            <div className="media-body">
              <h3 className="dropdown-item-title">
                Nora Silvester
                <span className="float-right text-sm text-warning"><i className="fas fa-star" /></span>
              </h3>
              <p className="text-sm">The subject goes here</p>
              <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
            </div>
          </div>
          {/* Message End */}
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
      </div>
    </li>
    {/* Notifications Dropdown Menu */}
    <li className="nav-item dropdown">
      <a className="nav-link" data-toggle="dropdown" href="#">
        <i className="far fa-bell" />
        <span className="badge badge-warning navbar-badge">15</span>
      </a>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span className="dropdown-item dropdown-header">15 Notifications</span>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          <i className="fas fa-envelope mr-2" /> 4 new messages
          <span className="float-right text-muted text-sm">3 mins</span>
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          <i className="fas fa-users mr-2" /> 8 friend requests
          <span className="float-right text-muted text-sm">12 hours</span>
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          <i className="fas fa-file mr-2" /> 3 new reports
          <span className="float-right text-muted text-sm">2 days</span>
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
      </div>
    </li>
    <li className="nav-item">
      <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
        <i className="fas fa-th-large" />
      </a>
    </li>
  </ul>
</nav>

        
    )
  }

}

export default Navbar;